const namespaced = true;

const state = {
    activeCustomer: null,
    agronodConsent: false,
};

const getters = {
    activeCustomer: (state) => state.activeCustomer,
    hasAgronodConsent: (state) => state.agronodConsent,
};

const actions = {
    SET_ACTIVE_CUSTOMER({ commit, dispatch, rootGetters }, id) {
        return new Promise((resolve, reject) => {
            if (rootGetters["interpolationSettings/showAllClay"]) {
                dispatch("interpolationSettings/SET_SHOW_ALL_CLAY", false, {
                    root: true,
                });
            }
            axios.get("/customer/" + id).then((response) => {
                commit("setActiveCustomer", response.data);

                resolve();
            });
        });
    },

    REFRESH_ACTIVE_CUSTOMER({ commit, state }) {
        return new Promise((resolve, reject) => {
            if(state.activeCustomer?.id){
                axios.get("/customer/" + state.activeCustomer.id)
                .then((response) => {
                    commit("setActiveCustomer", response.data);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
            }
        });
    },

    CHECK_AGRONOD_CONSENT({ commit, getters }, isConsumer = false) {
        return new Promise((resolve, reject) => {
            const customerId = getters.activeCustomer?.id;
            const url = isConsumer ? `/customer/${customerId}/agronod/consent/recive` : `/customer/${customerId}/agronod/consent/send`;
            if(customerId) {
                axios
                .get(url)
                .then((response) => {
                    if (response.data.consent) {
                        commit("setAgronodConsent", true);
                    } else {
                        commit("setAgronodConsent", false);
                    }
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
            } else {
                commit("setAgronodConsent", false);
                resolve();
            }
        });
    },

    RESET_AGRONOD_CONSENT({ commit }) {
        return new Promise((resolve, reject) => {
            commit("setAgronodConsent", false);
            resolve();
        });
    },

    RESET_ACTIVE_CUSTOMER({ commit }) {
        commit("resetActiveCustomer");
    },
};

const mutations = {
    setActiveCustomer(state, customer) {
        state.activeCustomer = customer;
        state.hasAgronodConsent = false;
    },

    resetActiveCustomer(state) {
        state.activeCustomer = null;
        state.hasAgronodConsent = false;
    },

    setAgronodConsent(state, consent) {
        state.agronodConsent = consent;
    },
};


export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
