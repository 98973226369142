import axios from "axios";
import Errors from "@/helpers/errors.js";

const namespaced = true;

const state = {
    access_token: null,
    status: "",
    hasLoadedOnce: false,
    errors: null,
    previousEmail: "",
    user: null,
    originalUser: null,
    originalActiveCustomer: null,
    original_access_token: null,
    showLicenseInfoModal: true,
};

const getters = {
    user: (state) => state.user,
    userName: (state) => (state.user ? state.user.name : ""),
    region: (state) => (state.user ? state.user.region : null),
    access_token: (state) => state.access_token,
    isImpersonated: (state) => state.originalUser !== null,
    showLicenseInfoModal: (state) => state.showLicenseInfoModal,
    licensePackage: (state) =>
        state.user && state.user.licensePackage
            ? state.user.licensePackage
            : null,
    isAuthenticated: (state) => !!state.access_token && state.user !== null,
    authStatus: (state) => state.status,
    authErrors: (state) => state.errors,
    previousEmail: (state) => state.previousEmail,
    profilePicture: (state) => getProfilePicture(state.user?.name),
    hasPackage: (state, getters) => !!getters.licensePackage,
};

const actions = {
    AUTH_REQUEST({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit("authRequest");
            axios
                .post("auth/login", {
                    email: payload.email,
                    password: payload.password,
                    device_name: "Markkartering.se",
                })
                .then((response) => {
                    let access_token = "Bearer " + response.data;
                    axios.defaults.headers.common["Authorization"] =
                        access_token;

                    commit("authSuccess", {
                        access_token: access_token,
                        email: payload.email,
                    });
                    dispatch("FETCH_USER").then(() => {
                        resolve(access_token);
                    });
                })
                .catch((err) => {
                    commit("authError", err.response.data);
                    reject(err);
                });
        });
    },

    AUTH_LOGOUT({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit("setUser", null);
            dispatch(
                "activeCustomer/RESET_ACTIVE_CUSTOMER",
                {},
                { root: true }
            );
            axios
                .post("auth/logout")
                .then((response) => {
                    commit("authLogout");
                    resolve();
                })
                .catch((err) => {
                    commit("authLogout");
                    resolve();
                });
        });
    },

    AUTH_REGISTER({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("auth/register", {
                    name: payload.name,
                    email: payload.email,
                    password: payload.password,
                    password_confirmation: payload.password_confirmation,
                    phone: payload.phone,
                    application_text: payload.application_text,
                    region_id: payload.region_id,
                    device_name: "Markkartering.se",
                })
                .then((response) => {
                    let access_token = "Bearer " + response.data;
                    axios.defaults.headers.common["Authorization"] =
                        access_token;

                    commit("authSuccess", {
                        access_token: access_token,
                        email: payload.email,
                    });
                    dispatch("FETCH_USER").then(() => {
                        resolve(access_token);
                    });
                })
                .catch((err) => {
                    commit("authError", err.response.data);
                    reject(err);
                });
        });
    },

    FETCH_USER({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get("auth/user")
                .then((response) => {
                    commit("setUser", response.data);
                    resolve(response);
                })
                .catch((err) => {
                    commit("authLogout");
                    reject(err);
                });
        });
    },

    IMPERSONATE_USER({ commit, dispatch, rootGetters }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post("impersonate/user", {
                    user_id: id,
                    device_name: "Markkartering.se",
                })
                .then((response) => {
                    commit("impersonateUser", {
                        user: response.data,
                        activeCustomer:
                            rootGetters["activeCustomer/activeCustomer"],
                    });
                    dispatch(
                        "activeCustomer/RESET_ACTIVE_CUSTOMER",
                        {},
                        { root: true }
                    );
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    RESET_IMPERSONATION({ state, commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit("resetImpersonation");
            dispatch(
                "activeCustomer/SET_ACTIVE_CUSTOMER",
                state.originalActiveCustomer.id,
                { root: true }
            ).then(() => {
                resolve();
            });
        });
    },

    IMPERSONATE_LICENSE({ commit, dispatch }, packageId) {
        return new Promise((resolve, reject) => {
            axios
                .post("impersonate/license", {
                    package_id: packageId,
                })
                .then((response) => {
                    window.location.reload();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    SET_SHOW_LICENSE_INFO_MODAL({ commit }, show) {
        commit("setShowLicenseInfoModal", show);
    },
};

const mutations = {
    authRequest: (state) => {
        state.status = "loading";
    },

    authSuccess: (state, { access_token, email }) => {
        state.status = "success";
        state.access_token = access_token;
        state.hasLoadedOnce = true;
        state.previousEmail = email;
        state.errors = null;
    },

    authError: (state, err) => {
        let errors = err.errors ? err.errors : {};
        if (err.error == "invalid_credentials") {
            errors.invalid_credentials = [
                "The user credentials were incorrect.",
            ];
        }

        state.access_token = null;
        state.status = "error";
        state.hasLoadedOnce = true;

        state.errors = new Errors();

        state.errors.record(errors);
    },

    authLogout: (state) => {
        state.access_token = null;
        state.user = null;
        state.originalUser = null;
        state.originalActiveCustomer = null;
        state.original_access_token = null;
    },

    setUser: (state, user) => {
        state.user = JSON.parse(JSON.stringify(user));
    },

    impersonateUser: (state, { user, activeCustomer }) => {
        let access_token = "Bearer " + user.access_token;
        axios.defaults.headers.common["Authorization"] = access_token;

        state.originalUser = JSON.parse(JSON.stringify(state.user));
        state.originalActiveCustomer = JSON.parse(
            JSON.stringify(activeCustomer)
        );
        state.original_access_token = JSON.parse(
            JSON.stringify(state.access_token)
        );
        state.user = user;
        state.access_token = access_token;
        state.status = "success";
    },

    resetImpersonation: (state) => {
        state.user = JSON.parse(JSON.stringify(state.originalUser));
        state.access_token = JSON.parse(
            JSON.stringify(state.original_access_token)
        );
        axios.defaults.headers.common["Authorization"] = state.access_token;
        state.originalUser = null;
        state.original_access_token = null;
    },

    setShowLicenseInfoModal(state, show) {
        state.showLicenseInfoModal = show;
    },
};

function getProfilePicture(name) {
    if (!name) {
        return "";
    }
    let nameString = name.replace(" ", "+");
    return (
        "https://ui-avatars.com/api/?name=" +
        nameString +
        "&color=7F9CF5&background=EBF4FF"
    );
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
