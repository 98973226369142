const namespaced = true;

const state = {
    samplings: [],
    currentSamples: [],
    currentYear: null,
    polygons: null,
    // activeCustomer: null,
};

const getters = {
    all: (state) => state.samplings,
    currentSamples: (state) => state.currentSamples,
    currentYear: (state) => state.currentYear,
    features: (state) => state.features,
    // currentOrder: state => state.currentOrder,
};

const actions = {
    GET_SAMPLINGS({ commit }, customerId) {
        return new Promise((resolve, reject) => {
            axios.get("/customer/" + customerId + "/all").then((response) => {
                commit("setSamplings", response.data);
                resolve();
            });
        });
    },

    // GET_SAMPLING_BY_YEARS({ commit }, {customerId, year}) {
    // 	return new Promise((resolve, reject) => {
    // 		axios.get('/sample/'+ customerId + '/' + year)
    // 		.then((response) => {
    // 			commit('setActiveYear', year)
    // 			commit('setCurrentSamples', response.data)
    // 			resolve();
    // 		})
    // 	})
    // },

    GET_POLYGONS_BY_YEAR({ commit }, { customerId, year }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/polygon/" + customerId + "/year/" + year)
                .then((response) => {
                    commit("setPolygons", response.data);
                    if (response.data.length > 0) {
                        resolve(response.data);
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    setSamplings(state, samplings) {
        state.samplings = samplings;
    },

    setActiveYear(state, currentYear) {
        state.currentYear = currentYear;
    },

    setCurrentSamples(state, samples) {
        state.currentSamples = samples;
    },

    setPolygons(state, data) {
        state.polygons = JSON.parse(data[0].features);
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
