// create variable for our handler to be shared between the bind & unbind hooks
var handleOutsideClick = {};

const OutsideClick = {
    bind(el, binding, vnode) {
        handleOutsideClick[el.id] = (e) => {
            e.stopPropagation();
            const { handler, excluded_ids } = binding.value;

            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;

            // if event clicked on an element outside the given element that has
            //  an id that exists in excluded_ids wont trigger the handler
            if (excluded_ids && excluded_ids.length) {
                excluded_ids.forEach((id) => {
                    if (!clickedOnExcludedEl) {
                        clickedOnExcludedEl = e.target.id === id;
                    }
                });
            }

            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                handler();
            }
        };
        // Register our outsideClick handler on the click/touchstart listeners
        document.addEventListener("click", handleOutsideClick[el.id]);
        document.addEventListener("touchstart", handleOutsideClick[el.id]);
    },

    unbind(el) {
        // If the element that has v-outside-click is removed, unbind it from listeners
        document.removeEventListener("click", handleOutsideClick[el.id]);
        document.removeEventListener("touchstart", handleOutsideClick[el.id]);
    },
};
export default OutsideClick;
