const namespaced = true;

const state = {
    currentGiva: null,
    painting: false,
    erasing: false,
    holdingMouseClick: false,
    appliedGivor: {},
    modifiedGivor: {},

    colors: ["#ccece6", "#99d8c9", "#66c2a4", "#41ae76", "#238b45", "#006d2c"],
};

const initialState = JSON.parse(JSON.stringify(state));

const getters = {
    exportState: (state) => getExportState(state),
    currentGiva: (state) => state.currentGiva,
    painting: (state) => state.painting,
    erasing: (state) => state.erasing,
    holdingMouseClick: (state) => state.holdingMouseClick,
    colors: (state) => state.colors,
    modifiedGivor: (state) => state.modifiedGivor,
    appliedGivor: (state) => state.appliedGivor,
    appliedGivorSorted: (state) =>
        Object.keys(state.appliedGivor).sort((a, b) => a - b),
};

const actions = {
    SET_CURRENT_GIVA({ commit }, giva) {
        commit("setCurrentGiva", giva);
    },

    INCREMENT_GIVA({ commit }, giva) {
        commit("incrementGiva", giva);
    },

    DECREMENT_GIVA({ commit }, giva) {
        commit("decrementGiva", giva);
    },

    TOGGLE_PAINTING({ commit }, painting) {
        commit("setPainting", painting);
        if (painting && state.erasing) {
            commit("setErasing", false);
        }
    },

    TOGGLE_ERASING({ commit, state }, erasing) {
        commit("setErasing", erasing);
        if (erasing && state.painting) {
            commit("setPainting", false);
        }
    },

    SET_HOLDING_MOUSE_CLICK({ commit }, holding) {
        commit("setHoldingMouseClick", holding);
    },

    SET_MODIFIED_LAYERS({ commit }, layers) {
        let modifiedGivor = {};
        let amount;
        let area;

        layers.forEach((layer) => {
            area = layer.feature.properties.area / 10000;
            amount = layer.feature.properties.giva * area;
            if (!modifiedGivor.hasOwnProperty(layer.feature.properties.giva)) {
                modifiedGivor[layer.feature.properties.giva] = {
                    amount: amount,
                    area: area,
                };
            } else {
                modifiedGivor[layer.feature.properties.giva].amount += amount;
                modifiedGivor[layer.feature.properties.giva].area += area;
            }
        });

        commit("setModifiedGivor", modifiedGivor);
    },

    CLEAR_PAINTING_STATES({ commit }) {
        commit("clearPaintingState");
    },

    CLEAR_STATE({ commit }) {
        commit("clearState");
    },

    SET_STATE({ commit }, newState) {
        commit("setState", newState);
    },
};

const mutations = {
    clearState(state) {
        Object.keys(state).forEach((key) => {
            state[key] = initialState[key];
        });
    },

    clearPaintingState(state) {
        state.painting = false;
        state.erasing = false;
        state.holdingMouseClick = false;
    },

    setCurrentGiva(state, giva) {
        state.currentGiva = giva;
    },

    incrementGiva(state, giva) {
        if (state.appliedGivor[giva]) {
            state.appliedGivor[giva] += 1;
        } else {
            Vue.set(state.appliedGivor, giva, 1);
        }
    },

    decrementGiva(state, giva) {
        if (state.appliedGivor[giva] <= 1) {
            Vue.delete(state.appliedGivor, giva);
        } else {
            state.appliedGivor[giva] -= 1;
        }
    },

    setPainting(state, painting) {
        state.painting = painting;
    },

    setErasing(state, erasing) {
        state.erasing = erasing;
    },

    setHoldingMouseClick(state, holding) {
        state.holdingMouseClick = holding;
    },

    setModifiedGivor(state, modifiedGivor) {
        state.modifiedGivor = modifiedGivor;
    },

    setState(state, newState) {
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

function getExportState(state) {
    return {
        appliedGivor: state.appliedGivor,
        modifiedGivor: state.modifiedGivor,
    };
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
