/** Js imports (Required to work for vite) */
import { Buffer } from "buffer";
import process from "process";
import EventEmitter from "events";
window.Buffer = Buffer;
window.process = process;
window.EventEmitter = EventEmitter;
window.global ||= window;

/** Js imports */
import _ from "lodash";
import axios from "axios";
import bowser from "bowser";
import L from "leaflet";
import moment from "moment";
window._ = _;
window.axios = axios;
window.bowser = bowser;
window.L = L;
window.moment = moment;

/** CSS Imports */
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import "../css/style.css";
import "es6-promise/auto";
import "tailwindcss/tailwind.css";
import "../css/map-styling.css";
import "@fortawesome/fontawesome-free/css/all.css";

/** Other importsError */
import "./helpers/form.js"; // handling for forms

/** Configure imported packages */
window.moment.locale("sv");
