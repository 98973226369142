const namespaced = true;

// state
export const state = {
    useSatellite: false,
};

// getters
export const getters = {
    useSatellite: (state) => state.useSatellite,
};

// mutations
export const mutations = {
    UPDATE_SETTING(state, { setting, value }) {
        state[setting] = value;
    },
};

// actions
export const actions = {
    updateSetting({ commit }, { setting, value }) {
        commit("UPDATE_SETTING", { setting: setting, value: value });

        // Update the settings object in local storage
        localStorage.setItem("settings", JSON.stringify(state));
    },

    /**
     * Checks local storage for a item named settings
     * If it exists, then set its settings
     */
    getSettingsFromLocalStorage({ state, commit }) {
        let settings = localStorage.getItem("settings");
        if (settings) {
            settings = JSON.parse(settings);
            Object.keys(settings).forEach((key) => {
                // Check that the setting is still in the original state so that it is not a removed setting
                if (state.hasOwnProperty(key)) {
                    commit("UPDATE_SETTING", {
                        setting: key,
                        value: settings[key],
                    });
                }
            });
        }
    },
};

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
