const namespaced = true;

const state = {
    runs: [],
    activeRun_id: null,
};

const getters = {
    runs: (state) => getSortedRuns(state.runs),
    activeRun: (state) => getActiveRun(state),
};

const actions = {
    ADD_RUN({ state, commit }, {parcel_id, data}) {
        return new Promise((resolve, reject) => {
            axios.post("parcel/" + parcel_id + "/addrun", data)
            .then((response) => {
                commit("addRun", response.data);
                resolve();
                // commit("setActiveRun", response.data.id);
            })
            .catch((e) =>{
                reject(e);
            });
        });
    },

    ADD_RUNS({ state, commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post("run/bulkcreate", data)
            .then((response) => {
                commit("addRuns", response.data);
                resolve();
            })
            .catch((e) =>{
                reject(e);
            });
        });
    },

    DELETE_RUN({ state, commit, dispatch, rootGetters }, {id, parcel_id}) {
        axios.delete("run/" + id).then((response) => {
            commit("setActiveRun", null);
            dispatch(
                "GET_PARCEL_RUNS",
                parcel_id
            );
        });
    },

    DELETE_RUNS({ state, commit, dispatch, rootGetters }, runs) {
        return new Promise((resolve, reject) => {
            axios.post("run/bulkdelete", {
                ids: runs.map(r => r.id)
            })
            .then((response) => {
                commit("setActiveRun", null);
                dispatch(
                    "GET_PARCEL_RUNS",
                    runs[0].parcel_id
                );
                resolve();
            })
            .catch((e) => {
                reject(e);
            });
        });
    },

    SET_ACTIVE_RUN({ state, commit }, run_id) {
        commit("setActiveRun", run_id);
    },

    GET_PARCEL_RUNS({ state, commit }, polygon_id) {
        commit("setActiveRun", null);
        if (polygon_id) {
            axios.get("parcel/" + polygon_id + "/runs").then((response) => {
                commit("setRuns", response.data);
            });
        } else {
            commit("setRuns", []);
        }
    },

    UPDATE_RUN_ORDER({ commit }, runs) {
        let runArray = runs.map((run, i) => {
            return {
                id: run.id,
                run_nbr: i + 1,
            };
        });

        axios.post("run/update/order", { runs: runArray }).then((response) => {
            runArray.forEach((run) => {
                commit("updateRunNbr", {
                    run_id: run.id,
                    run_nbr: run.run_nbr,
                });
            });
        });
    },

    UPDATE_RUN({ commit }, parameters) {
        return new Promise((resolve, reject) => {
            axios.patch("run/" + parameters.id, parameters)
            .then((response) => {
                commit("updateRun", response.data);
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    REMOVE_PRESCRIPTION_FROM_RUN({ commit }, id) {
        //run/{run}/removeprescription
        return new Promise((resolve, reject) => {
            axios.post("run/" + id + "/removeprescription")
            .then((response) => {
                commit("updateRun", response.data);
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    CLEAR_RUNS({ commit }) {
        commit("clearRuns");
    },
};

const mutations = {
    setRuns(state, runs) {
        state.runs = runs;
    },

    setActiveRun(state, run_id) {
        state.activeRun_id = run_id;
        // state.activeRun = getActiveRun(run_id);
        // This is some wierd stuff . Should consider changing behaviuor.
    },

    addRun(state, run) {
        state.runs.push(run);
    },

    addRuns(state, runs) {
        runs.forEach(run => {
            state.runs.push(run);
        });
    },

    updateRun(state, run) {
        let found = state.runs.find((i) => i.id === run.id);
        Object.keys(run).forEach((key) => {
            found[key] = run[key];
        });
    },

    updateRunNbr(state, params) {
        let run = state.runs.find((run) => run.id === params.run_id);
        run.run_nbr = params.run_nbr;
    },

    clearRuns(state) {
        state.runs = [];
        state.activeRun = null;
    },
};

/**
 * Return a sorted clone of the runs array
 */
function getSortedRuns(runs) {
    return JSON.parse(JSON.stringify(runs)).sort((a, b) => {
        return a.run_nbr - b.run_nbr;
    });
}

function getActiveRun(state) {
    if (state.activeRun_id) {
        return state.runs.find((run) => run.id === state.activeRun_id);
    }
    return null;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
