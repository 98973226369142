const namespaced = true;

const state = {
    products: [],
};

const getters = {
    products: (state) => state.products,
};

const actions = {
    GET_PRODUCTS({ commit }) {
        axios.get("product").then((response) => {
            commit("setProducts", response.data);
        });
    },

    ADD_PRODUCT({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post("product", data)
                .then((response) => {
                    commit("addProduct", response.data);
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
};

const mutations = {
    setProducts(state, products) {
        state.products = products;
    },
    
    addProduct(state, product) {
        state.products.push(product);
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
