const namespaced = true;

const state = {
    cropNeeds: [],
};

const getters = {
    cropNeeds: (state) => state.cropNeeds,
};

const actions = {
    GET_CROPNEEDS({ commit }, licenses) {
        return new Promise((resolve, reject) => {
            axios.get("/cropneed").then((response) => {
                commit("setCropNeeds", response.data);
                resolve();
            });
        });
    },
    REMOVE_CROPNEED({ commit }, cropNeed) {
        commit("removeCropNeed", cropNeed);
    },

    ADD_CROPNEED({ commit }, cropNeed) {
        commit("addCropNeed", cropNeed);
    },

    UPDATE_CROPNEED({ commit }, cropNeed) {
        commit("updateCropNeed", cropNeed);
    },
};

const mutations = {
    setCropNeeds(state, needs) {
        state.cropNeeds = needs;
    },
    addCropNeed(state, cropNeed) {
        state.cropNeeds.push(cropNeed);
    },
    removeCropNeed(state, cropNeed) {
        let crop = state.cropNeeds.find((crop) => crop.id === cropNeed.crop_id);
        state.cropNeeds = state.cropNeeds.filter((x) => x.id !== cropNeed.id);
    },
    updateCropNeed(state, cropNeed) {
        let needIndex = state.cropNeeds.findIndex(
            (need) => need.id === cropNeed.id
        );

        if (needIndex > -1) {
            state.cropNeeds[needIndex] = cropNeed;
            state.cropNeeds = [...state.cropNeeds];
        }
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
