import axios from "axios";

const namespaced = true;

/**
 * State
 */
const state = {
    interval_id: null,
    oldHtml: null,
    newVersionAvailable: false,
    notifications: [],
};

/**
 * Getters
 */
const getters = {
    notificationsActive: (state) =>
        state.newVersionAvailable || !!state.notifications.length,
    newVersionAvailable: (state) => state.newVersionAvailable,
};

/**
 * Mutations
 */
const mutations = {
    SET_INTERVAL(state, { interval_id }) {
        state.interval_id = interval_id;
    },

    SET_OLD_HTML(state, { old_html }) {
        state.oldHtml = old_html;
    },

    SET_NEW_VERSION_AVAILABLE(state, { available }) {
        state.newVersionAvailable = available;
    },
};

/**
 * Actions
 */
const actions = {
    startCheckForNewVersionInterval({ state, commit }) {
        isNewVersionAvailable(commit, state.oldHtml);

        let interval = setInterval(() => {
            isNewVersionAvailable(commit, state.oldHtml);
        }, 1000 * 30);

        commit("SET_INTERVAL", { interval_id: interval });
    },

    clearCheckForNewVerionInterval({ state, commit }) {
        if (state.interval_id) {
            clearInterval(state.interval_id);
            commit("SET_INTERVAL", { interval_id: null });
        }
    },
};

function isNewVersionAvailable(commit, oldHtml) {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: "/index.html",
            baseURL: "/",
        }).then((response) => {
            let html = response.data;

            if (oldHtml === null) {
                commit("SET_OLD_HTML", { old_html: html });
                return false;
            }

            if (oldHtml === html) {
                return false;
            }

            commit("SET_OLD_HTML", { old_html: html });
            commit("SET_NEW_VERSION_AVAILABLE", { available: true });
        });
    });
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
