const namespaced = true;

const state = {
    plantBalance: null,
    plantBalances: [],
    polygonsAndSamples: [],
};

const getters = {
    plantBalance: (state) => state.plantBalance,
    plantBalances: (state) => state.plantBalances,
};

const actions = {
    RESET({ commit }) {
        commit("reset");
    },

    GET_PLANT_BALANCE({ commit }, { gid }) {
        return new Promise((resolve, reject) => {
            axios.get("/plantbalance/" + gid).then(({ data }) => {
                commit("setPlantBalance", data);
                resolve();
            });
        });
    },

    REMOVE_PLANT_BALANCE({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios.delete("/plantbalance/" + id).then(({ data }) => {
                commit("removePlantBalance", id);
                resolve();
            });
        });
    },

    SET_PLANT_BALANCE({ commit }, plantBalance) {
        commit("setPlantBalance", plantBalance);
    },

    GET_PLANT_BALANCES({ commit }, { customer_id, year }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/plantbalance/bycustomerandyear/", {
                    customer_id: customer_id,
                    year: year,
                })
                .then(({ data }) => {
                    let plantBalances = data.map((plantBalance) => {
                        let modified = { ...plantBalance };
                        modified.samples = JSON.parse(plantBalance.samples).map(
                            (pb) => {
                                pb.geometry = {
                                    coordinates: [
                                        pb.properties.lat,
                                        pb.properties.lng,
                                    ],
                                    type: "Point",
                                };
                                pb.type = "Feature";
                                return pb;
                            }
                        );
                        modified.polygon = JSON.parse(plantBalance.polygon);
                        modified.polygon.geometry = JSON.parse(
                            modified.polygon.geometry
                        );
                        modified.rows = JSON.parse(plantBalance.rows);
                        return modified;
                    });

                    commit("setPlantBalances", plantBalances);
                });
        });
    },

    SAVE_PLANT_BALANCE(
        { commit, getters, rootGetters },
        { p_al_avg, k_al_avg, geom, samples, rows, customer_id, year }
    ) {
        let params = {
            p_al_avg: p_al_avg,
            customer_id: customer_id,
            year: year,
            k_al_avg: k_al_avg,
            geom: geom,
            rows: rows,
            sampleIds: samples,
            type: "cultivationYear",
        };

        let request = null;

        if (getters.plantBalance === null) {
            request = axios.post("/plantbalance", params);
        } else {
            request = axios.patch(
                "/plantbalance/" + getters.plantBalance.id,
                params
            );
        }

        return new Promise((resolve, reject) => {
            request.then(({ data }) => {
                let parsed = { ...data };
                parsed.samples = JSON.parse(data.samples);
                parsed.polygon = JSON.parse(data.polygon);
                parsed.polygon.geometry = JSON.parse(parsed.polygon.geometry);
                parsed.rows = JSON.parse(data.rows);

                commit("setPlantBalance", parsed);
                resolve();
            });
        });
    },
};

const mutations = {
    setPlantBalance(state, plantBalance) {
        if (Object.keys(plantBalance).length === 0) {
            state.plantBalance = null;
        } else {
            state.plantBalance = plantBalance;
        }
    },

    setPlantBalances(state, plantBalances) {
        state.plantBalances = plantBalances;
    },

    removePlantBalance(state, id) {
        state.plantBalances = state.plantBalances.filter((b) => b.id !== id);
    },

    reset(state) {
        state.plantBalance = null;
        state.plantBalances = [];
        state.polygonsAndSamples = [];
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
