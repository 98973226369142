const types = [
    {
        slug: "provnr",
        value: "provnr",
        title: "Provnummer",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "ph",
        value: "ph",
        title: "pH",
        shouldFilter: true,
        productTypes: ["chalk"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "p_al",
        value: "p_al",
        title: "Fosfor (P-AL)",
        shouldFilter: true,
        productTypes: ["p"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_p_al",
        value: "volume_corrected_p_al",
        title: "Fosfor (P-AL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: ["p"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "k_al",
        value: "k_al",
        title: "Kalium (K-AL)",
        shouldFilter: true,
        productTypes: ["k"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_k_al",
        value: "volume_corrected_k_al",
        title: "Kalium (K-AL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: ["k"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "mg_al",
        value: "mg_al",
        title: "Magnesium (Mg-AL)",
        shouldFilter: true,
        productTypes: ["mg"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_mg_al",
        value: "volume_corrected_mg_al",
        title: "Magnesium (Mg-AL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: ["mg"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "ca_al",
        value: "ca_al",
        title: "Kalcium (Ca-AL)",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_ca_al",
        value: "volume_corrected_ca_al",
        title: "Kalcium (Ca-AL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "p_hcl",
        value: "p_hcl",
        title: "Fosfor HCl",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "k_hcl",
        value: "k_hcl",
        title: "Kalium HCl",
        shouldFilter: true,
        productTypes: ["k"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_k_hcl",
        value: "volume_corrected_k_hcl",
        title: "Kalium (K-HCL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: ["k"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cu_hcl",
        value: "cu_hcl",
        title: "Koppar (Cu-HCL)",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "Koppar",
        description: "",
    },
    {
        slug: "volume_corrected_cu_hcl",
        value: "volume_corrected_cu_hcl",
        title: "Koppar (Cu-HCL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: ["cu"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "lerhalt",
        value: "lerhalt",
        title: "Lerhalt",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "mullhalt",
        value: "mullhalt",
        title: "Mullhalt",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "basmattnadsgrad",
        value: "basmattnadsgrad",
        title: "Basmättnadsgrad",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cultivationYear",
        value: "cultivationYear",
        title: "Gårdsbild",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cu",
        value: "cu",
        title: "Koppar",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cr",
        value: "cr",
        title: "Krom",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "p_need",
        value: "p_need",
        title: "Behov Fosfor",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "k_need",
        value: "k_need",
        title: "Behov Kalium",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "p_olsen",
        value: "p_olsen",
        title: "Fosfor (P-Olsen)",
        shouldFilter: true,
        productTypes: ["p"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "k_mg_kvot",
        value: "k_mg_kvot",
        title: "Kalium/Magnesium-kvot (K/Mg-kvot)",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "bor",
        value: "bor",
        title: "Bor",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volymvikt",
        value: "volymvikt",
        title: "Volymvikt",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "t_varde",
        value: "t_varde",
        title: "T-värde",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "s_varde",
        value: "s_varde",
        title: "S-värde (Cu-behov)",
        shouldFilter: true,
        productTypes: ["cu"],
        alternateTypeText: "Koppar",
        description: "",
    },
    {
        slug: "cd_hno3",
        value: "cd_hno3",
        title: "Cd HNO3",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "kalkbehov",
        value: "kalkbehov",
        title: "Kalkbehov",
        shouldFilter: true,
        productTypes: ["chalk"],
        alternateTypeText: "Kalk",
        description: "(ton/ha)",
    },
    {
        slug: "fe_al",
        value: "fe_al",
        title: "Järn (Fe-AL)",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_fe_al",
        value: "volume_corrected_fe_al",
        title: "Järn (Fe-AL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "al_al",
        value: "al_al",
        title: "Al-AL",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "volume_corrected_al_al",
        value: "volume_corrected_al_al",
        title: "Aluminium (Al-AL) Volymkorrigerad",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "al_as",
        value: "al_as",
        title: "Al-AS",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "toc",
        value: "toc",
        title: "Totalt organiskt kol (TOC)",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "zn",
        value: "zn",
        title: "Zink",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "ni",
        value: "ni",
        title: "Nickel",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "pb",
        value: "pb",
        title: "Bly",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "hg",
        value: "hg",
        title: "Kvicksilver",
        shouldFilter: true,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "mineralkvave_kg_n_ha",
        value: "mineralkvave_kg_n_ha",
        title: "Mineralkväve N(kg)/ha",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "mineralkvave_no3_n",
        value: "mineralkvave_no3_n",
        title: "Mineralkväve NO3",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "mineralkvave_nh4_n",
        value: "mineralkvave_nh4_n",
        title: "Mineralkväve NH4",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "mineralkvave_djup",
        value: "mineralkvave_djup",
        title: "Mineralkväve djup",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cystnematoder_potatis",
        value: "cystnematoder_potatis",
        title: "Cystnematoder potatis",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cystnematoder_betor",
        value: "cystnematoder_betor",
        title: "Cystnematoder betor",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "cystnematoder_spannmal",
        value: "cystnematoder_spannmal",
        title: "Cystnematoder spannmål",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "kalk_50_procent_cao",
        value: "kalk_50_procent_cao",
        title: "Totalt behov kalk (50% CaO)",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "kalkbehov_spannmal",
        value: "kalkbehov_spannmal",
        title: "Kalkbehov spannmål",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "kalkbehov_sockerbetor",
        value: "kalkbehov_sockerbetor",
        title: "Kalkbehov sockerbetor",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "magnesiumbehov",
        value: "magnesiumbehov",
        title: "Magnesiumbehov",
        shouldFilter: false,
        productTypes: ["mg"],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "avkastningsminskning",
        value: "avkastningsminskning",
        title: "Avkastningsminskning",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "sand_grovmo",
        value: "sand_grovmo",
        title: "Sand grovmo",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
    {
        slug: "ovrig",
        value: "ovrig",
        title: "Övrigt",
        shouldFilter: false,
        productTypes: [],
        alternateTypeText: "",
        description: "",
    },
];

export default types;
