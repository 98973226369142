import area from "@turf/area";
import types from "@/utils/types";

const namespaced = true;

const planTypes = [
    "cultivationYear",
    "p_need",
    "k_need",
    "ph",
    "p_al",
    "p_hcl",
    "k_al",
    "k_hcl",
    "mg_al",
    "cu_hcl",
    "k_mg_kvot",
    "bor",
    "ca_al",
    "mullhalt",
    "lerhalt",
    "volymvikt",
    "t_varde",
    "s_varde",
    "cd_hno3",
    "kalkbehov",
    "fe_al",
    "al_al",
    "al_as",
    "toc",
    "zn",
    "cu",
    "cr",
    "ni",
    "pb",
    "hg",
];

const state = {
    cultivationYears: [],
    years: [],
    currentCultivationYear: null,
    polygons: null,
    blockPolygons: null,

    // min and max values for each property in the cellParcelsGeom
    minMax: [],
    minMaxColors: {
        min: "white",
        max: "royalblue",
    },
    cellParcelsGeom: null,
    selectedBlockIds: [],
    selectedParcelIds: [],
    samples: [],
    crops: [],
    cropCodes: [],
    cropInfo: null,
    types: types.filter((t) => planTypes.includes(t.slug)),
};

const getters = {
    years: (state) => state.years,
    crops: (state) => state.crops,
    cropCodes: (state) => state.cropCodes,
    cropInfo: (state) => state.cropInfo,
    cultivationYears: (state) => state.cultivationYears,
    cultivationYear: (state) => state.currentCultivationYear,
    selectedParcelIds: (state) => state.selectedParcelIds,
    selectedParcels: (state) => getSelectedParcels(state),
    parcels: (state) =>
        state.currentCultivationYear?.parcels
            ? state.currentCultivationYear.parcels
            : [],
    selectedPolygons: (state) =>
        state.polygons?.features?.filter((p) =>
            state.selectedParcelIds.includes(p.properties.parcel_id)
        ),
    samples: (state) => state.samples,
    SamplesAsGeoJson: (state) => {
        return { type: "FeatureCollection", features: state.samples };
    },
    polygons: (state) => state.polygons,
    cellParcelsGeom: (state) => state.cellParcelsGeom,
    minMax: (state) => state.minMax,
    minMaxColors: (state) => state.minMaxColors,
    availableTypes: (state, getters, rootState, rootGetters) =>
        getAvaliableTypes(state, rootGetters),
    types: (state) => state.types,
    blockPolygons: (state) => state.blockPolygons,
    selectedBlockPolygons: (state) =>
        state.blockPolygons?.features?.filter((p) =>
            state.selectedBlockIds.includes(p.properties.gid)
        ),
    selectedBlockIds: (state) => state.selectedBlockIds,
};

const actions = {
    GET_CULTIVATION_YEARS(
        { commit, dispatch },
        { customerId, selectCurrentYear = false }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .get("/customer/" + customerId + "/cultivationYears?")
                .then((response) => {
                    commit("setCultivationYears", response.data);

                    let found = null;
                    if (selectCurrentYear) {
                        found = findClosestCultivationYear(response.data);
                        if (found) {
                            dispatch("GET_CULTIVATION_YEAR", {
                                customerId: customerId,
                                year: found.year,
                            }).then(() => {
                                resolve();
                            });
                        }
                    }
                    if (!found) {
                        resolve();
                    }
                });
        });
    },

    GET_CULTIVATION_YEAR({ commit, dispatch }, { customerId, year }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/cultivationyear/" + year + "/" + customerId)
                .then((response) => {
                    let joinedSampling = response.data.joined_sampling;
                    dispatch("planUi/SET_ANALYSIS_TYPE", "cultivationYear", {
                        root: true,
                    });
                    commit("setCellParcelsGeom", null);
                    commit("setCultivationYear", response.data);
                    commit("setPolygons", response.data.polygons);
                    console.warn(
                        "TODO, dont require joinedSamplings to get samples. Create a job that is dispatched when cultivation year parcels are created/updated/deleted"
                    );
                    if (joinedSampling) {
                        commit(
                            "setSamples",
                            joinedSampling.SamplesAsGeoJson[0].features
                        );
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    commit("resetCultivationYear");
                    reject("cultivationYear");
                });
        });
    },

    DELETE_CULTIVATION_YEAR({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/cultivationyear/" + id)
                .then((response) => {
                    commit("deleteYear", id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    UPDATE_CULTIVATION_YEAR({ commit }, { id, params }) {
        return new Promise((resolve, reject) => {
            axios
                .patch("/cultivationyear/" + id, params)
                .then((response) => {
                    commit("updateCultivationYear", response.data);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    GET_POLYGONS({ commit }, cultivationYear) {
        return new Promise((resolve, reject) => {
            axios
                .get("/cultivationyear/" + cultivationYear + "/get/polygons")
                .then((response) => {
                    commit("setPolygons", response.data);
                    resolve();
                })
                .catch((error) => {
                    commit("resetCultivationYear");
                    reject("cultivationYear");
                });
        });
    },

    RESET_CELL_PARCELS({ commit, getters, dispatch }) {
        return new Promise((resolve, reject) => {
            commit("setCellParcelsGeom", null);
            resolve();
        });
    },

    GET_CELL_PARCELS({ commit, getters, dispatch }) {
        return new Promise((resolve, reject) => {
            // Split parcels into chunks of 3
            let parcels = getters.parcels;
            let chunks = [];
            for (let i = 0; i < parcels.length; i += 3) {
                chunks.push(parcels.slice(i, i + 3));
            }

            // Function to process each chunk with a POST request
            const processChunk = (chunk) => {
                return axios.post(`/parcel/cells/geom`, {
                    ids: chunk.map((p) => p.id),
                });
            };

            // Execute all chunk requests in sequence (to avoid overloading the server)
            let sequence = Promise.resolve();

            let result = null;
            let count = 0;

            dispatch("planUi/SET_TO_BE_FETCHED", chunks.length, { root: true });

            chunks.forEach((chunk) => {
                sequence = sequence.then(() => {
                    return processChunk(chunk).then((response) => {
                        count++;
                        dispatch("planUi/SET_FETCHED", count, { root: true });
                        if (!result) {
                            result = response.data;
                        } else if (response.data.features) {
                            result.features = result.features.concat(
                                response.data.features
                            );
                        }
                    });
                });
            });

            // Finalize
            sequence
                .then(() => {
                    commit("setCellParcelsGeom", result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    dispatch("planUi/SET_FETCHING", false, { root: true });
                });
        });
    },

    APPEND_CELL_PARCELS({ commit }, parcelIds) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/parcel/cells/geom`, {
                    ids: parcelIds,
                })
                .then((response) => {
                    commit("appendCellParcelsGeom", response.data);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    GET_PARCEL_PRESCRIPTION_GEOM(
        { commit, getters, dispatch },
        { parcelId, reCaluclate }
    ) {
        return new Promise((resolve, reject) => {
            // parcel/{parcel}/prescriptiongeom
            axios
                .post("/parcel/" + parcelId + "/prescriptiongeom", {
                    gridSize: getters.prescriptionSettingCellSize,
                })
                .then((response) => {
                    commit("setPrescriptionFeatures", []);
                    commit("setPrescriptionOriginalGeom", response.data);

                    if (getters.settingsSelectedSubstance) {
                        // we want to recalculate the min and max values for the selected substance
                        commit(
                            "setPrescriptionSubtance",
                            getters.settingsSelectedSubstance
                        );
                    }

                    if (reCaluclate) {
                        dispatch("CALCULATE_GIVA").then(() => {
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },

    GET_BLOCK_POLYGONS({ commit }, { north, east, south, west }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/geojson/bbox", {
                    north: north,
                    east: east,
                    south: south,
                    west: west,
                })
                .then((response) => {
                    commit("setBlockPolygons", response.data);
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    RESET_BLOCK_POLYGONS({ commit }) {
        commit("deselectBlocks");
        commit("setBlockPolygons", null);
    },

    SELECT_BLOCK({ commit }, { id }) {
        commit("selectBlock", id);
    },

    DESELECT_ALL_BLOCKS({ commit }, { id }) {
        commit("deselectBlocks");
    },

    GET_YEARS({ commit }, customerId) {
        return new Promise((resolve, reject) => {
            axios
                .get("/customer/" + customerId + "/cultivationYears/years")
                .then((response) => {
                    commit("setYears", response.data);
                    resolve();
                });
        });
    },

    GET_CROPS({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("crop/npkbalance").then((response) => {
                commit("setCrops", response.data);
                resolve();
            });
        });
    },

    GET_CROP_CODES({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("cropcode/npkbalance").then((response) => {
                commit("setCropsCodes", response.data);
                resolve();
            });
        });
    },

    SET_CURRENT_PARCEL({ state, commit, dispatch }, { parcelId }) {
        dispatch("planUi/SET_SELECTED_RUNS", [], { root: true });

        if (state.selectedParcelIds.includes(parcelId)) {
            commit("deselectParcel", parcelId);
            dispatch("runs/CLEAR_RUNS", {}, { root: true });
        } else {
            dispatch("runs/GET_PARCEL_RUNS", parcelId, { root: true });
            commit("deselectAllParcels");
            commit("selectParcel", parcelId);
        }
    },

    TOGGLE_PARCEL({ commit, getters, dispatch }, { parcelId }) {
        return new Promise((resolve, reject) => {
            if (getters.selectedParcelIds.some((p_id) => p_id === parcelId)) {
                dispatch("planUi/SET_SELECTED_RUNS", [], { root: true });
                commit("deselectParcel", parcelId);
            } else {
                commit("selectParcel", parcelId);
            }
            resolve();
        });
    },

    SELECT_PARCEL({ state, commit, getters, dispatch }, { parcelId }) {
        dispatch("planUi/SET_SELECTED_RUNS", [], { root: true });
        return new Promise((resolve, reject) => {
            commit("selectParcel", parcelId);
            if (getters.selectedParcelIds.length === 1) {
                store.dispatch("runs/GET_PARCEL_RUNS", parcelId);
            } else {
                store.dispatch("runs/CLEAR_RUNS");
            }

            resolve();
        });
    },

    DESELECT_PARCEL({ commit, getters, dispatch }, { parcelId }) {
        dispatch("planUi/SET_SELECTED_RUNS", [], { root: true });
        return new Promise((resolve, reject) => {
            commit("deselectParcel", parcelId);
            if (getters.selectedParcelIds.length === 1) {
                store.dispatch("runs/GET_PARCEL_RUNS", getters.activeParcel.id);
            } else {
                store.dispatch("runs/CLEAR_RUNS");
            }
            resolve();
        });
    },

    UPDATE_PARCEL({ state, commit, dispatch }, { id, params }) {
        return new Promise((resolve, reject) => {
            axios
                .patch("parcel/" + id, params)
                .then((response) => {
                    commit("updateParcel", response.data);
                    dispatch("GET_POLYGONS", state.currentCultivationYear.id)
                        .then(() => {
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    ADD_PARCEL({ commit }, { cultivationYearId, feature }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/cultivationyear/" + cultivationYearId + "/addparcel", {
                    geom: feature.geometry,
                    area: area(feature) / 10000,
                })
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygons", response.data.polygons);
                    resolve();
                });
        });
    },

    ADD_PARCELS({ commit }, { cultivationYearId, features }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/cultivationyear/" + cultivationYearId + "/addparcels", {
                    parcels: features.map((feature) => {
                        return {
                            parcel_id: feature.properties?.blockid?.toString(),
                            name: feature.properties?.blockid?.toString(),
                            geom: feature.geometry,
                        };
                    }),
                })
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygons", response.data.polygons);
                    resolve();
                });
        });
    },

    REMOVE_PARCEL({ commit }, { cultivationYearId, parcelId }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/cultivationyear/" + cultivationYearId + "/removeparcel",
                    {
                        id: parcelId,
                    }
                )
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygons", response.data.polygons);
                    resolve();
                });
        });
    },

    UNITE_PARCELS({ commit }, { cultivationYearId, parcelA, parcelB }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/cultivationyear/" + cultivationYearId + "/uniteparcels",
                    {
                        parcelA: parcelA,
                        parcelB: parcelB,
                    }
                )
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygons", response.data.polygons);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    SPLIT_PARCEL(
        { commit },
        { cultivationYearId, parcelId, lineString, parcelPolygon }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/cultivationyear/" + cultivationYearId + "/splitparcel",
                    {
                        id: parcelId,
                        line_string: lineString,
                        polygon: parcelPolygon,
                    }
                )
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygons", response.data.polygons);
                    resolve();
                });
        });
    },

    CREATE_YEAR({ commit }, { customerId, blockIds, year }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/cultivationyear", {
                    customer_id: customerId,
                    year: year,
                    block_ids: blockIds,
                })
                .then((response) => {
                    commit("appendCultivationYear", response.data);
                    const newYear = response?.data?.year;
                    if (newYear) {
                        commit("appendYear", newYear);
                    }
                    dispatch("GET_CULTIVATION_YEAR", {
                        customerId: customerId,
                        year: year,
                    }).finally(() => {
                        resolve();
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    IMPORT_PREVIOUS_YEAR({ commit }, { customerId, importYearId, year }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/customer/" + customerId + "/import/cultivationyear", {
                    id: importYearId,
                    year: year,
                })
                .then((response) => {
                    commit("appendCultivationYear", response.data);
                    const newYear = response?.data?.year;
                    if (newYear) {
                        commit("appendYear", newYear);
                    }
                    dispatch("GET_CULTIVATION_YEAR", {
                        customerId: customerId,
                        year: response.data.year,
                    }).finally(() => {
                        resolve();
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    IMPORT_SAM_FILE({ commit, dispatch }, { customerId, formData }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/customer/" + customerId + "/importXML", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                    commit("appendCultivationYear", response.data);
                    const newYear = response?.data?.year;
                    if (newYear) {
                        commit("appendYear", newYear);
                    }

                    dispatch("GET_CULTIVATION_YEAR", {
                        customerId: customerId,
                        year: response.data.year,
                    }).finally(() => {
                        resolve();
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    RESET({ commit }) {
        commit("reset");
    },
};

const mutations = {
    setCultivationYear(state, cultivationYear) {
        state.currentCultivationYear = cultivationYear;
        state.selectedParcelIds = [];
        state.polygons = [];
    },

    setCultivationYears(state, cultivationYears) {
        state.cultivationYears = cultivationYears;
    },

    setCellParcelsGeom(state, geom) {
        state.minMax = calculateMinMax(geom);
        state.cellParcelsGeom = geom;
    },

    appendCellParcelsGeom(state, geom) {
        let newGeom = state.cellParcelsGeom;
        newGeom.features = newGeom.features.concat(geom.features);
        state.minMax = calculateMinMax(newGeom);
        state.cellParcelsGeom = newGeom;
    },

    appendCultivationYear(state, cultivationYear) {
        // Find the index where the cultivationYear should be inserted
        const index = state.cultivationYears.findIndex(
            (item) => item.year < cultivationYear.year
        );

        if (index === -1) {
            // If the year is less than all existing years, push it to the end
            state.cultivationYears.push(cultivationYear);
        } else {
            // Insert the year at the appropriate index to maintain sorting in descending order
            state.cultivationYears.splice(index, 0, cultivationYear);
        }
    },

    appendYear(state, year) {
        const intYear = parseInt(year);
        if (!state.years.includes(intYear)) {
            state.years.push(intYear);
        }
    },

    deleteYear(state, id) {
        state.cultivationYears = state.cultivationYears.filter(
            (cy) => cy.id !== id
        );

        if (
            state.currentCultivationYear &&
            state.currentCultivationYear.id === id
        ) {
            state.currentCultivationYear = null;
        }
    },

    setYears(state, years) {
        state.years = years;
    },

    setCrops(state, data) {
        state.crops = data;
    },

    setCropsCodes(state, data) {
        state.cropCodes = data;
    },

    selectParcel(state, parcelId) {
        if (!state.selectedParcelIds.includes(parcelId)) {
            state.selectedParcelIds.push(parcelId);
        }
    },

    deselectParcel(state, parcelId) {
        let index = state.selectedParcelIds.findIndex((id) => id === parcelId);
        if (index >= 0) {
            state.selectedParcelIds.splice(index, 1);
        }
    },

    deselectAllParcels(state) {
        state.selectedParcelIds = [];
    },

    updateParcel(state, params) {
        let index = state.currentCultivationYear.parcels.findIndex(
            (parcel) => parcel.id === params.id
        );

        if (index > -1) {
            let updatedParcel = {
                ...state.currentCultivationYear.parcels[index],
                ...params,
            };

            state.currentCultivationYear.parcels[index] = updatedParcel;
            state.currentCultivationYear = JSON.parse(
                JSON.stringify(state.currentCultivationYear)
            );
        }
    },

    setPolygons(state, data) {
        let polygons = data[0];
        let features = JSON.parse(polygons.features);
        polygons.features = features ? features : [];
        state.polygons = polygons;
        state.cropInfo = aggregateCrops(features);
    },

    setBlockPolygons(state, data) {
        state.blockPolygons = data;
    },

    selectBlock(state, newId) {
        if (state.selectedBlockIds.includes(newId)) {
            state.selectedBlockIds = state.selectedBlockIds.filter(
                (id) => id != newId
            );
        } else {
            state.selectedBlockIds.push(newId);
        }
    },

    deselectBlocks(state) {
        state.selectedBlockIds = [];
    },

    resetCultivationYear(state) {
        state.polygons = null;
        state.currentCultivationYear = null;
        state.selectedParcelIds = [];
    },

    reset(state) {
        state.cultivationYears = [];
        state.selectedParcelIds = [];
        state.polygons = null;
        state.currentCultivationYear = null;
        state.years = [];
        state.minMax = [];
        state.cellParcelsGeom = null;
        state.selectedBlockIds = [];
        state.samples = [];
        state.crops = [];
        state.cropCodes = [];
        state.cropInfo = null;
    },
    setSamples(state, samples) {
        state.samples = JSON.parse(samples);
    },
};

function getSelectedParcels(state) {
    if (state.currentCultivationYear && state.currentCultivationYear.parcels) {
        return state.currentCultivationYear.parcels.filter((p) => {
            return state.selectedParcelIds.includes(p.id);
        });
    }
    return [];
}

function getAvaliableTypes(state, rootGetters) {
    return state.types.filter((type) => {
        if (type.shouldFilter) {
            return rootGetters["interpolationSettings/settings"].find(
                (setting) => {
                    return setting.type === type.value;
                }
            );
        }

        return true;
    });
}

function aggregateCrops(features) {
    let totalAreal = 0;
    const cropSummary = features?.reduce((acc, feature) => {
        const {
            crop_name = "Ingen gröda", // Default crop name if undefined or empty
            areal,
            crop_color,
        } = feature.properties;

        totalAreal += areal;

        // Assign 'Ingen gröda' if crop_name is empty or not present
        const name = crop_name || "Ingen gröda";

        if (acc[name]) {
            // Add to existing totalAreal
            acc[name].areal += areal;
        } else {
            // Initialize with totalAreal when first creating the entry
            acc[name] = {
                name: name,
                areal: areal,
                color: crop_color ? crop_color : "gray",
            };
        }
        return acc;
    }, {});

    // Convert aggregated data into an array of objects as requested
    if (cropSummary) {
        return Object.values(cropSummary);
    }
    return [];
}

function findClosestCultivationYear(cultivationYears) {
    const currYear = new Date().getFullYear();
    let closestYear = null;
    let smallestDifference = Number.MAX_SAFE_INTEGER;

    for (const yearObj of cultivationYears) {
        const difference = yearObj.year - currYear;

        if (difference === 0) {
            return yearObj; // Current year found, return immediately
        }

        if (
            Math.abs(difference) < smallestDifference ||
            (difference === smallestDifference && difference > 0)
        ) {
            smallestDifference = Math.abs(difference);
            closestYear = yearObj;
        }
    }

    return closestYear;
}

function calculateMinMax(cellParcelsGeom) {
    const minMaxProperties = ["n_need", "p_need", "k_need"];
    let minMax = [];
    if (cellParcelsGeom?.features?.length) {
        cellParcelsGeom.features.forEach((f) => {
            minMaxProperties.forEach((key) => {
                if (key !== "id" && key !== "geometry") {
                    let value = f.properties[key];
                    // check if value is null or undefined
                    // if so set it to 0
                    value = !value ? 0 : value;

                    // check if minMax object has the key, if not create it
                    if (!(key in minMax)) {
                        minMax[key] = {
                            min: value,
                            max: value,
                        };
                    }

                    if (value < minMax[key].min) {
                        minMax[key].min = value;
                    }
                    if (value > minMax[key].max) {
                        minMax[key].max = value;
                    }
                }
            });
        });
    }

    return minMax;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
