const namespaced = true;

const state = {
    dates: {
        start: null,
        end: null,
        selected: null,
        available: null,
    },
    bounds: null,
    geoJSON: [],
    polygons: null,
    grid: {
        type: "FeatureCollection",
        features: [],
    },
    previews: [],
    loading: false,
    error: null,
};

const getters = {
    exportState: (state) => getExportState(state),
    date: (state) => state.dates.selected,
    availableDates: (state) => state.dates.available,
    previews: (state) => state.previews,
    bounds: (state) => state.bounds,
    loading: (state) => state.loading,
    grid: (state) => state.grid,
    polygons: (state) => state.polygons,
    nbrFields: (state) => (state.polygons ? state.polygons.length : 0),
    error: (state) => state.error,

    minMax: (state) => {
        let min = Number.MAX_SAFE_INTEGER;
        let max = 0;

        state.grid.features.forEach((feature) => {
            if (feature.properties.value < min) {
                min = feature.properties.value;
            }
            if (feature.properties.value > max) {
                max = feature.properties.value;
            }
        });

        return {
            min: min,
            max: max,
        };
    },

    givaBounds: (state) => {
        let min = Number.MAX_SAFE_INTEGER;
        let max = 0;
        state.grid.features.forEach((feature) => {
            if (!feature.modified && feature.properties.giva < min) {
                min = feature.properties.giva;
            }
            if (!feature.modified && feature.properties.giva > max) {
                max = feature.properties.giva;
            }
        });
        return {
            min: min,
            max: max,
        };
    },

    colorGrading: (state, getters) => {
        // If there are no min and max values return 0
        if (getters.givaBounds.min == 0 && getters.givaBounds.max == 0) {
            return [0, 0, 0, 0, 0, 0, 0, 0].map((grade) => {
                return { value: null, area: getters.totalArea.toFixed(1) };
            });
        }

        let old = getters.givaBounds.min;
        let length = 8;
        let colorGrading = [];

        colorGrading[0] = null;
        colorGrading[1] = getters.givaBounds.min;

        for (var i = 2; i < length + 1; i++) {
            colorGrading[i] =
                (getters.givaBounds.max - getters.givaBounds.min) / length +
                old;
            old = colorGrading[i];
        }

        colorGrading = colorGrading.map((grade, i) => {
            return {
                value: grade,
                area: getAreaForGrade(colorGrading, i, grade, state.grid),
            };
        });

        return colorGrading;
    },

    colors: (state, getters, rootState, rootGetters) => {
        return getters.colorGrading.map((grade, i) => {
            return {
                from: grade ? grade.value : null,
                to: getters.colorGrading[i + 1]
                    ? getters.colorGrading[i + 1].value
                    : null,
                color: rootGetters["colors/precisionColors"][i],
            };
        });
    },

    displaySateliteGrid: (state) => {
        return !state.loading && state.grid.features.length;
    },

    totalGiva: (state) => {
        return state.grid.features.reduce((sum, feature) => {
            return (sum +=
                (feature.properties.giva * feature.properties.area) / 10000);
        }, 0);
    },

    totalArea: (state) => {
        return state.grid.features.reduce((sum, feature) => {
            return (sum += feature.properties.area / 10000);
        }, 0);
    },

    zeroArea: (state) => {
        return state.grid.features.reduce((sum, feature) => {
            if (feature.properties.giva === 0) {
                return (sum += feature.properties.area / 10000);
            }
            return sum;
        }, 0);
    },

    info: (state, getters, rootState, rootGetters) => {
        return {
            totalArea: +getters.totalArea.toFixed(1) + " ha",
            maxLimit:
                getters.givaBounds.max > 1000
                    ? (getters.givaBounds.max / 1000).toFixed(1) + " ton/ha"
                    : (getters.givaBounds.max
                          ? getters.givaBounds.max.toFixed(1)
                          : 0) + " kg/ha",
            minLimit:
                getters.givaBounds.min > 1000
                    ? (getters.givaBounds.min / 1000).toFixed(1) + " ton/ha"
                    : (getters.givaBounds.min
                          ? getters.givaBounds.min.toFixed(1)
                          : 0) + " kg/ha",
            totalAmount: (getters.totalGiva / 1000).toFixed(1) + " ton",
            zeroArea:
                +getters.zeroArea.toFixed(1) +
                " ha (" +
                +((getters.zeroArea / getters.totalArea) * 100).toFixed(1) +
                "%)",
            method: "Satellit",
            grades: getters.colors.map((grade, i) => {
                return {
                    color: grade.color,
                    area: getters.colorGrading[i].area,
                    value:
                        grade.from === null
                            ? "Ingen giva"
                            : parseInt(grade.from) +
                              " - " +
                              parseInt(
                                  grade.to ? grade.to : getters.givaBounds.max
                              ),
                };
            }),
            manualGrades: rootGetters["manualGiva/appliedGivorSorted"].map(
                (giva) => {
                    let color;
                    let index = rootGetters[
                        "manualGiva/appliedGivorSorted"
                    ].indexOf(giva.toString());
                    if (index !== -1) {
                        index =
                            rootGetters["manualGiva/colors"].length -
                            (rootGetters["manualGiva/appliedGivorSorted"]
                                .length -
                                index);
                        color = rootGetters["manualGiva/colors"][index];
                    }
                    return {
                        value: giva,
                        color: color,
                    };
                }
            ),
        };
    },
};

const actions = {
    SET_SELECTED_DATE({ commit, dispatch }, date) {
        return new Promise((resolve) => {
            commit("setSelectedDate", date);
            dispatch("CLEAR_GRID_AND_SETTINGS");
            resolve();
        });
    },

    RESET_DATES({ commit }) {
        commit("resetDates");
    },

    SET_BOUNDS({ commit }, bounds) {
        commit("setBounds", bounds);
    },

    SET_DATE_RANGE({ commit, dispatch }, dateRange) {
        return new Promise((resolve) => {
            commit("setDateRange", dateRange);
            dispatch("GET_AVAILABLE_DATES").then((data) => {
                resolve(data);
            });
        });
    },

    GET_AVAILABLE_DATES({ commit, state }) {
        return axios
            .post("/sat/dates", {
                start: state.dates.start,
                end: state.dates.end,
                north_east_lat: state.bounds._northEast.lat,
                north_east_lng: state.bounds._northEast.lng,
                south_west_lat: state.bounds._southWest.lat,
                south_west_lng: state.bounds._southWest.lng,
            })
            .then((response) => {
                let features = response.data.features
                    .reverse()
                    .filter(
                        (feature) =>
                            feature.properties.cloudCoverPercentage < 50
                    );

                let dates = features.map((feature) => {
                    return {
                        id: feature.properties.id,
                        date: feature.properties.date,
                        cloudPercentage:
                            feature.properties.cloudCoverPercentage,
                    };
                });

                dates = _.uniqBy(dates, "date").sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                );

                commit("setAvailableDates", dates);

                return dates;
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    commit("setError", "dates");
                }
            });
    },

    SET_POLYGONS({ commit, dispatch }, polygons) {
        commit(
            "setPolygons",
            polygons.map((polygon) => polygon.feature)
        );
        commit(
            "setGeoJSON",
            polygons.map((polygon) => {
                return polygon.toGeoJSON().geometry;
            })
        );
        dispatch("GET_GRID");
    },

    GET_GRID({ commit, state }) {
        commit("setLoading", true);
        let southWest = L.CRS.EPSG3857.project(state.bounds._southWest);
        let northEast = L.CRS.EPSG3857.project(state.bounds._northEast);

        let bbox = `${southWest.x},${southWest.y},${northEast.x},${northEast.y}`;

        axios
            .all(
                state.geoJSON.map((geoJSON) => {
                    return axios.post("/sat/process", {
                        date: state.dates.selected,
                        bbox: bbox,
                        geojson: JSON.stringify(geoJSON),
                    });
                })
            )
            .then((responses) => {
                responses.forEach((response, index) => {
                    let polygonId = state.polygons[index].properties.id;
                    commit("addFeaturesToGrid", {
                        features: response.data.geojson.features,
                        polygon_id: polygonId,
                    });
                });
                commit("setLoading", false);
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    commit("setError", "grid");
                }
            });
    },

    SET_PREVIEWS({ commit, dispatch, state }, dates) {
        const existingPreviewDates = state.previews.map(
            (preview) => preview.date
        );
        const newDates = dates.filter(
            (date) => !existingPreviewDates.includes(date)
        );
        const datesToBeRemoved = existingPreviewDates.filter(
            (date) => !dates.includes(date)
        );

        commit("removePreviews", datesToBeRemoved);

        const duration = datesToBeRemoved.length == 0 ? 0 : 500;
        // we use a timeout to enable the animation to finish
        setTimeout(() => {
            commit("addPreviews", newDates);
        }, duration);
    },

    UPDATE_GRID({ commit }, gridLayer) {
        let features = Object.entries(gridLayer._layers).map(
            (layer) => layer[1].feature
        );
        commit("updateGrid", JSON.parse(JSON.stringify(features)));
    },

    CLEAR_GRID_AND_SETTINGS({ commit, dispatch }) {
        commit("emtpyGrid");
        dispatch("manualGiva/CLEAR_STATE", {}, { root: true });
        dispatch("adjustments/CLEAR_STATE", {}, { root: true });
        dispatch("adjust/CLEAR_STATE", {}, { root: true });
    },

    SET_STATE({ commit }, newState) {
        commit("setState", newState);
    },

    SET_FEATURES({ commit }, features) {
        commit("updateGrid", features);
    },
};

const mutations = {
    resetDates(state) {
        state.dates = {
            start: null,
            end: null,
            selected: null,
            available: null,
        };
    },

    setSelectedDate(state, date) {
        state.dates.selected = date;
    },

    removePreviews(state, dates) {
        state.previews = state.previews
            .filter((preview) => !dates.includes(preview.date))
            .sort((a, b) => new Date(a.date) - new Date(b.date));
    },

    addPreviews(state, newDates) {
        let southWest = L.CRS.EPSG3857.project(state.bounds._southWest);
        let northEast = L.CRS.EPSG3857.project(state.bounds._northEast);

        let newPreviews = structuredClone(state.previews);

        newDates.forEach((date) => {
            const endpoint = getPreviewEndpoint(
                date,
                southWest.x,
                southWest.y,
                northEast.x,
                northEast.y
            );

            newPreviews.push({
                date: date,
                image: endpoint,
            });
        });

        state.previews = newPreviews.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
    },

    setBounds(state, bounds) {
        state.bounds = bounds;
    },

    setDateRange(state, dateRange) {
        state.dates.start = dateRange.start;
        state.dates.end = dateRange.end;
    },

    setAvailableDates(state, dates) {
        state.dates.available = dates;
        state.available = null;
    },

    setPolygons(state, polygons) {
        state.polygons = polygons;
    },

    setGeoJSON(state, geJSONObjects) {
        state.geoJSON = geJSONObjects;
    },

    addFeaturesToGrid(state, { features, polygon_id }) {
        let cellFeatures = features;
        cellFeatures.forEach(
            (cell) => (cell.properties.polygon_id = polygon_id)
        );
        state.grid.features = state.grid.features.concat(features);
    },

    setLoading(state, status) {
        state.loading = status;
    },

    emtpyGrid(state) {
        state.grid.features = [];
    },

    updateGrid(state, features) {
        state.grid.features = features;
    },

    setError(state, error) {
        state.error = error;
    },

    setState(state, newState) {
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

function getAreaForGrade(colorGrading, i, grade, grid) {
    let upperboundExist = colorGrading.length > i + 1;
    let areaSum = grid.features.reduce((sum, feature) => {
        // Is the giva within the grade-bounds?
        if (grade === null && feature.properties.giva === 0) {
            return sum + feature.properties.area;
        } else if (
            grade === 0
                ? feature.properties.giva > grade
                : feature.properties.giva >= grade
        ) {
            if (
                upperboundExist
                    ? feature.properties.giva < colorGrading[i + 1]
                    : true
            ) {
                return sum + feature.properties.area;
            }
        }
        return sum;
    }, 0);

    return (areaSum / 10000).toFixed(2);
}

function getPreviewEndpoint(
    date,
    southWestX,
    southWestY,
    northEastX,
    northEastY,
    layer = "rgb"
) {
    const baseUrl = axios.defaults.baseURL;
    return encodeURI(
        `${baseUrl}/sat/preview/${date}/${layer}/${southWestX}/${southWestY}/${northEastX}/${northEastY}`
    );
}

function getExportState(state) {
    return {
        dates: state.dates,
        bounds: state.bounds,
        geoJSON: state.geoJSON,
        polygons: state.polygons,
    };
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
