const namespaced = true;

const state = {
    prescriptions: [],
    units: [
        {
            title: "kg/ha",
            totalTitle: "kg",
            precision: 1,
            isoXmlFactor: 100,
            slug: "kg_per_acre",
        },
        {
            title: "frö/ha",
            totalTitle: "frö",
            precision: 0,
            // per area in isoxml has /m2 as unit
            // wich means that we should convert ha to m2
            // (we want ha but have to write in m2)
            isoXmlFactor: 1 / 10000,
            slug: "seed_per_acre",
        },
        {
            title: "frö/m²",
            totalTitle: "frö",
            precision: 0,
            isoXmlFactor: 1,
            slug: "seed_per_sqrm",
        },
        // {
        //     title: "frö/m",
        //     totalTitle: "frö",
        //     precision: 0,
        //     slug: "seed_per_m",
        // },
    ],
    selectedUnitSlug: "kg_per_acre",
    thousandKernelWeight: null,
    germinatingPercentage: null,
    sharedPrescriptions: [],
    currentPrescription: null,
    temporaryPrescription: null,
    cache: {
        settings: null,
        parameters: null,
        gridInfo: null,
    },
};

const getters = {
    cache: (state) => state.cache,
    prescriptions: (state) => state.prescriptions,
    sharedPrescriptions: (state) => state.sharedPrescriptions,
    currentPrescription: (state) => state.currentPrescription,
    temporaryPrescription: (state) => state.temporaryPrescription,
    units: (state) => state.units,
    selectedUnit: (state) => getSelectedUnit(state),
    germinatingPercentage: (state) => state.germinatingPercentage,
    thousandKernelWeight: (state) => state.thousandKernelWeight,
};

const actions = {
    ADD_PRESCRIPTION(
        { commit, state },
        {
            grid,
            customer_id,
            polygons,
            planned_date,
            action_date,
            title,
            comment,
            store_state,
        }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .post("/prescription", {
                    cells: grid.features.map((f) => {
                        let parameters = {};
                        Object.keys(f.properties).forEach((key) => {
                            if (key != "polygon_id") {
                                parameters[key] = f.properties[key];
                            }
                        });
                        return {
                            geometry: JSON.stringify(f.geometry),
                            lat: f.properties.center["lat"],
                            lng: f.properties.center["lng"],
                            area: f.properties.area,
                            polygon_id: f.properties.polygon_id,
                            properties: JSON.stringify(f.properties),
                        };
                    }),
                    customer_id: customer_id,
                    settings: state.cache.settings,
                    parameters: state.cache.parameters,
                    gridinfo: state.cache.gridInfo,
                    store_state: store_state,
                    polygons: polygons.map((p) => ({
                        geom: JSON.stringify(p.geometry),
                        id: p.properties.id,
                    })),
                    planned_date: planned_date,
                    action_date: action_date,
                    title: title,
                    comment: comment,
                })
                .then((response) => {
                    commit("addPrescription", response.data);
                    resolve();
                    bus.$emit("display-notification", {
                        title: "Styrfilen är nu sparad",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    bus.$emit("display-notification", {
                        title: "Något gick fel då styrfilerna skulle hämtas. Kanske har ni inte licensen som krävs?",
                        type: "error",
                    });
                    reject(error);
                });
        });
    },

    FIND_PRESCRIPTIONS({ commit, state }, { polygons }) {
        return new Promise((resolve, reject) => {
            axios
            .post("/prescription/findprescriptions", {
                polygons: polygons,
            })
            .then((response) => {
                resolve();
                commit("setPrescriptions", response.data);
            })
            .catch(function (error) {
                reject();
                bus.$emit("display-notification", {
                    title: "Något gick fel då styrfilerna skulle hämtas. Kanske har ni inte licensen som krävs?",
                    type: "error",
                });
            });
        });
    },
    GET_SHARED_PRESCRIPTIONS({ commit, state }, customers) {
        axios
            .post("/prescription/shared", {
                customers: customers,
            })
            .then((response) => {
                commit("setSharedPrescriptions", response.data);
            })
            .catch(function (error) {
                bus.$emit("display-notification", {
                    title: "Något gick fel då styrfilerna skulle hämtas. Kanske har ni inte licensen som krävs?",
                    type: "error",
                });
            });
    },

    GET_ALL_PRESCRIPTIONS({ commit, state }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/prescription")
                .then((response) => {
                    commit("setPrescriptions", response.data);
                    resolve();
                })
                .catch(function (error) {
                    bus.$emit("display-notification", {
                        title: "Något gick fel då styrfilerna skulle hämtas. Kanske har ni inte licensen som krävs?",
                        type: "error",
                    });
                    reject();
                });
        });
    },

    GET_ALL_PRESCRIPTIONS_FOR_CUSTOMER({ commit, state }, { customerId }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/prescription/customer/" + customerId)
                .then((response) => {
                    commit("setPrescriptions", response.data);
                    resolve();
                })
                .catch(function (error) {
                    bus.$emit("display-notification", {
                        title: "Något gick fel då styrfilerna skulle hämtas. Kanske har ni inte licensen som krävs?",
                        type: "error",
                    });
                    reject();
                });
        });
    },

    GET_PRESCRIPTION({ commit, state }, { prescription_id }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/prescription/" + prescription_id)
                .then((response) => {
                    commit("setCurrentPrescription", response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    bus.$emit("display-notification", {
                        title: "Något gick fel då styrfilerna skulle hämtas. Kanske har ni inte licensen som krävs?",
                        type: "error",
                    });
                    reject(error);
                });
        });
    },

    ADD_TEMP_PRESCRIPTION({ commit }, prescription) {
        commit("setTempPrescription", prescription);
    },

    REMOVE_PRESCRIPTION({ commit, state }, { prescription_id }) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/prescription/" + prescription_id)
                .then(() => {
                    commit("removePrescription");
                    resolve();
                })
                .catch((error) => () => {
                    reject(error);
                    bus.$emit("display-notification", {
                        title: "Det gick inte att ta bort styrfilen. Har du rätt att ta bort den?",
                        type: "error",
                    });
                });
        });
    },

    RESET_CURRENT_PRESCRIPTION({ commit }) {
        commit("removePrescription");
    },

    SET_SETTINGS({ commit }, settings) {
        commit("setSettings", settings);
    },

    SET_PARAMETERS({ commit }, parameters) {
        commit("setParameters", parameters);
    },

    SET_GRIDINFO({ commit }, gridInfo) {
        commit("setGridInfo", gridInfo);
    },

    SET_UNIT({ commit }, unit) {
        commit("setUnit", unit);
    },

    SET_GERMINATING_PERCENTAGE({ commit }, value) {
        commit("setGerminatingPercentage", value);
    },

    SET_THOUSAND_KERNEL_WEIGHT({ commit }, value) {
        commit("setThousandKernelWeight", value);
    },

    CLEAR_STORE({ commit, sate }) {
        commit("clear");
    },

    UPDATE_PRESCRIPTION_INFO({ commit }, { prescription_id, params }) {
        return new Promise((resolve, reject) => {
            axios
                .patch("/prescription/" + prescription_id + "/info", params)
                .then((response) => {
                    commit("updatePrescriptionInfo", response.data);
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    setSettings(state, settings) {
        state.cache.settings = settings;
    },

    setParameters(state, parameters) {
        state.cache.parameters = parameters;
    },

    setGridInfo(state, gridInfo) {
        state.cache.gridInfo = gridInfo;
    },

    setUnit(state, unit) {
        state.selectedUnitSlug = unit;
    },

    setGerminatingPercentage(state, value) {
        value = parseFloat(value);
        if (value > 100) {
            value = 100;
        } else if (value < 0) {
            value = 0;
        }
        state.germinatingPercentage = value;
    },

    setThousandKernelWeight(state, value) {
        value = parseFloat(value);
        if (value < 0) {
            value = 0;
        }
        state.thousandKernelWeight = value;
    },

    setPrescriptions(state, prescriptions) {
        state.prescriptions = prescriptions;
    },

    setSharedPrescriptions(state, prescriptions) {
        state.sharedPrescriptions = prescriptions;
    },

    setCurrentPrescription(state, prescription) {
        state.currentPrescription = prescription;
    },

    addPrescription(state, prescription) {
        state.prescriptions.push(prescription);
    },

    removePrescription(state) {
        state.currentPrescription = null;
    },

    setTempPrescription(state, prescription) {
        state.temporaryPrescription = prescription;
        state.temporaryPrescription.grid.features.sort((a, b) => {
            if (a.properties.center.lat === b.properties.center.lat) {
                return a.properties.center.lng - b.properties.center.lng;
            }
            return a.properties.center.lat - b.properties.center.lat;
        });
    },

    updatePrescriptionInfo(info) {
        state.currentPrescription.title = info.title;
        state.currentPrescription.shared = info.shared;
        state.currentPrescription.comment = info.comment;
        state.currentPrescription.planned_date = info.planned_date;
        state.currentPrescription.action_date = info.action_date;
    },

    clear(state) {
        state.prescriptions = [];
        state.sharedPrescriptions = [];
        state.currentPrescription = null;
        state.temporaryPrescription = null;
        state.cache = {
            settings: null,
            parameters: null,
            gridInfo: null,
        };
    },
};

// Calculates the factor and sets eventual errors for the selected unit
// The static unit information is stored in the state object directly
function getSelectedUnit(state) {
    let selectedUnit = state.units.find(
        (u) => u.slug === state.selectedUnitSlug
    );
    return selectedUnit;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
