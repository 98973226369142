import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import createPersistedState from "vuex-persistedstate";

const dataState = createPersistedState({
    paths: ["auth", "support-auth", "activeCustomer", "settings"],
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== "production",
    plugins: [dataState],
});
