import { interpolateValue } from "@/helpers/interpolateValue.js";

/**
 * 	Values are stored i the targetpHValues matrix.
 * 	Calculate the correct target pH based on the lerhalt and mullhalt values.
 *
 * 	@param {object} sample
 * 	@param {boolean} sockerbetor Default false
 */
export function calculateTargetPh(sample, sockerbetor = false) {
    let lerhalt = sample.properties.lerhalt
        ? sample.properties.lerhalt
        : sample.properties.idw_lerhalt;
    let mullhalt = sample.properties.mullhalt
        ? sample.properties.mullhalt
        : sample.properties.idw_mullhalt;

    let lerhaltIndex = null;

    if (lerhalt < 5) {
        lerhaltIndex = 0;
    } else if (lerhalt < 15) {
        lerhaltIndex = 1;
    } else if (lerhalt < 25) {
        lerhaltIndex = 2;
    } else if (lerhalt < 40) {
        lerhaltIndex = 3;
    } else if (lerhalt < 60) {
        lerhaltIndex = 4;
    } else {
        lerhaltIndex = 5;
    }

    let mullData = undefined;
    if (sockerbetor) {
        mullData = getDefaultValuesSockerbetor().map(
            (row) => row[lerhaltIndex]
        );
    } else {
        mullData = getDefaultValuesSpannmal().map((row) => row[lerhaltIndex]);
    }

    if (mullhalt < 6) {
        return mullData[0];
    } else if (mullhalt >= 40) {
        return mullData[mullData.length - 1];
    } else {
        return interpolateTargetpHBasedOnMullhalt(mullData, mullhalt);
    }
}

function interpolateTargetpHBasedOnMullhalt(values, mullhalt) {
    let mullRanges = [
        { x: 0, y: values[0] },
        { x: 6, y: values[0] },
        { x: 9, y: values[1] },
        { x: 16, y: values[2] },
        { x: 30, y: values[3] },
        { x: 40, y: values[4] },
    ];

    for (let i = 0; i < mullRanges.length; i++) {
        if (mullhalt < mullRanges[i].x) {
            return interpolateValue(
                mullhalt,
                mullRanges[i - 1].x, // x1
                mullRanges[i].x, // x2
                mullRanges[i - 1].y, // y1
                mullRanges[i].y // y2
            );
        }
    }
}

function getDefaultValuesSpannmal() {
    return [
        [6.0, 6.2, 6.3, 6.4, 6.5, 6.5],
        [5.8, 5.9, 6.0, 6.1, 6.2, 6.2],
        [5.5, 5.6, 5.7, 5.8, 5.9, 5.9],
        [5.2, 5.3, 5.4, 5.5, 5.6, 5.6],
        [5.0, 5.1, 5.2, 5.3, 5.4, 5.4],
    ];
}

function getDefaultValuesSockerbetor() {
    return [
        [6.5, 6.7, 6.8, 6.9, 7.0, 7.0],
        [6.3, 6.4, 6.5, 6.6, 6.7, 6.7],
        [6.0, 6.1, 6.2, 6.3, 6.4, 6.4],
        [5.7, 5.8, 5.9, 6.0, 6.1, 6.1],
        [5.5, 5.6, 5.7, 5.8, 5.9, 5.9],
    ];
}
