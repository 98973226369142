const namespaced = true;
import types from "@/utils/types";

const state = {
    type: null,
    crop: null,
    yield: null,
    product: null,
    substance: null,
};

const getters = {
    params: (state) => {
        return {
            type: state.type,
            crop: state.crop,
            yield: state.yield,
            product: state.product,
            substance: state.substance,
        };
    },
    type: (state) => state.type,
    crop: (state) => state.crop,
    yield: (state) => state.yield,
    product: (state) => state.product,
    substance: (state) => state.substance,
    substanceOptions: (state, getters, rootState, rootGetters) =>
        getSubstanceOptions(rootGetters),
    typeOptions: (state, getters, rootState, rootGetters) =>
        getTypeOptions(rootGetters),
};

const actions = {
    SET_PARAMS({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit("setParams", params);
            resolve();
        });
    },

    SET_YIELD({ commit }, yieldValue) {
        return new Promise((resolve, reject) => {
            commit("setYeild", yieldValue);
            resolve();
        });
    },

    RESET_PARAMS({ commit }) {
        commit("resetParams");
    },
};

const mutations = {
    setParams(state, params) {
        if (state.type != params.type) state.type = params.type;
        if (state.crop != params.crop) state.crop = params.crop;
        if (state.yield != params.yield) state.yield = params.yield;
        if (state.product != params.product) state.product = params.product;
        if (state.substance != params.substance)
            state.substance = params.substance;
    },

    setYeild(state, yieldValue) {
        state.yield = yieldValue;
    },

    resetParams(state) {
        state.type = null;
        state.crop = null;
        state.yield = null;
        state.product = null;
        state.substance = null;
    },
};

function getSubstanceOptions(rootGetters) {
    let user = rootGetters["auth/user"];

    const defaultOptionKeys = [
        "p_al",
        "p_olsen",
        "ca_al",
        "k_al",
        "k_hcl",
        "mg_al",
        "mullhalt",
        "k_mg_kvot",
        "magnesiumbehov",
        "lerhalt",
        "ph",
        "bor",
        "zn",
        "cu",
        "cr",
        "ni",
        "pb",
        "fe_al",
        "mineralkvave_kg_n_ha",
        "mineralkvave_no3_n",
        "mineralkvave_nh4_n",
        "hg",
        "t_varde",
        "al_as",
        "volume_corrected_p_al",
        "volume_corrected_k_al",
        "volume_corrected_mg_al",
        "volume_corrected_ca_al",
        "volume_corrected_fe_al",
        "volume_corrected_al_al",
        "volume_corrected_k_hcl",
        "volume_corrected_cu_hcl",
    ];

    const hsVastraOptionKeys = ["s_varde", "kalkbehov", "cu_hcl"];

    let options = types.filter((type) => defaultOptionKeys.includes(type.slug));

    if (user && user.region.id == 6) {
        options = options.concat(
            types.filter((type) => hsVastraOptionKeys.includes(type.slug))
        );
    }

    return options.sort((a, b) => a.title.localeCompare(b.title));
}

function getTypeOptions(rootGetters) {
    let typeOptions = [];

    const user = rootGetters["auth/user"];
    const mullhaltTrigger = import.meta.env.VITE_MULLHALT_VC_TRIGGER || 12;
    const maxMullhalt = rootGetters["block/maxMullhalt"];

    if (hasLicense(user, "styrfil_type_phosphorus")) {
        const p_al = types.find((t) => t.slug == "p_al");
        if (p_al) {
            typeOptions.push({
                value: p_al.slug,
                title: p_al.title,
            });
        }
        if (maxMullhalt >= mullhaltTrigger) {
            const volume_corrected_p_al = types.find(
                (t) => t.slug == "volume_corrected_p_al"
            );
            if (volume_corrected_p_al) {
                typeOptions.push({
                    value: volume_corrected_p_al.slug,
                    title: volume_corrected_p_al.title,
                });
            }
        }
    }

    if (hasLicense(user, "styrfil_type_potassium")) {
        const k_al = types.find((t) => t.slug == "k_al");
        if (k_al) {
            typeOptions.push({
                value: k_al.slug,
                title: k_al.title,
            });
        }
        if (maxMullhalt >= mullhaltTrigger) {
            const volume_corrected_k_al = types.find(
                (t) => t.slug == "volume_corrected_k_al"
            );
            if (volume_corrected_k_al) {
                typeOptions.push({
                    value: volume_corrected_k_al.slug,
                    title: volume_corrected_k_al.title,
                });
            }
        }
    }

    if (hasLicense(user, "styrfil_type_chalk")) {
        typeOptions.push({ value: "chalk", title: "Kalk (pH)" });
    }
    if (hasLicense(user, "lerhaltskartan_view")) {
        typeOptions.push({
            value: "lerhaltskartan_advice",
            title: "Utsäde och kalk efter lerhalter och topografi",
        });
    }
    if (hasLicense(user, "styrfil_type_satellite")) {
        typeOptions.push({ value: "satellite", title: "Satellit" });
    }
    if (hasLicense(user, "styrfil_type_custom")) {
        typeOptions.push({ value: "custom", title: "Anpassad styrfil" });
    }

    return typeOptions;
}

function hasLicense(user, slug) {
    if (user && user.licenses) {
        return user.licenses[slug];
    }
    // return false;
    return true;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
