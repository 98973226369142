import { interpolateValue } from "@/helpers/interpolateValue.js";


const namespaced = true;

const state = {
    // prescription section
    type: 'plan_prescription',
    working: false,
    fetching: false,
    polygons: [],
    prescriptionsFeatures: [],
    settingsCellSize: 20,
    prescriptionOriginalGeom: null,
    settingsMin: null,
    settingsMax: null,
    settingsOverrideMin: null,
    settingsOverrideMax: null,
    settingOverrideGiva: 0,
    settingsSubtractGiva: 0,
    settingsTotal: null,
    settingsSelectedUnit: 'kg/ha',
    settingsSelectedSubstance: null,
    settingsSelectedProduct: null,
    settingsAmounts: [
        { value: 5, amount: 100 },
        { value: 10, amount: 200 },
    ],
    settingsUseDecimals: false,
    settingsIncludeZero: false,


    prescriptionOriginalTotalGiva: 0,
    prescriptionSubstanceMin: 0,
    prescriptionSubstanceMax: 0,
    prescriptionAvailableProperties: [],
    prescriptionMin: 0,
    prescriptionMax: 0,
    prescriptionTitle: 0,
    prescriptionTotal: 0,
    product: null,
    prescriptionPlanedGiva: 0,

};

const getters = {
    prescriptionSettingCellSize: (state) => state.settingsCellSize,
    prescriptionSettingAmounts: (state) => state.settingsAmounts,
    prescriptionSettingMin: (state) => state.settingsMin,
    prescriptionSettingMax: (state) => state.settingsMax,
    prescriptionSettingTotal: (state) => state.settingsTotal,
    prescriptionSettingIncludeZero: (state) => state.settingsIncludeZero,
    prescriptionSettingUseDecimals: (state) => state.settingsUseDecimals,
    prescriptionSettingsOverrideMin: (state) => state.settingsOverrideMin,
    prescriptionSettingsOverrideMax: (state) => state.settingsOverrideMax,
    prescriptionSettingOverrideGiva: (state) => state.settingOverrideGiva,
    prescriptionSettingSubtractGiva: (state) => state.settingsSubtractGiva,
    prescriptionSettingsSelectedUnit: (state) => state.settingsSelectedUnit,
    prescriptionProduct: (state) => state.product,
    prescriptionPlanedGiva: (state) => state.prescriptionPlanedGiva,
    prescriptionOriginalGeom: (state) => state.prescriptionOriginalGeom,
    prescriptionFeatures: (state) => state.prescriptionsFeatures,
    prescriptionPolygons: (state) => state.polygons,
    prescriptionSelectedSubtance: (state) => state.settingsSelectedSubstance,
    prescriptionSubstanceMin: (state) => state.prescriptionSubstanceMin,
    prescriptionSubstanceMax: (state) => state.prescriptionSubstanceMax,
    prescriptionOriginalTotalGiva: (state) => state.prescriptionOriginalTotalGiva,
    prescriptionTitle: (state) => state.prescriptionTitle,
    prescriptionMin: (state) => state.prescriptionMin,
    prescriptionMax: (state) => state.prescriptionMax,
    prescriptionTotal: (state) => state.prescriptionTotal,
    prescriptionAvailableTypes: (state, getters, rootState, rootGetters) => rootGetters["plan/types"].filter(type => state.prescriptionAvailableProperties.includes(type.value)),
    prescriptionAreal: (state, getters, rootState, rootGetters) => rootGetters["plan/selectedPolygons"].reduce((acc, cur) => acc + cur.properties.areal, 0),
    working: (state) => state.working,
    fetching: (state) => state.fetching,
};

const actions = {

    GET_PARCEL_PRESCRIPTION_GEOM({ commit, getters, dispatch, rootGetters }, { parcelId, reCaluclate }) {
        return new Promise((resolve, reject) => {
            // parcel/{parcel}/prescriptiongeom
            axios
                .post("/parcel/" + parcelId + "/prescriptiongeom", {
                    gridSize: getters.prescriptionSettingCellSize,
                })
                .then((response) => {
                    commit("setPrescriptionFeatures", []);
                    commit("setPrescriptionOriginalGeom", {
                        data: response.data,
                        types: rootGetters["plan/types"],
                    });

                    if (getters.settingsSelectedSubstance) {
                        // we want to recalculate the min and max values for the selected substance
                        commit("setPrescriptionSubtance", getters.settingsSelectedSubstance);
                    }

                    if (reCaluclate) {
                        dispatch("CALCULATE_GIVA").then(() => {
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },

    CALCULATE_GIVA({ commit, getters, state }) {
        return new Promise((resolve, reject) => {
            commit("setWorking", true);

            setTimeout(() => {
                const features = getters.prescriptionFeatures?.length ? getters.prescriptionFeatures : getters.prescriptionOriginalGeom?.features;
                const setTotalGiva = !getters.prescriptionFeatures?.length;

                if (features?.length &&
                    getters.prescriptionSelectedSubtance &&
                    getters.prescriptionSettingAmounts?.length > 1 &&
                    getters.prescriptionSelectedSubtance
                ) {
                    let caluculationResult = calculateGiva(
                        structuredClone(features),
                        getters.prescriptionSelectedSubtance,
                        getters.prescriptionSettingAmounts,
                        getters.prescriptionOriginalTotalGiva,
                        getters.prescriptionSettingTotal,
                        getters.prescriptionSettingMin,
                        getters.prescriptionSettingMax,
                        getters.prescriptionSettingsOverrideMin,
                        getters.prescriptionSettingsOverrideMax,
                        getters.prescriptionSettingOverrideGiva,
                        getters.prescriptionSettingSubtractGiva,
                        getters.prescriptionSettingIncludeZero,
                        getters.prescriptionSettingUseDecimals,
                    );
                    commit("setPrescriptionMin", caluculationResult.minGiva);
                    commit("setPrescriptionMax", caluculationResult.maxGiva);
                    commit("setPrescriptionTotal", caluculationResult.totalGiva);
                    commit("setPrescriptionFeatures", caluculationResult.features);

                    if (setTotalGiva) {
                        commit("setPrescriptionOriginalTotalGiva", caluculationResult.totalGiva);
                    }
                    resolve();
                } else {
                    commit("resetGiva");
                }

                setTimeout(() => {
                    commit("setWorking", false);
                }, 100);

            }, 100);

        });
    },

    SET_PRESCRIPTION_AMOUNT_SETTINGS({ commit, dispatch }, amounts) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionAmountSettings", amounts);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_SUBTANCE({ commit, dispatch, getters }, substance) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSubtance", substance);
            const startAmounts = [
                { value: getters.prescriptionSubstanceMin, amount: 0 },
                { value: getters.prescriptionSubstanceMax, amount: 0 },
            ];

            commit("setPrescriptionAmountSettings", startAmounts);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_PRODUCT({ commit }, product) {
        commit("setPrescriptionProduct", product);
    },

    SET_PRESCRIPTION_CELL_SIZE({ commit }, cellSize) {
        commit("setCellSize", cellSize);
    },

    SET_PRESCRIPTION_SETTINGS_USE_DECIMALS({ commit, dispatch }, useDecimals) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSettingUseDecimals", useDecimals);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_SETTINGS_MIN_GIVA({ commit, dispatch }, min) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSettingMin", min);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_SETTINGS_MAX_GIVA({ commit, dispatch }, max) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSettingMax", max);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_SETTINGS_TOTAL_GIVA({ commit, dispatch }, total) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSettingTotal", total);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_SETTINGS_OVERRIDE({ commit, dispatch }, { min, max, giva }) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSettingOverride", { min, max, giva });
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SET_PRESCRIPTION_SETTINGS_SUBTRACT_GIVA({ commit, dispatch }, giva) {
        return new Promise((resolve, reject) => {
            commit("setPrescriptionSettingSubtractGiva", giva);
            dispatch("CALCULATE_GIVA").then(() => {
                resolve();
            });
        });
    },

    SAVE_PRESCRIPTION({ commit, state, rootGetters }, {comment, filename}) {
        return new Promise((resolve, reject) => {
            
            axios
                .post("/prescription", {
                    customer_id: rootGetters["activeCustomer/activeCustomer"]?.id,
                    title: filename,
                    comment: comment,
                    settings: {
                        settingsCellSize: state.settingsCellSize,
                        prescriptionOriginalGeom: state.prescriptionOriginalGeom,
                        settingsMin: state.settingsMin,
                        settingsMax: state.settingsMax, 
                        settingsOverrideMin: state.settingsOverrideMin, 
                        settingsOverrideMax: state.settingsOverrideMax, 
                        settingOverrideGiva: state.settingOverrideGiva, 
                        settingsSubtractGiva: state.settingsSubtractGiva, 
                        settingsTotal: state.settingsTotal, 
                        settingsSelectedUnit: state.settingsSelectedUnit, 
                        settingsSelectedSubstance: state.settingsSelectedSubstance,
                        settingsSelectedProduct: state.settingsSelectedProduct, 
                        settingsAmounts: state.settingsAmounts, 
                        settingsUseDecimals: state.settingsUseDecimals, 
                        settingsIncludeZero: state.settingsIncludeZero, 
                    },
                    parameters: {
                        type: state.type,
                        prescriptionOriginalTotalGiva: state.prescriptionOriginalTotalGiva,
                        prescriptionSubstanceMin: state.prescriptionSubstanceMin,
                        prescriptionSubstanceMax: state.prescriptionSubstanceMax,
                        prescriptionAvailableProperties: state.prescriptionAvailableProperties,
                        prescriptionMin: state.prescriptionMin,
                        prescriptionMax: state.prescriptionMax,
                        prescriptionTotal: state.prescriptionTotal,
                        product: state.product,
                    },
                    polygons: state.polygons.map(p => {
                        return {
                            id: p.properties.id,
                            geom: JSON.stringify(p.geometry),
                        };
                    }),
                    cells: state.prescriptionsFeatures.map(f => {   
                        return {
                            area: f.properties.area,
                            polygon_id: f.properties.parcel_id,
                            lat: f.properties.center.lat,
                            lng: f.properties.center.lng,
                            geometry: JSON.stringify(f.geometry),
                            properties: JSON.stringify(f.properties),
                        };
                    }),
                    gridinfo: {default: "default"},
                })
                .then((response) => {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    UPDATE_PRESCRIPTION({ commit, state, rootGetters }, {comment, filename, prescriptionId}) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`/prescription/${prescriptionId}`, {
                    customer_id: rootGetters["activeCustomer/activeCustomer"]?.id,
                    title: filename,
                    comment: comment,
                    settings: {
                        settingsCellSize: state.settingsCellSize,
                        prescriptionOriginalGeom: state.prescriptionOriginalGeom,
                        settingsMin: state.settingsMin,
                        settingsMax: state.settingsMax, 
                        settingsOverrideMin: state.settingsOverrideMin, 
                        settingsOverrideMax: state.settingsOverrideMax, 
                        settingOverrideGiva: state.settingOverrideGiva, 
                        settingsSubtractGiva: state.settingsSubtractGiva, 
                        settingsTotal: state.settingsTotal, 
                        settingsSelectedUnit: state.settingsSelectedUnit, 
                        settingsSelectedSubstance: state.settingsSelectedSubstance,
                        settingsSelectedProduct: state.settingsSelectedProduct, 
                        settingsAmounts: state.settingsAmounts, 
                        settingsUseDecimals: state.settingsUseDecimals, 
                        settingsIncludeZero: state.settingsIncludeZero, 
                    },
                    parameters: {
                        type: state.type,
                        prescriptionOriginalTotalGiva: state.prescriptionOriginalTotalGiva,
                        prescriptionSubstanceMin: state.prescriptionSubstanceMin,
                        prescriptionSubstanceMax: state.prescriptionSubstanceMax,
                        prescriptionAvailableProperties: state.prescriptionAvailableProperties,
                        prescriptionMin: state.prescriptionMin,
                        prescriptionMax: state.prescriptionMax,
                        prescriptionTotal: state.prescriptionTotal,
                        product: state.product,
                    },
                    polygons: state.polygons.map(p => {
                        return {
                            id: p.properties.id,
                            geom: JSON.stringify(p.geometry),
                        };
                    }),
                    cells: state.prescriptionsFeatures.map(f => {   
                        return {
                            area: f.properties.area,
                            polygon_id: f.properties.parcel_id,
                            lat: f.properties.center.lat,
                            lng: f.properties.center.lng,
                            geometry: JSON.stringify(f.geometry),
                            properties: JSON.stringify(f.properties),
                        };
                    }),
                    gridinfo: {default: "default"},
                })
                .then((response) => {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    REMOVE_PRESCRIPTION_FILE({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/prescription/" + id)
                .then((response) => {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    LOAD_PRESCRIPTION({ commit, dispatch }, id) {
        commit("setFetching", true);
        return new Promise((resolve, reject) => {
            axios
                .get("/prescription/" + id)
                .then((response) => {
                    commit("loadPrescription", response.data);
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit("setFetching", false);
                });
            });
    },




    RESET_PRESCRIPTION({ commit }, values) {
        commit("resetPrescription", values);
    },
};

const mutations = {
    setPrescriptionAmountSettings(state, amounts) {
        state.settingsAmounts = amounts;
    },

    setPrescriptionSettingMin(state, min) {
        state.settingsMin = min;
    },

    setPrescriptionSettingMax(state, max) {
        state.settingsMax = max;
    },

    setPrescriptionSettingTotal(state, total) {
        state.settingsTotal = total;
    },

    setPrescriptionSettingOverride(state, { min, max, giva }) {
        state.settingsOverrideMin = min;
        state.settingsOverrideMax = max;
        state.settingOverrideGiva = giva;
    },

    setPrescriptionSettingSubtractGiva(state, giva) {
        state.settingsSubtractGiva = giva;
    },

    setPrescriptionOriginalGeom(state, {data, types}) {
        const availableTypes = types.map(type => type.value);
        const avaliabeProperties = [];
        data.features.forEach(f => {
            for (const [key, value] of Object.entries(f.properties)) {
                if (value) {
                    if (!avaliabeProperties.includes(key) && availableTypes.includes(key)) {
                        avaliabeProperties.push(key);
                    }
                }
            }
        });

        state.prescriptionAvailableProperties = avaliabeProperties;
        state.prescriptionOriginalGeom = data;
    },

    setPrescriptionFeatures(state, features) {
        state.prescriptionsFeatures = features;
    },

    setPolygons(state, polygons) {
        state.polygons = polygons;
    },

    setPrescriptionMin(state, min) {
        state.prescriptionMin = min;
    },

    setPrescriptionMax(state, max) {
        state.prescriptionMax = max;
    },

    setPrescriptionTotal(state, total) {
        state.prescriptionTotal = total;
    },

    setPrescriptionOriginalTotalGiva(state, total) {
        state.prescriptionOriginalTotalGiva = total;
    },

    setPrescriptionSettingUseDecimals(state, useDecimals) {
        state.settingsUseDecimals = useDecimals;
    },

    setWorking(state, working) {
        state.working = working;
    },

    setFetching(state, fetching) {
        state.fetching = fetching;
    },

    setCellSize(state, cellSize) {
        state.settingsCellSize = cellSize;
    },

    setPrescriptionSubtance(state, substance) {
        if (substance) {
            let minSubstanceValue = Number.MAX_SAFE_INTEGER;
            let maxSubstanceValue = 0;
            let geom = state.prescriptionOriginalGeom;
            geom.features.forEach(f => {
                const value = f.properties[substance];

                if (value < minSubstanceValue) {
                    minSubstanceValue = value;
                } else if (value > maxSubstanceValue) {
                    maxSubstanceValue = value;
                }
            });

            state.prescriptionSubstanceMin = minSubstanceValue;
            state.prescriptionSubstanceMax = maxSubstanceValue;
            state.prescriptionOriginalGeom = geom;
        }
        state.settingsSelectedSubstance = substance;
    },

    resetGiva(state) {
        state.prescriptionMin = 0;
        state.prescriptionMax = 0;
        state.prescriptionTotal = 0;
        state.prescriptionOriginalTotalGiva = 0;
        state.prescriptionsFeatures = [];
    },

    setPrescriptionProduct(state, product) {
        state.product = product;
    },

    loadPrescription(state, prescription) {
        let parameters = prescription?.prescription?.parameters;
        let settings = prescription?.prescription?.settings;
        if(settings) {
            for (const [key, value] of Object.entries(settings)) {
                state[key] = value;

            }
        }
        if(parameters) {
            for (const [key, value] of Object.entries(parameters)) {
                state[key] = value;
            }
        }
        if(prescription?.features?.length) {
            if(prescription.features[0].properties?.cells?.length) {
                state.prescriptionsFeatures = prescription.features[0].properties.cells;
            }
        }

        state.prescriptionTitle = prescription?.prescription?.title;

    },

    resetPrescription(state, values) {
        state.prescriptionsFeatures = [],
        state.settingsCellSize = 20,
        state.prescriptionOriginalGeom = null,
        state.settingsMin = null,
        state.settingsMax = null,
        state.settingsOverrideMin = null,
        state.settingsOverrideMax = null,
        state.settingOverrideGiva = 0,
        state.settingsSubtractGiva = 0,
        state.settingsTotal = null,
        state.settingsSelectedUnit = 'kg/ha',
        state.settingsSelectedSubstance = null,
        state.settingsSelectedProduct = null,
        state.settingsAmounts = [],
        state.settingsUseDecimals = false,
        state.settingsIncludeZero = false,
        state.prescriptionOriginalTotalGiva = 0,
        state.prescriptionSubstanceMin = 0,
        state.prescriptionSubstanceMax = 0,
        state.prescriptionAvailableProperties = [],
        state.prescriptionMin = 0,
        state.prescriptionMax = 0,
        state.prescriptionTotal = 0,
        state.prescriptionTitle = null,

        // set the values if they are provided
        state.product = values?.product ? values.product : null;
        state.prescriptionPlanedGiva = values?.planedGiva ? values.planedGiva : 0;
        state.polygons = values?.polygons ? values.polygons : [];
    },
};

function calculateGiva(
    features,
    substance,
    amounts,
    totalOrginalGiva,
    totalGivaLimit,
    minGivaLimit,
    maxGivaLimit,
    overrideMin,
    overrideMax,
    overrideGiva,
    subtractingGiva,
    includeZero,
    useDecimals
) {
    let totalGiva = 0;
    let maxGiva = 0;
    let minGiva = Number.MAX_SAFE_INTEGER;
    let calculatedFeatures = features.map(feature => {
        let newFeature = {
            ...feature,
        }
        newFeature.properties.giva = calculateGivaPerFeature(
            feature,
            substance,
            amounts,
            totalOrginalGiva,
            totalGivaLimit,
            minGivaLimit,
            maxGivaLimit,
            overrideMin,
            overrideMax,
            overrideGiva,
            subtractingGiva,
            includeZero,
            useDecimals
        );
        if (newFeature.properties.giva > maxGiva) {
            maxGiva = newFeature.properties.giva;
        } else if (newFeature.properties.giva < minGiva) {
            minGiva = newFeature.properties.giva;
        }
        const areaInHa = newFeature.properties.area / 10000;
        totalGiva += newFeature.properties.giva * areaInHa;
        return newFeature;
    });

    return {
        features: calculatedFeatures,
        totalGiva: totalGiva,
        minGiva: minGiva,
        maxGiva: maxGiva,
    };
}
/*
* Calculates the giva of a provided feature
*/
function calculateGivaPerFeature(
    feature,
    substance,
    amounts,
    totalOrginalGiva,
    totalGivaLimit,
    minGivaLimit,
    maxGivaLimit,
    overrideMin,
    overrideMax,
    overrideGiva,
    subtractingGiva,
    includeZero,
    useDecimals
) {
    let giva = 0;

    // if manualGiva is not set, calculate the giva
    // else use the manualGiva
    if (isNaN(feature.properties.manualGiva)) {
        for (let i = 0; i < amounts.length; i++) {

            // to fixed(2) to be able to compare a more precise feature.properties[substance] with amounts[i].value
            if (feature.properties[substance] && feature.properties[substance].toFixed(2) === amounts[i].value.toFixed(2)) {
                giva = amounts[i].amount;
                break;
            } else if (
                feature.properties[substance] < amounts[i].value &&
                i !== 0
            ) {
                // We are in the desired range
                giva = interpolateValue(
                    feature.properties[substance],
                    amounts[i - 1].value, // x1
                    amounts[i].value, // x2
                    amounts[i - 1].amount, // y1
                    amounts[i].amount, // y2
                    useDecimals
                );
                break;
            }
        }


        // Adjust the giva so that it is according to the users wishes
        if (totalGivaLimit) {
            giva *= totalGivaPercentage(totalGivaLimit, totalOrginalGiva);
        } else if (feature.modified && typeof feature.properties.modifiedGiva === 'number') {
            giva = feature.properties.modifiedGiva;
        }

        // if override max min and giva is set, and valid numbers, use them
        if (!isNaN(overrideMin) && !isNaN(overrideMax) && !isNaN(overrideGiva)) {
            if (overrideMin <= giva && giva <= overrideMax) {
                giva = overrideGiva;
            }
        }

        // if subtractingGiva is set, subtract it from the giva
        // this is equivalent to removing a prior prescription from the feature
        if (subtractingGiva > 0) {
            if (giva > subtractingGiva) {
                giva -= subtractingGiva;
            } else {
                giva = 0;
            }
        }


        // Adjust the giva so it is within the provided highest/lowest bounds
        if (
            minGivaLimit && (includeZero || giva > 0) && giva < minGivaLimit
        ) {
            giva = minGivaLimit;

        } else if (maxGivaLimit && maxGivaLimit < giva
        ) {
            giva = maxGivaLimit;
        }

    } else {
        giva = feature.properties.manualGiva;
    }

    if (useDecimals) {
        giva = parseFloat(+giva.toFixed(2));
    } else {
        giva = Math.round(giva);
    }

    return giva;
}

function totalGivaPercentage(totalAmount, originalTotalGiva) {
    if (totalAmount != null && originalTotalGiva != null && originalTotalGiva > 0) {
        return totalAmount / originalTotalGiva;
    }
    return 1;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
