const namespaced = true;

const state = {
    amounts: [],
    areas: {},
    min: null,
    max: null,
    typeText: "",
    maxAmount: 400,
    useDecimals: false,
};

const initialState = JSON.parse(JSON.stringify(state));

const getters = {
    exportState: (state) => getExportState(state),
    amounts: (state) => state.amounts,
    nbrDecimals: (state) => state.nbrDecimals,
    maxAmount: (state) => state.maxAmount,
    typeText: (state) => state.typeText,
    useDecimals: (state) => state.useDecimals,
    bounds: (state) => {
        return {
            min: state.min,
            max: state.max,
        };
    },
    labels: (state) => {
        let labels = [];
        if (state.min !== undefined && state.min !== null && state.max) {
            if (state.max > 1) {
                let labelValue = Math.floor(state.min / 5) * 5;
                while (labelValue <= Math.ceil(state.max / 5) * 5) {
                    labels.push(labelValue.toString());
                    labelValue += 5;
                }
            } else {
                let diff = (state.max - state.min) / 4;
                for (let i = 0; i <= 4; i++) {
                    labels.push(state.min + diff * i);
                }
            }
        }
        return labels;
    },

    amountsAndValues: (state, getters) => {
        return getters.labels.map((label, index) => {
            return {
                value: parseFloat(label),
                amount: state.amounts[index],
            };
        });
    },

    chartAmounts: (state, getters) => {
        return getters.amountsAndValues.map((obj) => {
            return {
                value: obj.value,
                amount: obj.amount,
                area: state.areas[obj.value],
            };
        });
    },
};

const actions = {
    SET_MIN_MAX({ commit }, minMax) {
        commit("setMinMax", minMax);
    },

    SET_AMOUNTS({ commit }, amounts) {
        commit("setAmounts", amounts);
    },

    SET_AREAS({ commit }, areas) {
        commit("setAreas", areas);
    },

    SET_TYPE_TEXT({ commit }, type) {
        commit("setTypeText", type);
    },

    SET_MAX_AMOUNT({ commit, getters }, newMaxAmount) {
        let currChartMax = _.maxBy(getters.chartAmounts, "amount");
        if (newMaxAmount < currChartMax.amount) {
            bus.$emit("display-notification", {
                title: "Det inmatade värdet är lägre än det högsta visade värdet i diagrammet.",
                type: "warning",
            });
        } else {
            commit("setMaxAmount", newMaxAmount);
        }
    },

    SET_USE_DECIMALS({ commit }, useDecimals) {
        commit("setUseDecimals", useDecimals);
    },

    CLEAR_STATE({ commit }) {
        commit("clearState");
    },

    SET_STATE({ commit }, newState) {
        commit("setState", newState);
    },
};

const mutations = {
    setMinMax(state, minMax) {
        state.min = minMax.min;
        state.max = minMax.max;
    },

    setAmounts(state, amounts) {
        state.amounts = amounts;
    },

    setAreas(state, areas) {
        state.areas = areas;
    },

    setTypeText(state, type) {
        state.typeText = type;
    },

    setMaxAmount(state, maxAmount) {
        state.maxAmount = maxAmount;
    },

    setUseDecimals(state, useDecimals) {
        state.useDecimals = useDecimals;
    },

    clearState(state) {
        Object.keys(state).forEach((key) => {
            state[key] = initialState[key];
        });
    },

    setState(state, newState) {
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

function getExportState(state) {
    return state;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
