import { intersection, isInteger } from "lodash";

const namespaced = true;

const state = {
    satellite: [
        { from: Number.MIN_VALUE, to: -0.2, color: "#000000" },
        { from: -0.2, to: 0, color: "#a50026" },
        { from: 0, to: 0.1, color: "#d73027" },
        { from: 0.1, to: 0.2, color: "#f46d43" },
        { from: 0.2, to: 0.3, color: "#fdae61" },
        { from: 0.3, to: 0.4, color: "#fee08b" },
        { from: 0.4, to: 0.5, color: "#ffffbf" },
        { from: 0.5, to: 0.6, color: "#d9ef8b" },
        { from: 0.6, to: 0.7, color: "#a6d96a" },
        { from: 0.7, to: 0.8, color: "#66bd63" },
        { from: 0.8, to: 0.9, color: "#1a9850" },
        { from: 0.9, to: Number.MAX_VALUE, color: "#006837" },
    ],

    precision: [
        "#ffffff",
        "#ece7f2",
        "#d0d1e6",
        "#a6bddb",
        "#74a9cf",
        "#3690c0",
        "#0570b0",
        "#045a8d",
        "#023858",
    ],
};

const getters = {
    satelliteColors: (state) => state.satellite,
    precisionColors: (state) => state.precision,
};

const actions = {};

const mutations = {};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
