import { calculateMgBehovSingleSample } from "@/helpers/evaluation.js";
import uuid from "uuid";

const namespaced = true;

const state = {
    polygons: { type: "FeatureCollection", features: [] },
};

const getters = {
    exportState: (state) => getExportState(state),
    polygons: (state) => state.polygons,
    hasRasters: (state) =>
        !!state.polygons.features.length &&
        state.polygons.features.every(
            (f) =>
                f.properties.raster_status === "Terrängklassifixering avslutad"
        ),
};

const actions = {
    SET_POLYGONS({ commit }, polygons) {
        commit("setPolygons", JSON.parse(JSON.stringify(polygons)));
    },

    CLEAR_POLYGONS({ commit }) {
        commit("clearPolygons");
    },

    SET_PROPERTY({ commit }, { polygon, property, value }) {
        commit("setProperty", { polygon, property, value });
    },

    GET_RASTERS({ state, commit, dispatch }) {
        return new Promise((resolve, reject) => {
            let requests = [];
            state.polygons.features.forEach((feature) => {
                //Only create rasters when needed
                if (!feature.properties.raster_id) {
                    requests.push(dispatch("CREATE_RASTER", feature));
                }
            });
            Promise.all(requests)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    UPDATE_STATUS({ state, commit, dispatch }) {
        return new Promise((resolve, reject) => {
            let requests = [];
            state.polygons.features.forEach((feature) => {
                if (feature.properties.raster_id) {
                    requests.push(dispatch("GET_RASTER", feature));
                }
            });
            Promise.all(requests)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    CREATE_RASTER({ state, commit, dispatch }, polygon) {
        return new Promise((resolve, reject) => {
            axios
                .post("raster/create", {
                    polygon: polygon.geometry,
                    parcel_id: polygon.properties.parcel_id,
                    layers: [
                        {
                            id: "topology",
                        },
                    ],
                })
                .then((response) => {
                    commit("setRaster", {
                        polygon: polygon,
                        raster: response.data,
                    });
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    GET_RASTER({ state, commit, dispatch }, polygon) {
        return new Promise((resolve, reject) => {
            axios
                .get("raster/" + polygon.properties.raster_id)
                .then((response) => {
                    commit("setRaster", {
                        polygon: polygon,
                        raster: response.data,
                    });
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    GET_SAMPLES({ state, commit, dispatch }, customer_id) {
        return new Promise((resolve, reject) => {
            let requests = [];
            state.polygons.features.forEach((feature) => {
                requests.push(
                    dispatch("GET_SAMPLE", {
                        feature: feature,
                        customer_id: customer_id,
                    })
                );
            });
            Promise.all(requests)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    GET_SAMPLE({ state, commit, dispatch }, { customer_id, feature }) {
        return new Promise((resolve, reject) => {
            axios
                .post(`sample/clay/${customer_id}/geometry`, {
                    geom: JSON.stringify(feature.geometry),
                })
                .then((response) => {
                    commit("setSamples", {
                        samples: response.data,
                        polygonId: feature.properties.id,
                    });
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    },
};

const mutations = {
    setPolygons(state, polygons) {
        polygons.features.forEach((polygon) => {
            polygon.properties.id = polygon.properties.id
                ? polygon.properties.id
                : uuid.v4();
        });
        state.polygons = polygons;
    },

    setSamples(state, { samples, polygonId }) {
        let index = state.polygons.features.findIndex(
            (f) => f.properties.id === polygonId
        );
        if (index >= 0) {
            let updated = state.polygons.features[index];
            updated.properties.samples = samples;
            updated.properties.samples.forEach((s) => {
                s.magnesiumbehov = calculateMagnesiumbehov(s);
            });
            state.polygons.features.splice(index, 1, updated);
            state.polygons.features.splice(index, 1, updated);
        }
    },

    clearPolygons(state) {
        state.polygons = { type: "FeatureCollection", features: [] };
    },

    setRaster(state, { polygon, raster }) {
        let index = state.polygons.features.findIndex(
            (f) => f.properties.id === polygon.properties.id
        );
        if (index >= 0) {
            let updated = state.polygons.features[index];
            updated.properties.raster_id = raster.id;
            updated.properties.raster_status = raster.status;
            state.polygons.features.splice(index, 1, updated);
        }
    },

    setProperty(state, { polygon, property, value }) {
        let index = state.polygons.features.findIndex(
            (f) => f.properties.id === polygon.properties.id
        );
        if (index >= 0) {
            let updated = state.polygons.features[index];
            updated.properties[property] = value;
            state.polygons.features.splice(index, 1, updated);
        }
    },
};

function calculateMagnesiumbehov(sample) {
    let unModifedSample = JSON.parse(JSON.stringify(sample));
    unModifedSample.properties = unModifedSample;
    let mg = calculateMgBehovSingleSample(unModifedSample);
    return mg;
}

function getExportState(state) {
    return state;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
