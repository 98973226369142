/**
 * 	Calculate the income reduction for each sample.
 * 	Each sample must have the following values:
 * 	pH, P_Al, K_Al, Mg_Al, Mullhalt, Lerhalt
 *
 * 	@param {array} samples An array of samples
 */
export function calculateEvaluation(samples) {
    // Split valid and non-valid samples into two arrays
    let results = _.partition(samples, (sample) => {
        return (
            !sample.ph ||
            !sample.p_al ||
            !sample.k_al ||
            !sample.mg_al ||
            !sample.lerhalt ||
            !sample.mullhalt
        );
    });

    let calculatedSamples = getCalculatedSamples(results[1]);

    return {
        errorSamples: results[0],
        samples: calculatedSamples,
    };
}

function getCalculatedSamples(samples) {
    return samples.map((sample) => {
        let ph = calculate_ph_avkastningsminskning(sample);

        let p_al = calculate_p_al_avkastningsminskning(sample);

        let k_al = calculate_k_al_avkastningsminskning(sample);

        let mg_al = calculate_mg_al_avkastningsminskning(sample);

        let sum = Math.pow(
            Math.pow(ph, EXP) +
                Math.pow(p_al, EXP) +
                Math.pow(k_al, EXP) +
                Math.pow(mg_al, EXP),
            1 / EXP
        );

        return {
            id: sample.id,
            ph_reduction: ph,
            p_al_reduction: p_al,
            k_al_reduction: k_al,
            mg_al_reduction: mg_al,
            total_reduction: sum,
        };
    });
}

export function calculateMgBehovSingleSample(sample) {
    let lerhalt = sample.properties.lerhalt
        ? sample.properties.lerhalt
        : sample.properties.idw_lerhalt;
    let mullhalt = sample.properties.mullhalt
        ? sample.properties.mullhalt
        : sample.properties.idw_mullhalt;

    let mg_al = Math.round(
        calculate_volym_justering(mullhalt) * sample.properties.mg_al
    );

    // AQ Min Mg
    // =IF(R10 < 15, 5 + 0.2 * R10, 8)
    // AR Max Mg
    // =IF(R10 < 15 , 8 + 0.1333 * R10, 10)
    let min_mg = 8;
    let max_mg = 10;
    if (lerhalt < 15) {
        min_mg = 5 + 0.2 * lerhalt;
        max_mg = 8 + 0.1333 * lerhalt;
    }

    // AS Kvot Mg
    // =M10 / 2 + 1
    let kvot_mg = sample.properties.k_al / 2 + 1;

    // AT Kvot jfr Mg-Al
    // =IF(AS10 > AQ10, AS10, AQ10)
    let kvot_jfr_mg_al = kvot_mg > min_mg ? kvot_mg : min_mg;

    // AU Önskvärt Mg-Al
    // =IF(AT10 < AR10, AT10, AR10)
    let onskvart_mg_al = Math.round(
        kvot_jfr_mg_al < max_mg ? kvot_jfr_mg_al : max_mg
    );

    return onskvart_mg_al - mg_al > 0 ? onskvart_mg_al - mg_al : 0;
}

const HOSTVETE = true;

// Avkastningspotential Höstvete utifrån vegitationsperiodens längd, globalinstrålning mm
const AVKASTN_POT_HVETE = 13500;
// Fyll i relativtal för aktuell höstvetesort utifrån tabell till höger
const REL_TAL_HVETE = 94;

// Avkastningspotential Vårkorn utifrån vegitationsperiodens längd, globalinstrålning mm
const AVKASTN_POT_VKORN = 13500;
// Fyll i relativtal för aktuell vårkornssort utifrån tabell till höger
const REL_TAL_VKORN = 100;

const AVKASTN_POT_AKT_GRODA = HOSTVETE
    ? (AVKASTN_POT_HVETE * REL_TAL_HVETE) / 100
    : (AVKASTN_POT_VKORN * REL_TAL_VKORN) / 100;

const TILLFORT_P = 20;
const TILLFORT_K = 40;
const TILLFORT_MG = 0;

const EXP = 1.2;

const P_AVK_MINSK_GRUND_LOW_TABLE = [
    {
        value: 1,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 2000,
    },
    {
        value: 2,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 1000,
    },
    {
        value: 3,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 500,
    },
    {
        value: 4,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 250,
    },
    {
        value: 5,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 125,
    },
    { value: 6, amount: 0 },
    { value: 7, amount: 0 },
    { value: 8, amount: 0 },
    { value: 9, amount: 0 },
    { value: 10, amount: 0 },
    { value: 11, amount: 0 },
    { value: 12, amount: 0 },
    { value: 13, amount: 0 },
    { value: 14, amount: 0 },
    { value: 15, amount: 0 },
    { value: 16, amount: 0 },
    // Higher values return 0
];

const K_AVK_MINSK_GRUND_LOW_TABLE = [
    { value: 1, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 400 },
    { value: 2, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 300 },
    { value: 3, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 200 },
    { value: 4, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 100 },
    { value: 5, amount: 0 },
    { value: 6, amount: 0 },
    { value: 7, amount: 0 },
    { value: 8, amount: 0 },
    { value: 9, amount: 0 },
    { value: 10, amount: 0 },
    { value: 11, amount: 0 },
    { value: 12, amount: 0 },
    { value: 13, amount: 0 },
    { value: 14, amount: 0 },
    { value: 15, amount: 0 },
    { value: 16, amount: 0 },
    // Higher values return 0
];

const K_KVOT_AVK_MINSK_GRUND_LOW_TABLE = [
    { value: 0.1, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 200 },
    { value: 0.2, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 175 },
    { value: 0.3, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 150 },
    { value: 0.4, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 125 },
    { value: 0.5, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 100 },
    { value: 0.6, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 75 },
    { value: 0.7, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 50 },
    { value: 0.8, amount: (AVKASTN_POT_AKT_GRODA / 8000) * 25 },
    { value: 0.9, amount: 0 },
    // Higher values return 0
];

const MG_AVK_MINSK_GRUND_LOW_TABLE = [
    { value: 1, amount: 0 },
    { value: 2, amount: 0 },
    { value: 3, amount: 0 },
    { value: 4, amount: 0 },
    { value: 5, amount: 0 },
    { value: 6, amount: 0 },
    { value: 7, amount: 0 },
    { value: 8, amount: 0 },
    { value: 9, amount: 0 },
    { value: 10, amount: 0 },
    // Higher values return 0
];

const P_BEHOV_TABLE = [
    {
        value: 1,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 35,
    },
    {
        value: 2,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 30,
    },
    {
        value: 3,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 25,
    },
    {
        value: 4,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 20,
    },
    {
        value: 5,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 6,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 10,
    },
    {
        value: 7,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 5,
    },
    { value: 8, amount: 0 },
    { value: 9, amount: 0 },
    { value: 10, amount: 0 },
    { value: 11, amount: 0 },
    { value: 12, amount: 0 },
    { value: 13, amount: 0 },
    { value: 14, amount: 0 },
    { value: 15, amount: 0 },
    { value: 16, amount: 0 },
    // Higher values return 0
];

const MG_BEHOV_TABLE = [
    {
        value: 1,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 5,
    },
    {
        value: 2,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 10,
    },
    {
        value: 3,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 4,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 5,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 6,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 7,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 8,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 9,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    {
        value: 10,
        amount: (AVKASTN_POT_AKT_GRODA / (HOSTVETE ? 10000 : 8000)) * 15,
    },
    // Higher values return 0
];

const K_BEHOV_TABLE = [
    {
        value: 1,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 50 : 55)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 2,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 45 : 50)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 3,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 40 : 45)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 4,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 35 : 40)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 5,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 30 : 35)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 6,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 25 : 30)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 7,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 20 : 25)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 8,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 15 : 20)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 9,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 10 : 15)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 10,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 5 : 10)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 11,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 0 : 5)) /
            (HOSTVETE ? 10000 : 8000),
    },
    { value: 12, amount: 0 },
    { value: 13, amount: 0 },
    { value: 14, amount: 0 },
    { value: 15, amount: 0 },
    { value: 16, amount: 0 },
    // Higher values return 0
];

const K_KVOT_BEHOV_TABLE = [
    {
        value: 0.1,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 40 : 45)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.2,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 35 : 40)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.3,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 30 : 35)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.4,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 25 : 30)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.5,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 20 : 25)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.6,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 15 : 20)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.7,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 10 : 15)) /
            (HOSTVETE ? 10000 : 8000),
    },
    {
        value: 0.8,
        amount:
            (AVKASTN_POT_AKT_GRODA * (HOSTVETE ? 5 : 10)) /
            (HOSTVETE ? 10000 : 8000),
    },
    { value: 0.9, amount: HOSTVETE ? 0 : AVKASTN_POT_AKT_GRODA * (5 / 8000) },
    // Higher values return 0
];

const P_AVKASTN_MINSKN_CAN_BE_FIXED = [
    {
        value: 1,
        amount: ((HOSTVETE ? 30.0 : 30.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 2,
        amount: ((HOSTVETE ? 18.0 : 18.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 3,
        amount: ((HOSTVETE ? 7.0 : 7.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 4,
        amount: ((HOSTVETE ? 6.2 : 6.2) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 5,
        amount: ((HOSTVETE ? 5.5 : 5.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 6,
        amount: ((HOSTVETE ? 4.5 : 4.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 7,
        amount: ((HOSTVETE ? 4.1 : 4.1) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 8,
        amount: ((HOSTVETE ? 3.8 : 3.8) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 9,
        amount: ((HOSTVETE ? 3.4 : 3.4) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 10,
        amount: ((HOSTVETE ? 3.1 : 3.1) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 11,
        amount: ((HOSTVETE ? 2.7 : 2.7) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 12,
        amount: ((HOSTVETE ? 2.4 : 2.4) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 13,
        amount: ((HOSTVETE ? 2.1 : 2.1) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 14,
        amount: ((HOSTVETE ? 1.8 : 1.8) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 15,
        amount: ((HOSTVETE ? 1.5 : 1.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 16,
        amount: ((HOSTVETE ? 1.2 : 1.2) * AVKASTN_POT_AKT_GRODA) / 100,
    },
];

const K_AVKASTN_MINSKN_CAN_BE_FIXED = [
    {
        value: 1,
        amount: ((HOSTVETE ? 9.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 2,
        amount: ((HOSTVETE ? 8.0 : 9.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 3,
        amount: ((HOSTVETE ? 7.0 : 8.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 4,
        amount: ((HOSTVETE ? 6.0 : 7.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 5,
        amount: ((HOSTVETE ? 5.0 : 6.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 6,
        amount: ((HOSTVETE ? 4.0 : 5.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 7,
        amount: ((HOSTVETE ? 3.0 : 4.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 8,
        amount: ((HOSTVETE ? 2.0 : 3.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 9,
        amount: ((HOSTVETE ? 1.0 : 2.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 10,
        amount: ((HOSTVETE ? 0.5 : 1.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 11,
        amount: ((HOSTVETE ? 0.0 : 0.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 12,
        amount: ((HOSTVETE ? 0.0 : 0.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 13,
        amount: ((HOSTVETE ? 0.0 : 0.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 14,
        amount: ((HOSTVETE ? 0.0 : 0.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 15,
        amount: ((HOSTVETE ? 0.0 : 0.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 16,
        amount: ((HOSTVETE ? 0.0 : 0.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
];

const K_KVOT_AVKASTN_MINSKN_CAN_BE_FIXED = [
    {
        value: 0.1,
        amount: ((HOSTVETE ? 7.0 : 8.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.2,
        amount: ((HOSTVETE ? 6.0 : 7.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.3,
        amount: ((HOSTVETE ? 5.0 : 6.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.4,
        amount: ((HOSTVETE ? 4.0 : 5.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.5,
        amount: ((HOSTVETE ? 3.0 : 4.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.6,
        amount: ((HOSTVETE ? 2.0 : 3.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.7,
        amount: ((HOSTVETE ? 1.0 : 2.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.8,
        amount: ((HOSTVETE ? 0.5 : 1.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 0.9,
        amount: ((HOSTVETE ? 0.0 : 0.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
];

const MG_AVKASTN_MINSKN_CAN_BE_FIXED = [
    {
        value: 1,
        amount: ((HOSTVETE ? 1.0 : 1.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 2,
        amount: ((HOSTVETE ? 2.0 : 2.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 3,
        amount: ((HOSTVETE ? 4.0 : 5.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 4,
        amount: ((HOSTVETE ? 6.0 : 7.5) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 5,
        amount: ((HOSTVETE ? 8.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 6,
        amount: ((HOSTVETE ? 8.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 7,
        amount: ((HOSTVETE ? 8.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 8,
        amount: ((HOSTVETE ? 8.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 9,
        amount: ((HOSTVETE ? 8.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
    {
        value: 10,
        amount: ((HOSTVETE ? 8.0 : 10.0) * AVKASTN_POT_AKT_GRODA) / 100,
    },
];

/**
 *
 * 	pH calculation  ------------------------------------------------------------------------
 *
 */

function calculate_ph_avkastningsminskning(sample) {
    let target_pH = calculate_target_ph(sample);

    // Avkastningsminskning vid differens mellan pH
    // och optimalt pH, kg per 0,1 %-enhets differens per ha
    // och år vid aktuell avkastningspotential och gröda
    // =IF(B5="",120*D23/8000,140*D23/8000)
    let avkastn_minsk_diff_ph_var = avkastn_minsk_diff_ph();

    // % av kalkbehovet
    // åtgärdat efter kartering
    // User input
    let percentage_cao_behov_after_kartering = 0;

    // =IF(J5 > I5, (100 - K5) / 100 * SUM(J5 - I5) * $Grundförutsättningar.D$71 * 10,0)
    if (target_pH > sample.ph) {
        return (
            ((100 - percentage_cao_behov_after_kartering) / 100) *
            (target_pH - sample.ph) *
            avkastn_minsk_diff_ph_var *
            10
        );
    } else {
        return 0;
    }
}

/**
 * 	This method is tested and checked, and should be fine.
 */
function calculate_target_ph(sample) {
    // Since we will always have HOSTVETE this will always be Optimalt pH ($Grund.AP10)
    // =IF($Grundförutsättningar.B$5="",IF(D5="",0,$Grund.AP10),$Grund.AP10)

    // Grund.AP10 =IF(AN10>1,AN10,AO10)
    // AN10 will always be empty (user input), so we jump to AO10
    if (sample.mullhalt < 30) {
        // return AM10
        return calculate_lowest_optimal_ph(sample);
    } else {
        return 5.6;
    }
}

/**
 * 	This method is tested and checked, and should be fine.
 */
function calculate_lowest_optimal_ph(sample) {
    if (sample.mullhalt < 30) {
        let k_hcl_opt_ph = calculate_k_hcl_opt_ph(sample);
        let mullhalt_opt_ph = calculate_mullhalt_opt_ph(sample);

        // Lägsta (Optimalt pH)
        // =IF(AK10 < AL10, AK10, AL10)
        // K-HCl (Optimalt pH)  <  Mullhalt (Optimalt pH) : K-HCl (Optimalt pH) ? Mullh(Optimalt pH)
        if (k_hcl_opt_ph < mullhalt_opt_ph) {
            // AK10
            return k_hcl_opt_ph;
        } else {
            // AL10
            return mullhalt_opt_ph;
        }
    } else {
        return 5.6;
    }
}

/**
 * 	This method is tested and checked, and should be fine.
 */
function calculate_k_hcl_opt_ph(sample) {
    // =IF(R10 < 15, R10*0.033+6-$AK$4*AJ10, 6.5-$AK$4*AJ10)
    if (sample.lerhalt < 15) {
        //					Remove last part, potatis stuff
        // R10 * 0.033 + 6 - $AK$4 * AJ10
        return sample.lerhalt * 0.033 + 6;
    } else {
        //		 Remove last part, potatis stuff
        // 6.5 - $AK$4 * AJ10
        return 6.5;
    }
}

/**
 * 	This method is tested and checked, and should be fine.
 */
function calculate_mullhalt_opt_ph(sample) {
    // =IF(Y10 < 8, AK10, 6.4-(Y10-8)*0.8/22-AJ10*$AK$4/2)
    if (sample.mullhalt < 8) {
        // K-HCl (Optimalt pH)
        // return K-HCl (Optimalt pH)
        return calculate_k_hcl_opt_ph(sample); // WTF M8 ???! Smile and wave
    } else {
        //						    Don't care about potatis, remove this part, will always be zero
        // 6.4-(Y10-8)*0.8/22-AJ10  *$AK$4/2
        return 6.4 - ((sample.mullhalt - 8) * 0.8) / 22;
    }
}

function avkastn_minsk_diff_ph() {
    if (HOSTVETE) {
        // 120*D23/8000
        return (120 * AVKASTN_POT_AKT_GRODA) / 8000;
    } else {
        // 140*D23/8000
        return (140 * AVKASTN_POT_AKT_GRODA) / 8000;
    }
}

/**
 *
 * 	P-Al calculation  ------------------------------------------------------------------------
 *
 */

/**
 * 	Seems to work for P-Al, K-Al, Mg-Al.
 *
 * @param {Object} sample
 */
function calculate_volym_justering(mullhalt) {
    // =IF(Y24 > 60, 0.45, IF(Y24 < 3, 1, (1 - 0.009649 * (Y24 - 3))))
    if (mullhalt > 60) {
        return 0.45;
    } else if (mullhalt < 3) {
        return 1.0;
    } else {
        return 1 - 0.009649 * (mullhalt - 3);
    }
}

/**
 *  This method is tested and checked, and should be fine.
 */
function calculate_p_behov(p_al) {
    // =IF(M5>0,LOOKUP(M5,$Grundförutsättningar.A$81:A$96,$Grundförutsättningar.D$81:$D96),0)
    let data = P_BEHOV_TABLE.filter((row) => {
        return Math.round(p_al) == row.value;
    });

    // If the p_al is greater than 16 we need to return 0, we have no data points above 16.
    if (data[0]) {
        return data[0].amount;
    } else {
        return 0;
    }
}

/**
 *  This method is tested and checked, and should be fine.
 */
function calculate_p_avk_minsk_grund(p_al) {
    // Avkastningspotential med aktuell gröda och sort (kg/ha):
    // =IF(B5="", D20 * D15 / 100, D17 * D14 / 100)
    let data = P_AVK_MINSK_GRUND_LOW_TABLE.filter((row) => {
        return Math.round(p_al) == row.value;
    });

    // If the p_al is greater than 16 we need to return 0, we have no data points above 16.
    if (data[0]) {
        return data[0].amount;
    } else {
        return 0;
    }
}

/**
 *  This method is tested and checked, and should be fine.
 */
function calculate_p_avk_minsk_paverkbar(p_al) {
    // =IF(W5 < X5, LOOKUP(M5,$Grundförutsättningar.A$81:A$96,$Grundförutsättningar.C$81:C$96) * SUM((X5 - W5) / X5), 0)
    let p_behov = calculate_p_behov(p_al);

    if (TILLFORT_P < p_behov) {
        let data = P_AVKASTN_MINSKN_CAN_BE_FIXED.filter((row) => {
            return Math.round(p_al) == row.value;
        });

        if (data[0]) {
            return data[0].amount * ((p_behov - TILLFORT_P) / p_behov);
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

/**
 *  This method is tested and checked, and should be fine.
 */
function calculate_p_al_avkastningsminskning(sample) {
    let p_al = calculate_volym_justering(sample.mullhalt) * sample.p_al;

    // Skördesäkning BU5
    // =IF(Y5 + Z5 > 0, Y5 + Z5, 0)
    // Avk minskn grund + Avk minskn påverkbar > 0 ? Avk minskn grund + Avk minskn påverkbar : 0;
    let avk_minsk_grund = calculate_p_avk_minsk_grund(p_al);

    let avk_minsk_paverkbar = calculate_p_avk_minsk_paverkbar(p_al);

    if (avk_minsk_grund + avk_minsk_paverkbar > 0) {
        return avk_minsk_grund + avk_minsk_paverkbar;
    } else {
        return 0;
    }
}

/**
 *
 * 	K-Al Calculations  ------------------------------------------------------------------------
 *
 */
function calculate_k_avk_minsk_grund(k_al) {
    // Avkastningspotential med aktuell gröda och sort (kg/ha):
    // =IF(B5="", D20 * D15 / 100, D17 * D14 / 100)
    let data = K_AVK_MINSK_GRUND_LOW_TABLE.filter((row) => {
        return Math.round(k_al) == row.value;
    });

    // If the k_al is greater than 16 we need to return 0, we have no data points above 16.
    if (data[0]) {
        return data[0].amount;
    } else {
        return 0;
    }
}

// This function is really similar to the version for p_al, can probably be merged
// It has been tested with some random values, seem to output correct value.
function calculate_k_avk_minsk_paverkbar(k_al) {
    // =IF(W5 < X5, LOOKUP(M5,$Grundförutsättningar.A$81:A$96,$Grundförutsättningar.C$81:C$96) * SUM((X5 - W5) / X5), 0)
    let k_behov = calculate_k_behov(k_al);

    if (TILLFORT_K < k_behov) {
        let data = K_AVKASTN_MINSKN_CAN_BE_FIXED.filter((row) => {
            return Math.round(k_al) == row.value;
        });

        if (data[0]) {
            return data[0].amount * ((k_behov - TILLFORT_K) / k_behov);
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

function calculate_k_behov(k_al) {
    let data = K_BEHOV_TABLE.filter((row) => {
        return Math.round(k_al) == row.value;
    });

    // If the k_al is greater than 16 we need to return 0, we have no data points above 16.
    if (data[0]) {
        return data[0].amount;
    } else {
        return 0;
    }
}

/**
 *
 * 	K/Mg Kvot calculation  ------------------------------------------------------------------------
 *
 */

function calculate_k_mg_kvot(k_al, mg_al) {
    // =IF(O10 > 1, AC10 / AD10, 0)
    let k_mg_kvot = 0;
    if (mg_al > 1) {
        k_mg_kvot = k_al / mg_al;
    }
    return k_mg_kvot;
}

/**
 *  This method is tested and checked, and should be fine.
 */
function calculate_k_kvot_behov(k_mg_kvot) {
    // AE5
    // =IF(N5 > 0,
    // 	IF(R5 < 0.95,
    // 		LOOKUP(R5,$Grundförutsättningar.A$126:A$134,$Grundförutsättningar.D$126:D$134),
    // 		0
    // 	),
    // 0)
    if (k_mg_kvot < 0.95) {
        let data = K_KVOT_BEHOV_TABLE.filter((row) => {
            return parseFloat(k_mg_kvot.toFixed(1)) == parseFloat(row.value);
        });
        // If the k_mg_kvot is greater than 0.9 we need to return 0, we have no data points above 16.
        if (data[0]) {
            return data[0].amount;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

function calculate_k_kvot_avk_minsk_grund(k_al, k_mg_kvot) {
    // Avkastningspotential med aktuell gröda och sort (kg/ha):
    // =IF(B5="", D20 * D15 / 100, D17 * D14 / 100)
    let data = K_KVOT_AVK_MINSK_GRUND_LOW_TABLE.filter((row) => {
        return parseFloat(k_mg_kvot.toFixed(1)) == parseFloat(row.value);
    });

    // k_al > 0
    // =IF(N5 > 0,
    // 	// K/Mg < 0.95
    // 	IF(R5 < 0.95,
    // 		// 5 / k_al * B$126:B$134
    // 		5 / N5 * LOOKUP(R5,$Grundförutsättningar.A$126:A$134,$Grundförutsättningar.B$126:B$134),
    // 		0
    // 	),
    // 0)
    if (data[0] && k_mg_kvot < 0.95) {
        return (5 / Math.round(k_al)) * data[0].amount;
    } else {
        return 0;
    }
}

function calculate_k_kvot_avk_minsk_paverkbar(k_al, k_mg_kvot) {
    // =IF(AA5 < AE5,
    // 	IF(R5 < 0.95,
    // 		5 / N5 * value_fixable * SUM((AE5 - AA5) / AE5),
    // 		0
    // 	),
    // 	0
    // )
    // This is kalium need based on k_mg_kvot level
    let k_kvot_behov = calculate_k_kvot_behov(k_mg_kvot);

    if (TILLFORT_K < k_kvot_behov) {
        let data = K_KVOT_AVKASTN_MINSKN_CAN_BE_FIXED.filter((row) => {
            return parseFloat(k_mg_kvot.toFixed(1)) == row.value;
        });

        if (data[0]) {
            return (
                (5 / k_al) *
                data[0].amount *
                ((k_kvot_behov - TILLFORT_K) / k_kvot_behov)
            );
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

function calculate_k_al_avkastningsminskning(sample) {
    let k_al = calculate_volym_justering(sample.mullhalt) * sample.k_al;
    let mg_al = calculate_volym_justering(sample.mullhalt) * sample.mg_al;

    let avk_minsk_grund = calculate_k_avk_minsk_grund(k_al);
    let avk_minsk_paverkbar = calculate_k_avk_minsk_paverkbar(k_al);

    // OK to here probably
    let k_mg_kvot = calculate_k_mg_kvot(k_al, mg_al);

    let k_kvot_avkastn_grund = calculate_k_kvot_avk_minsk_grund(
        k_al,
        k_mg_kvot
    );
    let k_kvot_avk_minsk_paverkbar = calculate_k_kvot_avk_minsk_paverkbar(
        k_al,
        k_mg_kvot
    );

    // BV5
    // =IF(AC5 + AD5 + AF5 + AG5 > 0, AC5 + AD5 + AF5 + AG5, 0)
    // =IF(N5>0,LOOKUP(N5,$Grundförutsättningar.A$103:A$118,$Grundförutsättningar.B$103:B$118),0)
    // if(K-Al Avk minskn grund + K-Al Avk minskn påverkbar + Kvot K/Mg-Al Avk minskn grund + Kvot K/Mg-Al Avk minskn påverkbar) {
    if (
        avk_minsk_grund +
            avk_minsk_paverkbar +
            k_kvot_avkastn_grund +
            k_kvot_avk_minsk_paverkbar >
        0
    ) {
        // return K-Al Avk minskn grund + K-Al Avk minskn påverkbar + Kvot K/Mg-Al Avk minskn grund + Kvot K/Mg-Al Avk minskn påverkbar
        return (
            avk_minsk_grund +
            avk_minsk_paverkbar +
            k_kvot_avkastn_grund +
            k_kvot_avk_minsk_paverkbar
        );
    } else {
        return 0;
    }
}

/**
 *
 * 	Mg calculation  ------------------------------------------------------------------------
 *
 */

function calculate_mg_behov(diff) {
    // =LOOKUP(AK5,$Grundförutsättningar.A$141:A$151,$Grundförutsättningar.D$141:D$151)
    let data = MG_BEHOV_TABLE.filter((row) => {
        return Math.round(diff) == row.value;
    });

    // If the diff is greater than 10 we need to return 0, we have no data points above 16.
    if (data[0]) {
        return data[0].amount;
    } else {
        return 0;
    }
}

function calculate_mg_avk_minsk_grund(mg_al) {
    // Avkastningspotential med aktuell gröda och sort (kg/ha):
    // =IF(B5="", D20 * D15 / 100, D17 * D14 / 100)
    let data = MG_AVK_MINSK_GRUND_LOW_TABLE.filter((row) => {
        return Math.round(mg_al) == row.value;
    });

    // If the mg_al is greater than 10 we need to return 0, we have no data points above 16.
    if (data[0]) {
        return data[0].amount;
    } else {
        return 0;
    }
}

function calculate_mg_avk_minsk_paverkbar(diff) {
    // =IF(AH32 < AI32,
    // 	LOOKUP(AK32, $Grundförutsättningar.A$141:A$151,$Grundförutsättningar.C$141:C$151) * SUM((AI32 - AH32) / AI32),
    // 	0
    // )
    let mg_behov = calculate_mg_behov(diff);

    if (TILLFORT_MG < mg_behov) {
        let data = MG_AVKASTN_MINSKN_CAN_BE_FIXED.filter((row) => {
            return Math.round(diff) == row.value;
        });

        if (data[0]) {
            return (data[0].amount * (mg_behov - TILLFORT_MG)) / mg_behov;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

function calculate_mg_al_avkastningsminskning(sample) {
    let mg_al = Math.round(
        calculate_volym_justering(sample.mullhalt) * sample.mg_al
    );

    // BW5
    // Avk minskn grund + Avk minskn påverkbar > 0 ? (100 / Åtgärdat efter kart.) / 100 * (Avk minskn grund + Avk minskn påverkbar)
    // =IF(AL17 + AM17 > 0, (100 - Q17) / 100 * SUM(AL17 + AM17), 0)

    // Avk minskn grund + Avk minskn påverkbar > 0 ? Avk minskn grund + Avk minskn påverkbar : 0;
    let avk_minsk_grund = calculate_mg_avk_minsk_grund(mg_al);

    // AQ Min Mg
    // =IF(R10 < 15, 5 + 0.2 * R10, 8)
    // AR Max Mg
    // =IF(R10 < 15 , 8 + 0.1333 * R10, 10)
    let min_mg = 8;
    let max_mg = 10;
    if (sample.lerhalt < 15) {
        min_mg = 5 + 0.2 * sample.lerhalt;
        max_mg = 8 + 0.1333 * sample.lerhalt;
    }

    // AS Kvot Mg
    // =M10 / 2 + 1
    let kvot_mg = sample.k_al / 2 + 1;

    // AT Kvot jfr Mg-Al
    // =IF(AS10 > AQ10, AS10, AQ10)
    let kvot_jfr_mg_al = kvot_mg > min_mg ? kvot_mg : min_mg;

    // AU Önskvärt Mg-Al
    // =IF(AT10 < AR10, AT10, AR10)
    let onskvart_mg_al = Math.round(
        kvot_jfr_mg_al < max_mg ? kvot_jfr_mg_al : max_mg
    );

    // =IF(AJ5 - P5 > 0, AJ5 - P5, 0)
    let diff = onskvart_mg_al - mg_al > 0 ? onskvart_mg_al - mg_al : 0;

    let avk_minsk_paverkbar = calculate_mg_avk_minsk_paverkbar(diff);

    if (avk_minsk_grund + avk_minsk_paverkbar > 0) {
        return avk_minsk_grund + avk_minsk_paverkbar;
    } else {
        return 0;
    }
}
