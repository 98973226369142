const namespaced = true;

const state = {
    crops: [],
};

const getters = {
    crops: (state) => state.crops,
};

const actions = {
    GET_CROPS({ commit }, licenses) {
        return new Promise((resolve, reject) => {
            axios.get("/crop").then((response) => {
                commit("setCrops", response.data);
                resolve();
            });
        });
    },
    REMOVE_CROPNEED({ commit }, cropNeed) {
        commit("removeCropNeed", cropNeed);
    },

    ADD_CROPNEED({ commit }, cropNeed) {
        commit("addCropNeed", cropNeed);
    },

    UPDATE_CROPNEED({ commit }, cropNeed) {
        commit("updateCropNeed", cropNeed);
    },
};

const mutations = {
    setCrops(state, crops) {
        state.crops = crops;
    },
    addCropNeed(state, cropNeed) {
        let crop = state.crops.find((crop) => crop.id === cropNeed.crop_id);
        crop.crop_needs.push(cropNeed);
    },
    removeCropNeed(state, cropNeed) {
        let crop = state.crops.find((crop) => crop.id === cropNeed.crop_id);
        crop.crop_needs = crop.crop_needs.filter((x) => x.id !== cropNeed.id);
    },
    updateCropNeed(state, cropNeed) {
        let cropIndex = state.crops.findIndex(
            (crop) => crop.id === cropNeed.crop_id
        );

        if (cropIndex !== -1) {
            let needIndex = state.crops[cropIndex].crop_needs.findIndex(
                (need) => need.id === cropNeed.id
            );

            if (needIndex !== -1) {
                // we are emtying the array to enable the triggering of change
                let tempCropNeeds = state.crops[cropIndex].crop_needs;
                state.crops[cropIndex].crop_needs = [];
                tempCropNeeds[needIndex] = cropNeed;
                state.crops[cropIndex].crop_needs = tempCropNeeds;
            }
        }
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
