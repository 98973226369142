const namespaced = true;

const state = {
    currentRaster: null,
};

const getters = {
    currentRaster: (state) => state.currentRaster,
};

const actions = {
    SET_CURRENT_RASTER({ dispatch, commit, getters, rootGetters }, id) {
        return new Promise((resolve, reject) => {
            axios.get("/raster/" + id).then((response) => {
                commit("setCurrentRaster", response.data);
                dispatch("layers/SET_AVAILABLE_LAYERS", {}, { root: true });
                resolve();
            });
        });
    },

    UPDATE_RASTER({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.patch("/raster/" + params.id, params).then((response) => {
                commit("setCurrentRaster", response.data);
                resolve();
            });
        });
    },

    ADD_LAYER({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios
                .post("/raster/" + params.id + "/layer/create", params.layer)
                .then((response) => {
                    commit("setCurrentRaster", response.data);
                    resolve();
                });
        });
    },
};

const mutations = {
    setCurrentRaster(state, raster) {
        state.currentRaster = raster;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
