const namespaced = true;
import types from "@/utils/types";

const state = {
    currentType: null,
    currentInterpolationType: null,
    currentSmoothColors: null,
    showAllClay: false,
    interpolationSettings: [],
    interpolationTypes: types,
};

const getters = {
    settings: (state) => state.interpolationSettings,
    types: (state) => state.interpolationTypes,
    currentType: (state) => state.currentType,
    currentInterpolationType: (state) => state.currentInterpolationType,
    currentSmoothColors: (state) => state.currentSmoothColors,
    showAllClay: (state) => state.showAllClay,

    activeInterpolationSetting: (state) =>
        getActiveSetting(state.currentType, state.interpolationSettings),
    availableTypes: (state, getters, rootState, rootGetters) =>
        getAvailableTypes(getters.types, getters.settings, rootGetters),
};

const actions = {
    GET_INTERPOLATION_SETTINGS({ commit }) {
        axios.get("/interpolationsetting").then((response) => {
            commit("setInterpolationSettings", response.data);
        });
    },

    SET_INTERPOLATION_SETTINGS({ commit }, settings) {
        commit("setInterpolationSettings", settings);
    },

    UPDATE_SETTING({ commit }, setting) {
        if (setting.active) {
            commit("clearActive", setting.type);
        }

        commit("updateSetting", setting);
    },

    ADD_SETTING({ commit }, setting) {
        if (setting.active) {
            commit("clearActive", setting.type);
        }

        commit("addSetting", setting);
    },

    DELETE_SETTING({ commit }, id) {
        axios.delete("/interpolationsetting/" + id).then((response) => {
            commit("deleteSetting", id);
        });
    },

    SET_TYPE({ commit, getters, dispatch }, type) {
        commit("setType", type);
        if (
            getters.activeInterpolationSetting &&
            getters.activeInterpolationSetting.smoothColors
        ) {
            dispatch(
                "SET_SMOOTH_COLORS",
                getters.activeInterpolationSetting.smoothColors
            );
        }
    },

    SET_INTERPOLATION_TYPE({ commit }, interpolationType) {
        commit("setInterpolationType", interpolationType);
    },

    SET_SMOOTH_COLORS({ commit }, smoothColors) {
        commit("setSmoothColors", smoothColors);
    },

    SET_SHOW_ALL_CLAY({ commit, dispatch }, showAllClay) {
        commit("setShowAllClay", showAllClay);
        if (showAllClay) {
            dispatch("block/FETCH_ALL_CLAY_SAMPLES", {}, { root: true });
        }
    },
};

const mutations = {
    setInterpolationSettings(state, colors) {
        state.interpolationSettings = colors;
    },

    clearActive(state, type) {
        state.interpolationSettings.forEach((setting) => {
            if (setting.type == type && setting.active) {
                setting.active = false;
            }
        });
    },

    updateSetting(state, setting) {
        let found = state.interpolationSettings.find(
            (s) => s.id === setting.id
        );

        if (found) {
            Object.keys(setting).forEach((key) => (found[key] = setting[key]));
        }
    },

    addSetting(state, setting) {
        state.interpolationSettings.push(setting);
    },

    deleteSetting(state, id) {
        state.interpolationSettings = state.interpolationSettings.filter(
            (setting) => {
                return setting.id != id;
            }
        );

        bus.$emit("display-notification", {
            title: "Inställningen togs bort!",
            type: "success",
        });
    },

    setType(state, type) {
        state.currentType = type;
    },

    setInterpolationType(state, interpolationType) {
        state.currentInterpolationType = interpolationType;
    },

    setSmoothColors(state, smoothColors) {
        state.currentSmoothColors = smoothColors;
    },

    setShowAllClay(state, showAllClay) {
        state.showAllClay = showAllClay;
    },
};

/**
 * Will return the setting that is for the current type
 * and that is set to active
 */
function getActiveSetting(type, settings) {
    return settings.find((setting) => {
        return setting.active && setting.type === type;
    });
}

/**
 * Will return the types that is possible to show
 * Is retrieved by viewing the properties of all the samples
 */
function getAvailableTypes(types, settings, rootGetters) {
    let region = rootGetters["auth/region"];
    let filteredTypes = types;

    // filter out types for regions other than Halland
    if (region && region.id !== 2) {
        // for all other than Västra (and Halland), we remove kalkbehov
        if (region.id !== 6) {
            filteredTypes = types.filter((t) => t.slug !== "kalkbehov");
        }
        filteredTypes = filteredTypes.filter(
            (t) =>
                t.slug !== "kalkbehov_spannmal" &&
                t.slug !== "kalkbehov_sockerbetor"
        );
    }
    const maxMullhalt = rootGetters["block/maxMullhalt"];
    const mullhaltTrigger = import.meta.env.VITE_MULLHALT_VC_TRIGGER || 12;

    if (maxMullhalt < mullhaltTrigger) {
        filteredTypes = filteredTypes.filter(
            (t) =>
                ![
                    "volume_corrected_p_al",
                    "volume_corrected_k_al",
                    "volume_corrected_mg_al",
                    "volume_corrected_ca_al",
                    "volume_corrected_fe_al",
                    "volume_corrected_al_al",
                    "volume_corrected_k_hcl",
                    "volume_corrected_cu_hcl",
                ].includes(t.slug)
        );
    }

    let foundTypes = filteredTypes.reduce((obj, type) => {
        obj[type.slug] = false;
        return obj;
    }, {});

    if (rootGetters["block/samples"]) {
        rootGetters["block/samples"].forEach((sample) => {
            Object.keys(foundTypes).forEach((key) => {
                if (
                    sample.properties[key] !== "" &&
                    sample.properties[key] !== 0 &&
                    sample.properties[key] != null
                ) {
                    foundTypes[key] = true;
                }
            });
        });
        return types.filter((type) => {
            return foundTypes[type.slug];
        });
    }
    return [];
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
