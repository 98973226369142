import { calculateMgBehovSingleSample } from "@/helpers/evaluation.js";
import { calculateTargetPh } from "@/helpers/chalk.js";
import uuid from "uuid";

const namespaced = true;

const state = {
    blocks: null,
    polygons: null,
    samples: null,
    customer_id: null,
    year: null,
    joinedSampling_id: null,
    interpolateParcels: true,
    useParcels: true,
    selectedPolygons: [],
    allClaySamples: null,
};

const getters = {
    blocks: (state) => state.blocks,
    polygons: (state) => state.polygons,
    filteredClaySamples: (state) =>
        state.allClaySamples
            ? state.allClaySamples.filter(
                  (c) =>
                      state.samples &&
                      !state.samples.some(
                          (s) => s.properties.id == c.properties.id
                      )
              )
            : [],
    defaultSamples: (state) => state.samples,
    samples: (state, getters, rootState, rootGetters) =>
        getSamples(state.samples, getters.filteredClaySamples, rootGetters),
    year: (state) => state.year,
    joinedSampling: (state) => state.joinedSampling_id,
    interpolateParcels: (state) => state.interpolateParcels,
    useParcels: (state) => state.useParcels,
    interpolationGeoms: (state) =>
        state.interpolateParcels ? state.polygons : state.blocks,
    selectedPolygons: (state) => getSelectedPolygons(state),
    selectedPolygonsAndSamples: (state, getters) =>
        getPolygonsAndSamples(state, getters.samples),
    selectedSamples: (state, getters) => getSelectedSamples(getters),
    maxMullhalt: (state, getters) => getMax(getters.samples, "mullhalt"),
    selectedSamplesStrict: (state, getters) => selectedSamplesStrict(getters),
    selectedSamplesGeoJson: (state, getters) =>
        getters.samples.filter((s) =>
            getters.selectedSamples.some(
                (selectedSample) => selectedSample.id == s.properties.id
            )
        ),
};

const actions = {
    CLEAR_STORE({ commit }) {
        commit("clearStore");
    },

    SET_CUSTOMER_YEAR({ commit, dispatch }, parameters) {
        commit("setAllClaySamples", null);
        commit("setCustomer", parameters.customer_id);
        commit("setYear", parameters.year);
        dispatch("GET_SAMPLES");
        dispatch("GET_SETTINGS");
    },

    SET_POLYGONS_AND_SAMPLES({ commit }, { polygons, samples }) {
        commit("setAllClaySamples", null);
        commit("setSamples", samples);
        commit("setPolygons", polygons);
        commit("setBlocks", polygons);
    },

    GET_CULTIVATION_YEAR({ commit, dispatch }, parameters) {
        let url =
            "cultivationyear/" + parameters.year + "/" + parameters.customer_id;
        let message = "Något gick fel när gårdsbilden hämtades";
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((response) => {
                    let samples = response.data.joined_sampling;
                    let polygons = response.data.polygons;
                    if (samples && polygons) {
                        let parsedPolygon = JSON.parse(polygons[0].features);
                        commit(
                            "setSamples",
                            JSON.parse(samples.SamplesAsGeoJson[0].features)
                        );
                        commit("setPolygons", parsedPolygon);
                        commit("setBlocks", parsedPolygon);
                        resolve();
                    } else {
                        message = `Gårdsbilden (${parameters.year}) är inte fullständig, var god koppla året till en markkartering`;
                        reject({
                            message: message,
                            year: parameters.year,
                            exists: true,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        message = `Gårdsbilden för ${parameters.year} finns inte`;
                    }
                    reject({
                        message: message,
                        year: parameters.year,
                        exists: false,
                    });
                });
        });
    },

    SET_JOINED_SAMPLING({ commit, dispatch }, sampling_id) {
        commit("setJoinedSampling", sampling_id);
        dispatch("GET_SAMPLES");
        dispatch("GET_SETTINGS");
    },

    GET_POLYGONS({ state, commit, getters, rootGetters, dispatch }) {
        let url = "polygon/" + state.customer_id + "/year/" + state.year;
        if (state.joinedSampling_id) {
            url = "/polygon/joinedsampling/" + state.joinedSampling_id;
        }

        axios.get(url).then((response) => {
            let polygons = JSON.parse(response.data[0].features);
            polygons.forEach((p) => {
                p.properties.id = uuid.v4();
                if (p.properties.parcels && p.properties.parcels.features) {
                    p.properties.parcels.features.forEach((f) => {
                        f.properties.id = uuid.v4();
                    });
                }
            });

            commit("setBlocks", polygons);
            commit(
                "setPolygons",
                getPolygons(
                    state,
                    getters.samples,
                    rootGetters["license/getLicense"]("can_import_xml_files"),
                    polygons
                )
            );

            // When the polygons have been retrieved, we can retrieve all clay samples since we need the polygons
            if (
                rootGetters["interpolationSettings/currentType"] == "lerhalt" &&
                rootGetters["interpolationSettings/showAllClay"]
            ) {
                dispatch("FETCH_ALL_CLAY_SAMPLES");
            }
        });
    },

    GET_SAMPLES({ state, commit, dispatch }) {
        let url = "sample/" + state.customer_id + "/" + state.year + "/geojson";
        if (state.joinedSampling_id) {
            url = "/joinedsamplings/" + state.joinedSampling_id + "/geojson";
        }

        axios.get(url).then((response) => {
            commit("setSamples", JSON.parse(response.data[0].features));
            // Will retrieve the polygons after the samples are retrieved. They are needed if a parcel is missing
            dispatch("GET_POLYGONS");
        });
    },

    SPLIT_POLYGON({ commit }, parameters) {
        commit("splitPolygon", parameters);
    },

    MERGE_POLYGON({ commit }, parameters) {
        commit("mergePolygons", parameters);
    },

    SELECT_POLYGON({ commit }, id) {
        commit("selectPolygon", id);
    },

    GET_SETTINGS({ state, commit }) {
        let settings = localStorage.getItem("settings");
        settings = settings ? JSON.parse(settings) : {};

        if (state.customer_id && settings.hasOwnProperty("customers")) {
            if (settings.customers.hasOwnProperty(state.customer_id)) {
                if (
                    settings.customers[state.customer_id].hasOwnProperty(
                        "interpolateParcels"
                    )
                ) {
                    commit(
                        "setInterpolateParcels",
                        settings.customers[state.customer_id].interpolateParcels
                    );
                }
                if (
                    settings.customers[state.customer_id].hasOwnProperty(
                        "useParcels"
                    )
                ) {
                    commit(
                        "setUseParcels",
                        settings.customers[state.customer_id].useParcels
                    );
                }
            }
        }
    },

    SET_INTERPOLATE_PARCELS({ state, commit }, value) {
        if (state.customer_id) {
            let settings = getCustomerSettings(state.customer_id);

            settings.customers[state.customer_id].interpolateParcels = value;
            localStorage.setItem("settings", JSON.stringify(settings));

            commit("setInterpolateParcels", value);
        }
    },

    SET_USE_PARCELS({ state, commit }, value) {
        if (state.customer_id) {
            let settings = getCustomerSettings(state.customer_id);

            settings.customers[state.customer_id].useParcels = value;
            localStorage.setItem("settings", JSON.stringify(settings));

            commit("setUseParcels", value);

            location.reload();
        }
    },

    FETCH_ALL_CLAY_SAMPLES({ state, commit, getters }) {
        axios
            .post("/sample/allclaybygeojson", {
                geometries: getters.polygons.map((p) =>
                    JSON.stringify(p.geometry)
                ),
            })
            .then(({ data }) => {
                commit(
                    "setAllClaySamples",
                    data.map((r) => ({
                        type: r.type,
                        geometry: JSON.parse(r.geometry),
                        properties: JSON.parse(r.properties),
                    }))
                );
            });
    },

    RESET_SELECT_POLYGONS({ state, commit }) {
        commit("resetSelectedPolygons");
    },

    RESET_YEAR({ state, commit }) {
        commit("resetYear");
    },

    RESET_JOINED_SAMPLING({ state, commit }) {
        commit("resetJoinedSampling");
    },
};

const mutations = {
    clearStore(state) {
        state.blocks = null;
        state.polygons = null;
        state.samples = null;
        state.customer_id = null;
        state.year = null;
        state.joinedSampling_id = null;
        state.selectedPolygons = [];
    },

    setCustomer(state, customer_id) {
        state.customer_id = customer_id;
    },

    setYear(state, year) {
        state.year = year;
    },

    setRaster(state, { polygon, raster }) {},

    resetYear(state) {
        state.year = null;
    },

    setJoinedSampling(state, sampling_id) {
        state.joinedSampling_id = sampling_id;
    },

    resetJoinedSampling(state) {
        state.joinedSampling_id = null;
    },

    setBlocks(state, blocks) {
        state.blocks = blocks;
    },

    setPolygons(state, polygons) {
        state.polygons = polygons;
    },

    setSamples(state, samples) {
        if (samples) {
            state.samples = samples.map((sample) => {
                return calculateBehovValues(sample);
            });
        } else {
            state.samples = samples;
        }
    },

    splitPolygon(state, parameters) {
        // remove the polygon that is being split
        state.polygons = state.polygons.filter(
            (polygon) => polygon.properties.id !== parameters.parent_id
        );
        state.selectedPolygons = state.selectedPolygons.filter(
            (id) => parameters.parent_id !== id
        );

        // Add the new polygons
        parameters.newPolygons.forEach((polygon) =>
            state.polygons.push(polygon)
        );
    },

    mergePolygons(state, parameters) {
        // remove the polygon that are being merged
        state.polygons = state.polygons.filter(
            (polygon) => !parameters.old_ids.includes(polygon.properties.id)
        );

        state.selectedPolygons = state.selectedPolygons.filter(
            (id) => !parameters.old_ids.includes(id)
        );

        // Add the merged polygon
        state.polygons.push(parameters.newPolygon);
    },

    selectPolygon(state, id) {
        if (state.selectedPolygons.includes(id)) {
            state.selectedPolygons = state.selectedPolygons.filter(
                (p_id) => p_id !== id
            );
        } else {
            state.selectedPolygons.push(id);
        }
    },

    resetSelectedPolygons() {
        state.selectedPolygons = [];
    },

    setInterpolateParcels(state, value) {
        state.interpolateParcels = value;
    },

    setUseParcels(state, value) {
        state.useParcels = value;
    },

    setAllClaySamples(state, samples) {
        state.allClaySamples = samples;
    },
};

/**
 * Will add information about behov values
 * Other than those values the sample is not altered
 *
 * @param {object} sample
 */
function calculateBehovValues(sample) {
    sample.properties.magnesiumbehov = calculateMgBehovSingleSample(sample);

    let lerhalt = sample.properties.lerhalt
        ? sample.properties.lerhalt
        : sample.properties.idw_lerhalt;
    let mullhalt = sample.properties.mullhalt
        ? sample.properties.mullhalt
        : sample.properties.idw_mullhalt;

    if (lerhalt > 0 && mullhalt > 0 && sample.properties.ph) {
        let targetpHSpannmal = calculateTargetPh(sample);
        let targetpHSockerbetor = calculateTargetPh(sample, true);
        if (targetpHSpannmal - sample.properties.ph > 0) {
            sample.properties.kalkbehov_spannmal =
                Math.round(
                    (((targetpHSpannmal - sample.properties.ph) *
                        (1.9 + (3.5 * mullhalt + lerhalt) / 3.8)) /
                        2) *
                        10
                ) / 10;
        } else {
            sample.properties.kalkbehov_spannmal = 0;
        }
        if (targetpHSockerbetor - sample.properties.ph > 0) {
            sample.properties.kalkbehov_sockerbetor =
                Math.round(
                    (((targetpHSockerbetor - sample.properties.ph) *
                        (1.9 + (3.5 * mullhalt + lerhalt) / 3.8)) /
                        2) *
                        10
                ) / 10;
        } else {
            sample.properties.kalkbehov_sockerbetor = 0;
        }
    }

    return sample;
}

/**
 * Will ensure that the settings object has the necessary properties
 * for storing settings for the provided customer_id
 */
function getCustomerSettings(customer_id) {
    let settings = localStorage.getItem("settings");
    settings = settings ? JSON.parse(settings) : {};

    if (!settings.hasOwnProperty("customers")) {
        settings.customers = {};
    }

    if (!settings.customers.hasOwnProperty(customer_id)) {
        settings.customers[customer_id] = {};
    }

    return settings;
}

function getSamples(samples, claySamples, rootGetters) {
    if (
        (rootGetters["interpolationSettings/currentType"] == "lerhalt" ||
            rootGetters["params/type"] == "chalk") &&
        rootGetters["interpolationSettings/showAllClay"] &&
        samples &&
        claySamples
    ) {
        return samples.concat(claySamples);
    } else {
        return samples;
    }
}

/**
 * Will retrieve the polygons, will use the provided polygons parcels
 * as the polygons if they exist
 */
function getPolygons(state, samples, hasImportXmlLicense, data) {
    if (
        hasImportXmlLicense &&
        state.useParcels &&
        data.some((obj) => obj.properties.hasOwnProperty("parcels"))
    ) {
        let polygons = data.reduce((array, p) => {
            if (p.properties.parcels.features) {
                return array.concat(p.properties.parcels.features);
            } else {
                let length = samples ? samples.length : 0;
                // If there is no parcels available and the current block has samples contained in it then use that block
                // Can't break early in a forEach, using good old for loop instead
                for (let i = 0; i < length; i++) {
                    if (pointInPolygon(samples[i], p)) {
                        return array.concat(p);
                    }
                }
            }
        }, []);

        // Flattens all the arrays into one array
        return polygons.reduce(
            (array, polygonArray) => array.concat(polygonArray),
            []
        );
    }

    return data;
}

/**
 * Will return an array of objects that contain a polygon and the samples needed
 * to create a grid for the polygon.
 */
function getPolygonsAndSamples(state, samples) {
    if (state.polygons) {
        let selectedPolygons = state.polygons.filter((polygon) =>
            state.selectedPolygons.includes(polygon.properties.id)
        );
        selectedPolygons = selectedPolygons.map((polygon) => {
            let samplesGeoJson = getPolygonSamples(state, samples, polygon);
            return {
                polygon: polygon,
                samplesGeoJson: samplesGeoJson,
                samples: samplesGeoJson.map((sample) => {
                    return {
                        id: sample.properties.id,
                        year: sample.properties.year,
                        x: sample.properties.x,
                        y: sample.properties.y,
                        ph: sample.properties.ph,
                        p_al: sample.properties.p_al,
                        p_olsen: sample.properties.p_olsen,
                        k_al: sample.properties.k_al,
                        mg_al: sample.properties.mg_al,
                        ca_al: sample.properties.ca_al,
                        volume_corrected_p_al:
                            sample.properties.volume_corrected_p_al,
                        volume_corrected_k_al:
                            sample.properties.volume_corrected_k_al,
                        volume_corrected_mg_al:
                            sample.properties.volume_corrected_mg_al,
                        volume_corrected_ca_al:
                            sample.properties.volume_corrected_ca_al,
                        volume_corrected_fe_al:
                            sample.properties.volume_corrected_fe_al,
                        volume_corrected_al_al:
                            sample.properties.volume_corrected_al_al,
                        volume_corrected_k_hcl:
                            sample.properties.volume_corrected_k_hcl,
                        volume_corrected_cu_hcl:
                            sample.properties.volume_corrected_cu_hcl,
                        k_mg_kvot: sample.properties.k_mg_kvot,
                        lerhalt: sample.properties.lerhalt,
                        mullhalt: sample.properties.mullhalt,
                        sand_grovmo: sample.properties.sand_grovmo,
                        other_years_lerhalt:
                            sample.properties.other_years_lerhalt,
                        other_years_mullhalt:
                            sample.properties.other_years_mullhalt,
                        idw_lerhalt: sample.properties.idw_lerhalt,
                        idw_mullhalt: sample.properties.idw_mullhalt,
                        magnesiumbehov: sample.properties.magnesiumbehov,
                    };
                }),
            };
        });
        return selectedPolygons;
    }
}

function getSelectedPolygons(state) {
    if (state.polygons) {
        return state.polygons.filter((polygon) =>
            state.selectedPolygons.includes(polygon.properties.id)
        );
    }
    return [];
}

/**
 * Will retireve the samples that are within the block that has
 * the gid the provided polygon has as gid or parent_gid
 */
function getPolygonSamples(state, samples, polygon) {
    if (polygon.properties.hasOwnProperty("gid")) {
        return getSamplesWithinPolygon(
            samples,
            state.blocks.find(
                (block) => block.properties.gid === polygon.properties.gid
            )
        );
    } else {
        let preFoundBlock = state.blocks.find(
            (block) =>
                block.properties.gid === polygon.properties.parent_gid &&
                !!polygon.properties.parent_gid &&
                !!block.properties.gid
        );
        if (Array.isArray(polygon.properties.parent_gid)) {
            // If the polygon has several parent_ids its a merged polygon. So we retrieve the samples from all the parent-blocks
            let blocks = state.blocks.filter(
                (block) =>
                    polygon.properties.parent_gid.indexOf(
                        block.properties.gid
                    ) !== -1
            );
            return blocks.reduce((array, block) => {
                return array.concat(getSamplesWithinPolygon(samples, block));
            }, []);
        } else if (preFoundBlock) {
            let samplesInBlock = getSamplesWithinPolygon(
                samples,
                preFoundBlock
            );
            let samplesInPolygon = getSamplesWithinPolygon(samples, polygon);
            // to avoid more 'fulhack' we also return the samples within the polygon
            // this is to avoid getting less samples than the selected polygon
            // but with the possibility to get desired samples outside of the polygon
            return getUnique(samplesInBlock.concat(samplesInPolygon));
        } else {
            // Catch all function, will also make sure that "gårdsbild/joined_sampings" parcels work as intended
            return getSamplesWithinPolygon(samples, polygon);
        }
    }
}

function getSelectedSamples(getters) {
    if (getters.selectedPolygonsAndSamples) {
        return getters.selectedPolygonsAndSamples.reduce((array, obj) => {
            return array.concat(obj.samples);
        }, []);
    }
    return [];
}

function selectedSamplesStrict(getters) {
    if (
        getters.selectedSamplesGeoJson &&
        getters.selectedPolygons?.length > 0
    ) {
        return getters.selectedPolygons.reduce((array, polygon) => {
            return array.concat(
                getSamplesWithinPolygon(getters.selectedSamplesGeoJson, polygon)
            );
        }, []);
    }
    return [];
}

function getUnique(array, property = "id") {
    let unique = [];

    array.forEach((element) => {
        if (
            !unique.find(
                (u) => u.properties[property] === element.properties[property]
            )
        ) {
            unique.push(element);
        }
    });
    return unique;
}

/**
 * Will return all samples that are within the provided polygon
 */
function getSamplesWithinPolygon(samples, polygon) {
    return samples.reduce((array, sample) => {
        if (pointInPolygon(sample, polygon)) {
            array.push(sample);
        }
        return array;
    }, []);
}

function getMax(samples, type) {
    return samples.reduce((max, sample) => {
        // if sample has sample[type], else try sample.properties[type]
        return Math.max(max, sample[type] || sample.properties[type]);
    }, 0);
}

import pointInPolygon from "@turf/boolean-point-in-polygon";

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
