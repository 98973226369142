const namespaced = true;

const state = {
    samplings: [],
};

const getters = {
    all: (state) => state.samplings,
};

const actions = {
    GET_JOINED_SAMPLINGS_CUSTOMER({ commit }, customerId) {
        return new Promise((resolve, reject) => {
            axios
                .get("/joinedsamplings/" + customerId + "/all")
                .then((response) => {
                    commit("setSamplings", response.data);
                    resolve();
                });
        });
    },

    GET_JOINED_SAMPLINGS_USER({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/joinedsamplings").then((response) => {
                commit("setSamplings", response.data);
                resolve();
            });
        });
    },

    UPDATE_JOINED_SAMPLING({ commit }, { sampling_id, params }) {
        return new Promise((resolve, reject) => {
            axios
                .put("/joinedsamplings/" + sampling_id, params)
                .then((response) => {
                    commit("updateSampling", response.data);
                    resolve();
                });
        });
    },
};

const mutations = {
    setSamplings(state, samplings) {
        samplings.forEach((sampling) => {
            let customers = [];
            let foundCustomer;
            sampling.customerYear.forEach((customerYear) => {
                foundCustomer = _.find(customers, (customer) => {
                    return customer.id == customerYear.customer.id;
                });
                if (foundCustomer) {
                    foundCustomer.years.push({
                        year: customerYear.year,
                        count: customerYear.count,
                    });
                } else {
                    customerYear.customer.years = [
                        { year: customerYear.year, count: customerYear.count },
                    ];
                    customers.push(customerYear.customer);
                }
            });

            sampling.customers = customers;
        });

        state.samplings = samplings;
    },

    setActiveYear(state, currentYear) {
        state.currentYear = currentYear;
    },

    updateSampling(state, sampling) {
        let index = state.samplings.findIndex((s) => s.id === sampling.id);
        if (index >= 0) {
            sampling.customers = state.samplings[index].customers;
            state.samplings[index] = sampling;
            state.samplings = JSON.parse(JSON.stringify(state.samplings));
        }
    },

    setCurrentSamples(state, samples) {
        state.currentSamples = samples;
    },

    setfeaturess(state, data) {
        state.features = JSON.parse(data[0].features);
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
