const namespaced = true;

const state = {
    expectedYield: null,
    n_need: null,
    p_need: null,
    k_need: null,

    pInputBalance: null,
    kInputBalance: null,
    nLongterm: null,
    preCropEffect: null,
    mullSoil: null,
};

const getters = {
    netto: (state, getters, rootState, rootGetters) => {
        let activeParcel = rootGetters["cultivationYears/activeParcel"];
        if (activeParcel) {
            return activeParcel.nettoNeeds;
        } else {
            return {
                n: 0,
                p: 0,
                k: 0,
            };
        }
    },

    planned: (state, getters, rootState, rootGetters) => {
        return rootGetters["runs/runs"]
            .filter((run) => {
                return !run.performed;
            })
            .reduce(
                (values, run) => {
                    if (run.n) {
                        values.n += parseFloat(run.n);
                    }
                    if (run.p) {
                        values.p += parseFloat(run.p);
                    }
                    if (run.k) {
                        values.k += parseFloat(run.k);
                    }
                    return values;
                },
                { n: 0, p: 0, k: 0 }
            );
    },
    // planned: (state, getters, rootState, rootGetters) =>
    //     getPlanned(state, rootGetters["runs/runs"]),
    performed: (state, getters, rootState, rootGetters) => {
        /**
         *
         * Need to work on runs.
         * We have nothing that alters our values when creating runs!
         * We need to set NPK and so on when saving a run.
         *
         */
        return rootGetters["runs/runs"]
            .filter((run) => run.performed === true)
            .reduce(
                (values, run) => {
                    if (run.n) {
                        values.n += parseFloat(run.n);
                    }
                    if (run.p) {
                        values.p += parseFloat(run.p);
                    }
                    if (run.k) {
                        values.k += parseFloat(run.k);
                    }
                    return values;
                },
                { n: 0, p: 0, k: 0 }
            );
    },
    // performed: (state, getters, rootState, rootGetters) =>
    //     getPerformed(state, rootGetters["runs/runs"]),
    balance: (state, getters, rootState, rootGetters) => {
        return {
            n:
                getters.performed.n +
                getters.planned.n -
                rootGetters["cultivationYears/activeParcel"].nettoNeeds.n,
            p:
                getters.performed.p +
                getters.planned.p -
                rootGetters["cultivationYears/activeParcel"].nettoNeeds.p,
            k:
                getters.performed.k +
                getters.planned.k -
                rootGetters["cultivationYears/activeParcel"].nettoNeeds.k,
        };
    },
    // balance: (state, getters, rootState, rootGetters) =>
    //     getBalance(state, getters, rootGetters),

    /**
     *	For each substance add together the inputed need, then add the saved
     *	fieldConditions-values.
     * 	(For N: n_longterm, pre_crop_effect and mull_soil)
     *	(For P: p_input_balance)
     *	(For K: k_input_balance)
     *	Then subtract those same values but from the the input fields instead of the parcel
     */
    calculatedNeed: (state) => {
        return {
            n:
                state.n_need -
                state.nLongterm -
                state.preCropEffect -
                state.mullSoil,
            p: state.p_need - state.pInputBalance,
            k: state.k_need - state.kInputBalance,
        };
    },

    canUpdateNeeds: (state, getters) => {
        let values = [
            getters.calculatedNeed.n,
            getters.calculatedNeed.p,
            getters.calculatedNeed.k,
            state.pInputBalance,
            state.kInputBalance,
            state.nLongterm,
            state.preCropEffect,
            state.mullSoil,
        ];

        return values.every((val) => val !== "");
    },
};

const actions = {};

const mutations = {};

function getPlanned(state, runs) {
    return runs
        .filter((run) => {
            return !run.performed;
        })
        .reduce(
            (values, run) => {
                if (run.n) {
                    values.n += parseFloat(run.n);
                }
                if (run.p) {
                    values.p += parseFloat(run.p);
                }
                if (run.k) {
                    values.k += parseFloat(run.k);
                }
                return values;
            },
            { n: 0, p: 0, k: 0 }
        );
}

function getPerformed(state, runs) {
    return runs
        .filter((run) => {
            return run.performed;
        })
        .reduce(
            (values, run) => {
                if (run.n) {
                    values.n += parseFloat(run.n);
                }
                if (run.p) {
                    values.p += parseFloat(run.p);
                }
                if (run.k) {
                    values.k += parseFloat(run.k);
                }
                return values;
            },
            { n: 0, p: 0, k: 0 }
        );
}

function getBalance(state, getters, rootGetters) {
    return {
        n:
            getters.performed.n +
            getters.planned.n -
            rootGetters["cultivationYears/activeParcel"].nettoNeeds.n,
        p:
            getters.performed.p +
            getters.planned.p -
            rootGetters["cultivationYears/activeParcel"].nettoNeeds.p,
        k:
            getters.performed.k +
            getters.planned.k -
            rootGetters["cultivationYears/activeParcel"].nettoNeeds.k,
    };
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
