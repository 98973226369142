import "./package-imports.js";
import OutsideClick from "@/directives/click-outside";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import Vue from "vue";
import Pusher from 'pusher-js';

window.Vue = Vue;

/**
 *	Create our message bus
 */
window.bus = new Vue();
window.Pusher = Pusher;

import VueRouter from "vue-router";
import VueAxios from "vue-axios";

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

window.supportAxios = axios.create({
    withCredentials: true,
});

axios.defaults.baseURL = import.meta.env.VITE_API_URL + "api/v1";
window.supportAxios.defaults.baseURL = import.meta.env.VITE_SUPPORT_URL + "api";

if (process.env.NODE_ENV !== "production") {
    Vue.config.productionTip = false;
} else {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}

import routes from "./routes/routes";
import store from "./store";

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    routes,
});

Vue.router = router;

Vue.axios.interceptors.response.use(
    (response) => {
        // intercept the global error
        return response;
    },
    function (error) {
        if (
            error.response &&
            error.response.status === 401 &&
            Vue.router.currentRoute.name != "login"
        ) {
            Vue.router.push({ name: "login" });
        }

        return Promise.reject(error);
    }
);

// Because we want to be able to test FETCH_USER in login we need to have a bearer
if (store.getters["auth/access_token"]) {
    axios.defaults.headers.common["Authorization"] =
        store.getters["auth/access_token"];
}

/** Google Analytics */
const isLocalhost = Boolean(
    window.location.hostname === "markkartering.test" ||
        window.location.hostname === "localhost" ||
        window.location.hostname === "[::1]" ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

if (process.env.NODE_ENV === "production" && !isLocalhost) {
    router.onReady(() => {
        window.ga =
            window.ga ||
            function () {
                (ga.q = ga.q || []).push(arguments);
            };
        ga.l = +new Date();

        ga("create", "UA-66344126-7", "auto");

        router.afterEach((to, from) => {
            ga("set", "page", to.fullPath);
            ga("send", "pageview");
        });
    });
    router.onError((error, to) => {
        if (
            error.message.includes(
                "Failed to fetch dynamically imported module"
            )
        ) {
            window.location = to.fullPath;
        }
    });
}

/**
 *	Add the hasLicense prototype so we can check the licenses
 *  Half mesure, in the inital months of MK.se alla users are "premium"
 */
Vue.prototype.$hasLicense = function (slug) {
    let user = this.$store.getters["auth/user"];
    if (user && user.licenses) {
        return user.licenses[slug];
    }
    // return false;
    return true;
};

/**
 *	Add the hasLicense prototype so we can check the licenses
 */
Vue.prototype.$auth_check = function (user, slug) {
    return this.$store.getters["auth/isAuthenticated"];
};

Vue.prototype.$auth_user = function (user, slug) {
    return this.$store.getters["auth/user"];
};

/**
 *	Add the hasPermission prototype so we can check the permissions
 */
Vue.prototype.$hasPermission = function (slug) {
    let user = this.$store.getters["auth/user"];
    if (user && user.permissions) {
        return user.permissions[slug];
    }
    return false;
};

Vue.prototype.$hasCustomers = function () {
    let user = this.$store.getters["auth/user"];
    if (user) {
        return user.customers_count > 0;
    }
    return false;
};

Vue.directive("clicked-outside", OutsideClick);

/**
 * Will check if a route has a meta parameter called license
 * If the roues has that parameter, check if the user has the license
 * If the user does not have the license, redirect to /dashboard
 */
router.beforeEach((to, from, next) => {
    let vue = router.apps[0];
    if (to.meta.auth == false) {
        next();
    } else if (vue.$auth_check()) {
        if (to.meta.license !== undefined) {
            if (vue.$hasLicense(to.meta.license)) {
                next();
            } else if (from.path === "/") {
                // if we navigate directly from the addressbar, the popup should not be visisble
                // we redirect as before
                next({ path: "/dashboard" });
            } else {
                bus.$emit("open-not-allowed-modal", {
                    license: to.meta.license,
                });
            }
        } else {
            next();
        }
    } else {
        next({ name: "login", query: { from: JSON.stringify(to) } });
    }
});

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        Vue,
        dsn: "https://da5ba60913354220a8164fa1b18882f9@o80035.ingest.sentry.io/192788",
        initialScope: {
            user: store.getters["auth/isAuthenticated"]
                ? {
                      id: store.getters["auth/user"].id,
                      email: store.getters["auth/user"].email,
                  }
                : "User not signed in",
        },
        integrations: [
            new ExtraErrorDataIntegration(),
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // We recommend adjusting this value in production
        // of transactions for performance monitoring.
        tracesSampleRate: 0.2,
    });
}

new Vue({
    el: "#app",
    created() {
        if (this.$store.getters["auth/isAuthenticated"]) {
            Vue.axios.defaults.headers.common["Authorization"] =
                this.$store.getters["auth/access_token"];
            this.$store.dispatch("auth/FETCH_USER");
            this.$store.dispatch("activeCustomer/REFRESH_ACTIVE_CUSTOMER");
        } else if (this.$store.getters["auth/access_token"]) {
            this.$store.dispatch("auth/AUTH_LOGOUT");
        }

        if (this.$store.getters["support-auth/accessToken"]) {
            window.supportAxios.defaults.headers.common["Authorization"] =
                this.$store.getters["support-auth/accessToken"];
        }
    },
    router,
    store,
});

/**
 *
 * Check what browser the user has
 * If it is to old redirect the user
 *
 */
if (
    (bowser.msie && bowser.version <= 11) ||
    (bowser.chrome && bowser.version <= 60)
) {
    window.location.replace("/browser");
}
