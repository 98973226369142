const namespaced = true;

const state = {
    licenses: [],
};

const getters = {
    getLicense: (state) => (name) => getLicense(state.licenses, name),
};

const actions = {
    SET_LICENSES({ commit }, licenses) {
        commit("setLicenses", licenses);
    },
};

const mutations = {
    setLicenses(state, licenses) {
        state.licenses = licenses;
    },
};

function getLicense(licenses, name) {
    let license = licenses.find((license) => license.name === name);
    if (license) {
        return license.state;
    }
    return false;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
