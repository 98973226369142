import area from "@turf/area";
import isPointInPolygon from "@turf/boolean-point-in-polygon";
import store from "@/store";
import { polygon } from "leaflet";

const namespaced = true;

const state = {
    cultivationYears: [],
    years: [],
    polygons: null,
    selectedParcelIds: [],
    currentCultivationYear: null,
    previousCultivationYear: null,
    samplesAsFeatures: [],
    selectedBlocks: [],
    isEditing: false,
    offset: 0,
    activeParcel: null,
};

const getters = {
    years: (state) => state.years,
    all: (state) => state.cultivationYears,
    polygons: (state) => state.polygons,
    samples: (state) => state.samplesAsFeatures.map((s) => s.properties),
    selectedPolygons: (state) => getSelectedPolygons(state),
    selectedPolygonsAndSamples: (state, getters) =>
        getSelectedPolygonsAndSamples(state, getters),
    selectedSamples: (state, getters) => getSelectedSamples(state, getters),
    selectedBlocks: (state) => state.selectedBlocks,
    currentCultivationYear: (state) => state.currentCultivationYear,
    previousCultivationYear: (state) => state.previousCultivationYear,
    selectedSamplesStatistics: (state, getters) =>
        getSelectedSamplesStatistics(state, getters),
    selectedParcels: (state) => getSelectedParcels(state),
    isEditing: (state) => state.isEditing,
    offset: (state) => state.offset,

    activeParcel: (state) => state.activeParcel,
};

const actions = {
    GET_CULTIVATION_YEARS({ commit }, customerId) {
        console.log("GET_CULTIVATION_YEARS", customerId);
        return new Promise((resolve, reject) => {
            axios
                .get("/customer/" + customerId + "/cultivationYears?")
                .then((response) => {
                    commit("setCultivationYears", response.data);
                    resolve();
                });
        });
    },

    GET_YEARS({ commit }, customerId) {
        console.log("GET_YEARS", customerId);
        return new Promise((resolve, reject) => {
            axios
                .get("/customer/" + customerId + "/cultivationYears/years")
                .then((response) => {
                    commit("setYears", response.data);
                    resolve();
                });
        });
    },

    DELETE_CULTIVATION_YEAR({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/cultivationyear/" + id)
                .then((response) => {
                    commit("deleteYear", id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    UPDATE_CULTIVATION_YEAR({ commit }, { id, params }) {
        return new Promise((resolve, reject) => {
            axios
                .patch("/cultivationyear/" + id, params)
                .then((response) => {
                    commit("updateCultivationYear", response.data);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    SET_CURRENT_PARCEL(
        { state, commit, getters },
        { parcelId, shadowBlock = false }
    ) {
        if (state.selectedParcelIds.includes(parcelId)) {
            store.dispatch("cultivationYears/DESELECT_PARCEL", {
                parcelId,
                shadowBlock,
            });
        } else {
            store.dispatch("cultivationYears/DESELECT_ALL_PARCELS");
            store.dispatch("cultivationYears/SELECT_PARCEL", {
                parcelId,
                shadowBlock,
            });
        }
    },

    UPDATE_PARCEL({ state, commit, getters }, params) {
        return new Promise((resolve, reject) => {
            axios
                .patch("parcel/" + getters.activeParcel.id, params)
                .then((response) => {
                    commit("updateParcel", response.data);
                    resolve();
                });
        });
    },

    /**
     * This action will only temporary change data of a parcel.
     * It is dependending on us calling UPDATE_CULTIVATION_YEAR_PARCELS
     */
    CHANGE_PARCEL_ATTRIBUTES({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit("updateParcel", params);
            resolve();
        });
    },

    ADD_PARCEL({ commit }, { cultivationYearId, feature }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/cultivationyear/" + cultivationYearId + "/addparcel", {
                    geom: feature.geometry,
                    area: area(feature) / 10000,
                })
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    resolve();
                });
        });
    },

    ADD_PARCELS({ commit }, { cultivationYearId, features }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/cultivationyear/" + cultivationYearId + "/addparcels", {
                    parcels: features.map((feature) => {
                        return {
                            geom: feature.geometry,
                            area: area(feature) / 10000,
                        };
                    }),
                })
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    resolve();
                });
        });
    },

    REMOVE_PARCEL({ commit }, { cultivationYearId, parcelId }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/cultivationyear/" + cultivationYearId + "/removeparcel",
                    {
                        id: parcelId,
                    }
                )
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    resolve();
                });
        });
    },

    UNITE_PARCELS({ commit }, { cultivationYearId, parcelA, parcelB }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/cultivationyear/" + cultivationYearId + "/uniteparcels",
                    {
                        parcelA: parcelA,
                        parcelB: parcelB,
                    }
                )
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    SPLIT_PARCEL(
        { commit },
        { cultivationYearId, parcelId, addedFeatures, updatedFeatures }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/cultivationyear/" + cultivationYearId + "/splitparcel",
                    {
                        id: parcelId,
                        updated_feature: updatedFeatures,
                        added_features: addedFeatures,
                    }
                )
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    resolve();
                });
        });
    },

    TOGGLE_PARCEL({ commit, getters }, { parcelId }) {
        return new Promise((resolve, reject) => {
            if (
                getters.selectedPolygons.some(
                    (p) => p.properties.id === parcelId
                )
            ) {
                commit("deselectParcel", parcelId);
            } else {
                commit("selectParcel", parcelId);
            }
            resolve();
        });
    },

    SELECT_PARCEL(
        { state, commit, getters, dispatch },
        { parcelId, shadowBlock = false }
    ) {
        return new Promise((resolve, reject) => {
            commit("selectParcel", parcelId);
            commit("setActiveParcel", state);
            if (shadowBlock) {
                dispatch("block/SELECT_POLYGON", parcelId, {
                    root: true,
                });
            }
            if (getters.selectedPolygons.length === 1) {
                store.dispatch("runs/GET_PARCEL_RUNS", parcelId);
            } else {
                store.dispatch("runs/CLEAR_RUNS");
            }

            resolve();
        });
    },

    DESELECT_PARCEL(
        { commit, getters, dispatch },
        { parcelId, shadowBlock = false }
    ) {
        return new Promise((resolve, reject) => {
            commit("deselectParcel", parcelId);
            if (shadowBlock) {
                dispatch("block/SELECT_POLYGON", parcelId, {
                    root: true,
                });
            }
            if (getters.selectedPolygons.length === 1) {
                store.dispatch("runs/GET_PARCEL_RUNS", getters.activeParcel.id);
            } else {
                store.dispatch("runs/CLEAR_RUNS");
            }
            resolve();
        });
    },

    DESELECT_ALL_PARCELS({ commit }) {
        return new Promise((resolve, reject) => {
            commit("deselectAllParcels");
            resolve();
        });
    },

    GET_CULTIVATIONYEAR(
        { commit, dispatch },
        { customerId, year, shadowBlock = false }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .get("/cultivationyear/" + year + "/" + customerId)
                .then((response) => {
                    let joinedSampling = response.data.joined_sampling;
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    if (joinedSampling) {
                        commit(
                            "setSamplesAsFeatures",
                            joinedSampling.SamplesAsGeoJson[0].features
                        );
                    }
                    if (shadowBlock) {
                        addToBlockStore(dispatch, response);
                    }
                    resolve();
                })
                .catch((error) => {
                    commit("resetCultivationYear");
                    commit("resetPolygon");
                    reject("cultivationYear");
                });
        });
    },

    GET_PREVIOUS_CULTIVATIONYEAR({ commit, getters }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/cultivationyear/" +
                        (getters.currentCultivationYear.year - 1) +
                        "/" +
                        getters.currentCultivationYear.customer_id
                )
                .then((response) => {
                    if (!response.data.joined_sampling) {
                        reject();
                    }
                    let prev = response.data;
                    let polygons = JSON.parse(
                        response.data.polygons[0].features
                    );
                    let samplesAsFeatures = JSON.parse(
                        response.data.joined_sampling.SamplesAsGeoJson[0]
                            .features
                    );
                    prev.polygons = polygons;
                    prev.samplesAsFeatures = samplesAsFeatures;
                    commit("setPreviousCultivationYear", prev);
                    resolve();
                })
                .catch((error) => {
                    // reject();
                });
        });
    },

    UPDATE_CULTIVATION_YEAR_PARCELS(
        { commit },
        { cultivationYearId, parcels }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .patch("/cultivationyear/" + cultivationYearId, {
                    parcels: parcels,
                })
                .then((response) => {
                    commit("setCultivationYear", response.data);
                    commit("setPolygon", response.data.polygons);
                    resolve();
                });
        });
    },

    GET_POLYGONS({ commit }, cultivationYear) {
        return new Promise((resolve, reject) => {
            axios
                .get("/cultivationyear/" + cultivationYear + "/polygons")
                .then((response) => {
                    commit("setPolygon", response.data);
                    resolve();
                });
        });
    },

    ATTACH_JOINED_SAMPLING(
        { commit },
        { joinedSamplingId, cultivationYearId }
    ) {
        return new Promise((resolve, reject) => {
            axios
                .post("/cultivationyear/attach", {
                    cultivation_year_id: cultivationYearId,
                    joined_sampling_id: joinedSamplingId,
                })
                .then((response) => {
                    commit("setJoinedSampling", response.data);
                    resolve();
                });
        });
    },

    UPDATE_POLYGON_FEATURES({ commit }, features) {
        commit("updateFeatures", features);
    },

    IMPORT_SAM_FILE({ commit }, { customerId, formData }) {
        return new Promise((resolve, reject) => {
            axios
                .post("/customer/" + customerId + "/importXML", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    bus.$emit("display-notification", {
                        title:
                            error.response.status == 400
                                ? error.response.data
                                : "Något gick fel",
                        type: "error",
                    });
                    reject();
                });
        });
    },

    IMPORT_PREVIOUS_YEAR({ commit }, { customerId, params }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/customer/" + customerId + "/import/cultivationyear",
                    params
                )
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    IMPORT_FROM_DV({ commit }, { customerId, params }) {
        return new Promise((resolve, reject) => {
            axios
                //{customer}/import/cultivationyear/dv
                .post(
                    "/customer/" + customerId + "/import/cultivationyear/dv",
                    params
                )
                .then((response) => {
                    console.log("IMPORT_FROM_DV THEN", response);
                    resolve();
                })
                .catch((error) => {
                    console.log("IMPORT_FROM_DV CATCH", error);
                    reject(error);
                });
        });
    },

    SELECT_BLOCK({ commit }, feature) {
        commit("addBlock", feature);
    },

    DESELECT_BLOCK({ commit }, feature) {
        commit("removeBlock", feature);
    },

    DESELECT_ALL_BLOCKS({ commit }) {
        commit("removeAllBlock");
    },

    IS_EDITING({ commit }, isEditing) {
        commit("setEditingState", isEditing);
    },

    RESET({ commit }) {
        commit("reset");
    },

    SET_OFFSET({ commit }, offset) {
        commit("setOffset", offset);
    },
};

const mutations = {
    selectParcel(state, parcelId) {
        if (!state.selectedParcelIds.includes(parcelId)) {
            state.selectedParcelIds.push(parcelId);
        }
    },

    setActiveParcel(state) {
        state.activeParcel = getActiveParcel(state);
    },

    deselectParcel(state, parcelId) {
        let index = state.selectedParcelIds.findIndex((id) => id === parcelId);
        if (index >= 0) {
            state.selectedParcelIds.splice(index, 1);
        }
    },

    deselectAllParcels(state) {
        state.selectedParcelIds = [];
    },

    updateParcel(state, params) {
        let index = state.currentCultivationYear.parcels.findIndex(
            (parcel) => parcel.id === params.id
        );
        if (index > -1) {
            let updatedParcel = {
                ...state.currentCultivationYear.parcels[index],
                ...params,
            };
            state.currentCultivationYear.parcels[index] = updatedParcel;
            state.activeParcel = updatedParcel;
        }
    },

    setJoinedSampling(state, data) {
        state.currentCultivationYear.joined_sampling_id =
            data.joined_sampling_id;
    },

    setCultivationYear(state, cultivationYear) {
        state.currentCultivationYear = cultivationYear;
    },

    setPreviousCultivationYear(state, cultivationYear) {
        state.previousCultivationYear = cultivationYear;
    },

    setSamplesAsFeatures(state, samples) {
        state.samplesAsFeatures = JSON.parse(samples);
    },

    resetCultivationYear(state) {
        state.currentCultivationYear = null;
    },

    setCultivationYears(state, cultivationYears) {
        state.cultivationYears = cultivationYears;
    },

    setPolygon(state, data) {
        let polygons = data[0];
        let features = JSON.parse(polygons.features);
        polygons.features = features ? features : [];
        state.polygons = polygons;
    },

    resetPolygon(state) {
        state.polygons = null;
    },

    deleteYear(state, id) {
        state.cultivationYears = state.cultivationYears.filter(
            (cy) => cy.id !== id
        );
    },

    updateCultivationYear(state, data) {
        state.cultivationYears = [
            ...state.cultivationYears.filter((cy) => cy.id !== data.id),
            data,
        ].sort((a, b) => a.year > b.year);
    },

    addCultivationYear(state, data) {
        state.cultivationYears = [...state.cultivationYears, data].sort(
            (a, b) => a.year > b.year
        );
    },

    updateFeatures(state, features) {
        if (features.length) {
            let UpdatedPolygons = state.polygons;
            features.forEach((updatedFeature) => {
                let index = UpdatedPolygons.features.findIndex(
                    (feature) =>
                        feature.properties.id === updatedFeature.properties.id
                );
                if (index >= 0) {
                    UpdatedPolygons.features[index] = updatedFeature;
                }
            });
            state.polygons = JSON.parse(JSON.stringify(UpdatedPolygons));
        }
    },

    addBlock(state, feature) {
        state.selectedBlocks.push(feature);
    },

    removeBlock(state, feature) {
        let index = state.selectedBlocks.findIndex(
            (block) => block.properties.blockid === feature.properties.blockid
        );
        if (index >= 0) {
            state.selectedBlocks.splice(index, 1);
        }
    },

    removeAllBlock(state) {
        state.selectedBlocks = [];
    },

    setEditingState(state, isEditing) {
        state.isEditing = !!isEditing;
    },

    setOffset(state, offset) {
        state.offset = offset;
    },

    setYears(state, years) {
        state.years = years;
    },

    reset(state) {
        state.cultivationYears = [];
        state.polygons = null;
        state.selectedParcelIds = [];
        state.currentCultivationYear = null;
        state.previousCultivationYear = null;
        state.samplesAsFeatures = [];
        state.selectedBlocks = [];
        state.isEditing = false;
        state.offset = 0;
        state.activeParcel = null;
    },
};

function addToBlockStore(dispatch, response) {
    let samples = response.data.joined_sampling;
    let polygons = response.data.polygons;
    if (samples && polygons) {
        dispatch(
            "block/SET_POLYGONS_AND_SAMPLES",
            {
                polygons: polygons[0].features,
                samples: JSON.parse(samples.SamplesAsGeoJson[0].features),
            },
            { root: true }
        );
    }
}

function getSelectedPolygons(state) {
    if (state.polygons) {
        return state.polygons.features.filter((f) => {
            return state.selectedParcelIds.includes(f.properties.id);
        });
    }
    return [];
}

function getSelectedParcels(state) {
    if (state.currentCultivationYear && state.currentCultivationYear.parcels) {
        return state.currentCultivationYear.parcels.filter((p) => {
            return state.selectedParcelIds.includes(p.id);
        });
    }
    return [];
}

function getSelectedPolygonsAndSamples(state, getters) {
    if (getters.selectedPolygons.length && state.samplesAsFeatures.length) {
        let polygonsAndSamples = [];
        getters.selectedPolygons.forEach((polygon) => {
            polygonsAndSamples.push({
                polygon: polygon,
                samples: state.samplesAsFeatures.filter((sample) => {
                    return isPointInPolygon(sample, polygon);
                }),
            });
        });

        return polygonsAndSamples;
    }
    return [];
}

function getSelectedSamples(state, getters) {
    if (getters.selectedPolygonsAndSamples) {
        return getters.selectedPolygonsAndSamples.reduce((array, obj) => {
            return array.concat(obj.samples);
        }, []);
    }
    return [];
}

function getSelectedSamplesStatistics(state, getters) {
    let avg = {
        ph: null,
        p_al: null,
        k_al: null,
        mg_al: null,
    };

    let years = new Set();
    if (getters.selectedSamples.length) {
        getters.selectedSamples.forEach((feature) => {
            avg.ph += feature.properties.ph;
            avg.p_al += feature.properties.p_al;
            avg.k_al += feature.properties.k_al;
            avg.mg_al += feature.properties.mg_al;
            years.add(feature.properties.year);
        });

        avg.ph = avg.ph / getters.selectedSamples.length;
        avg.p_al = avg.p_al / getters.selectedSamples.length;
        avg.k_al = avg.k_al / getters.selectedSamples.length;
        avg.mg_al = avg.mg_al / getters.selectedSamples.length;
    } else if (!getters.selectedPolygons.length) {
        state.samplesAsFeatures.forEach((feature) => {
            avg.ph += feature.properties.ph;
            avg.p_al += feature.properties.p_al;
            avg.k_al += feature.properties.k_al;
            avg.mg_al += feature.properties.mg_al;
            years.add(feature.properties.year);
        });

        avg.ph = avg.ph / state.samplesAsFeatures.length;
        avg.p_al = avg.p_al / state.samplesAsFeatures.length;
        avg.k_al = avg.k_al / state.samplesAsFeatures.length;
        avg.mg_al = avg.mg_al / state.samplesAsFeatures.length;
    }

    return {
        avg: avg,
        years: [...years],
    };
}

function getActiveParcel(state) {
    if (state.selectedParcelIds.length) {
        return state.currentCultivationYear.parcels.find(
            (parcel) => state.selectedParcelIds[0] === parcel.id
        );
    }
    return null;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
