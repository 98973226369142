const namespaced = true;

const state = {
    polygons: [],
    orders: [],
    currentOrder: null,
};

const getters = {
    orders: (state) => state.orders,
    polygons: (state) => state.polygons,
    currentOrder: (state) => state.currentOrder,
};

const actions = {
    GET_ORDERS({ commit }, customerId) {
        return new Promise((resolve, reject) => {
            axios
                .get("/customer/" + customerId + "/orders")
                .then((response) => {
                    commit("setOrders", response.data);
                    resolve();
                });
        });
    },
    SET_CURRENT_ORDER({ commit }, order) {
        return new Promise((resolve, reject) => {
            axios.get("/order/" + order).then((response) => {
                commit("setCurrentOrder", response.data);
                resolve();
            });
        });
    },
    SET_POLYGONS({ commit }, polygons) {
        return new Promise((resolve, reject) => {
            commit("setPolygons", polygons);
            resolve();
        });
    },
};

const mutations = {
    setPolygons(state, polygons) {
        state.polygons = polygons;
    },

    setOrders(state, orders) {
        state.orders = orders;
    },

    setCurrentOrder(state, order) {
        state.currentOrder = order;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
