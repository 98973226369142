const namespaced = true;

const state = {
    substance: null,
    product: null,
    polygons: [],
    grid: {
        type: "FeatureCollection",
        features: [],
    },
    bounds: null,
    loading: false,
    amounts: [],
    gridder_endpoint: import.meta.env.VITE_GRIDDER_ENDPOINT,
};

const initialState = JSON.parse(JSON.stringify(state));

const getters = {
    exportState: (state) => getExportState(state),
    substance: (state) => state.substance,
    product: (state) => state.product,
    grid: (state) => state.grid,
    loading: (state) => state.loading,
    polygons: (state) => state.polygons,
    amounts: (state) => state.amounts,
    bounds: (state) => state.bounds,
    type: (state) => (state.substance === "chalk" ? "ph" : state.substance),
    gridLoaded: (state) => state.grid.features.length,
    typeText: (state, getters, rootState, rootGetters) =>
        getTypeText(state.substance, getters, rootGetters),
    typeTextDescription: (state, getters, rootState, rootGetters) =>
        getTypeTextDescription(state.substance, getters, rootGetters),
    alternateTypeText: (state, getters, rootState, rootGetters) =>
        getAlternateTypeText(state.substance, getters, rootGetters),
    totalArea: (state) => getTotalArea(state.grid.features),
    givaBounds: (state) => getGivaBounds(state.grid.features),
    valueBounds: (state) => getValueBounds(state.grid.features),
    totalGiva: (state) => getTotalGiva(state.grid.features),
    areaError: (state) => getAreaError(state.grid.features, state.substance),
    areas: (state) => getAreas(state.amounts, state.grid.features),
    avgGiva: (state) => getAvgGiva(state.grid.features),

    amountsWithArea: (state, getters) =>
        getAmountsAndArea(state.amounts, getters.areas),
    colorGrading: (state, getters, rootState, rootGetters) =>
        getColorGrading(getters.givaBounds, getters.totalArea, rootGetters),
    colors: (state, getters, rootState, rootGetters) =>
        getColors(getters.colorGrading, rootGetters),
    info: (state, getters, rootState, rootGetters) =>
        getInfo(state, getters, rootGetters),
};

const actions = {
    SET_SUBSTANCE({ commit, state, dispatch }, substance) {
        if (state.grid.features.length) {
            dispatch("CLEAR_GRID_AND_SETTINGS");
        }
        commit("setSubstance", substance);
    },

    SET_PRODUCT({ commit }, product) {
        commit("setProduct", product);
    },

    SET_POLYGONS({ commit, dispatch }, polygons) {
        if (polygons.length) {
            dispatch("CLEAR_POLYGONS");
            dispatch("CLEAR_GRID_AND_SETTINGS");
        }
        commit("setBounds", polygons);
        commit("setPolygons", polygons);
    },

    GET_GRID({ commit, state, dispatch, rootGetters }, params) {
        commit("setLoading", true);
        axios
            .all(
                state.polygons.map((polygon) => {
                    return axios.post(state.gridder_endpoint, {
                        polygon: polygon.geometry,
                        samples: {
                            type: "FeatureCollection",
                            features: JSON.parse(
                                JSON.stringify(
                                    rootGetters["block/selectedSamplesGeoJson"]
                                )
                            ).map((s) => {
                                let sample = s;
                                sample.properties = {
                                    value: s.properties[state.substance],
                                };
                                return sample;
                            }),
                        },
                        // type: state.substance,
                        // distance: params.idwDistance ? params.idwDistance : 100,
                        // size: params.size,
                        // useDSMS: false,
                        // forceDSMS: false,
                        settings: {
                            size: params.size,
                            distance: params.idwDistance
                                ? params.idwDistance
                                : 100,
                            attributes: ["value"],
                            use_zero_values: false,
                            use_dsms_tif: false,
                            force_dsms_tif: false,
                        },
                    });
                })
            )
            .then((responses) => {
                commit("emtpyGrid");
                responses.forEach((response, index) => {
                    let polygonId = state.polygons[index].properties.id;
                    commit("addFeaturesToGrid", {
                        features: response.data.geojson.features,
                        polygon_id: polygonId,
                    });
                });
                commit("setLoading", false);
                dispatch("REMOVE_AMOUNTS_OUT_OF_BOUNDS");
            });
    },

    UPDATE_GRID({ commit }, gridLayer) {
        let features = Object.entries(gridLayer._layers).map(
            (layer) => layer[1].feature
        );
        commit("updateGrid", JSON.parse(JSON.stringify(features)));
    },

    SET_FEATURES({ commit }, features) {
        commit("updateGrid", features);
    },

    ADD_VALUE({ commit, getters }, params) {
        commit("addValue", {
            value: params.value,
            amount: params.amount,
        });
    },

    UPDATE_AMOUNTS({ commit }, amounts) {
        commit("updateAmounts", amounts);
    },

    REMOVE_AMOUNTS_OUT_OF_BOUNDS({ state, commit, getters }) {
        commit(
            "setAmounts",
            state.amounts.filter((amount) => {
                return (
                    amount.value >= getters.valueBounds.min &&
                    amount.value <= getters.valueBounds.max
                );
            })
        );
    },

    CLEAR_STATE({ commit }) {
        commit("clearState");
    },

    CLEAR_POLYGONS({ commit }) {
        commit("setPolygons", []);
        commit("setBounds", []);
    },

    CLEAR_GRID_AND_SETTINGS({ commit, dispatch }) {
        commit("emtpyGrid");
        dispatch("manualGiva/CLEAR_STATE", {}, { root: true });
        dispatch("adjustments/CLEAR_STATE", {}, { root: true });
    },

    SET_STATE({ commit }, newState) {
        commit("setState", newState);
    },
};

const mutations = {
    setSubstance(state, substance) {
        state.substance = substance;
    },

    setProduct(state, product) {
        state.product = product;
    },

    setBounds(state, polygons) {
        let bounds = null;
        polygons.forEach((polygon) => {
            let newBounds = polygon.getBounds();
            if (bounds) {
                bounds.extend(newBounds);
            } else {
                bounds = newBounds;
            }
        });
        state.bounds = bounds;
    },

    setPolygons(state, polygons) {
        polygons.forEach(
            (polygon) =>
                (polygon.feature.geometry = polygon.toGeoJSON().geometry)
        );
        state.polygons = polygons.map((polygon) => polygon.feature);
        // state.polygons = polygons
    },

    setLoading(state, status) {
        state.loading = status;
    },

    addFeaturesToGrid(state, { features, polygon_id }) {
        let cellFeatures = features;
        cellFeatures.forEach(
            (cell) => (cell.properties.polygon_id = polygon_id)
        );
        state.grid.features = state.grid.features.concat(features);
    },

    addValue(state, params) {
        state.amounts.push({
            value: params.value,
            amount: params.amount,
            area: 0,
        });
    },

    updateAmounts(state, amounts) {
        state.amounts.forEach((amount) => {
            amount.amount = parseFloat(amounts[amount.value]);
        });
    },

    setAmounts(state, amounts) {
        state.amounts = amounts;
    },

    emtpyGrid(state) {
        state.grid.features = [];
    },

    updateGrid(state, features) {
        state.grid.features = features;
    },

    clearState(state) {
        Object.keys(state).forEach((key) => {
            state[key] = initialState[key];
        });
    },

    setState(state, newState) {
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

function getAreaError(features, substance) {
    return (
        features.reduce((area, cell) => {
            if (
                isCellValueZero(cell, substance) &&
                cell.properties.giva === 0 &&
                !cell.properties.givaManuallyAdded
            ) {
                return (area += cell.properties.area);
            }
            return area;
        }, 0) / 10000
    );
}

function isCellValueZero(cell, substance) {
    if (substance === "chalk") {
        return (
            cell.properties.ph === 0 ||
            cell.properties.mullhalt === 0 ||
            cell.properties.lerhalt === 0
        );
    } else {
        return cell.properties.value === 0;
    }
}

function getAvgGiva(features) {
    let totalGiva = 0;
    let nbrCellsWithValue = 0;

    features.forEach((cell) => {
        if (cell.properties.value != 0) {
            totalGiva += cell.properties.giva;
            nbrCellsWithValue += 1;
        }
    });

    return +(totalGiva / nbrCellsWithValue).toFixed(2);
}

function getAreas(amounts, features) {
    let areas = {};
    let closestValue;
    amounts.forEach((amount) => {
        areas[amount.value] = 0;
    });

    if (amounts.length) {
        features.forEach((feature) => {
            closestValue = amounts
                .map((amount) => amount.value)
                .reduce(function (prev, curr) {
                    return Math.abs(curr - feature.properties.value) <
                        Math.abs(prev - feature.properties.value)
                        ? curr
                        : prev;
                });

            areas[closestValue] += feature.properties.area;
        });
    }

    return areas;
}

function getAmountsAndArea(amounts, areas) {
    return amounts
        .map((amount) => {
            return {
                value: amount.value,
                amount: amount.amount,
                area: areas[amount.value] / 10000,
            };
        })
        .sort((a, b) => a.value - b.value);
}

function getTypeText(type, getters, rootGetters) {
    let found = rootGetters["interpolationSettings/types"].find(
        (t) => t.slug == type
    );

    return found ? found.title : "okänd typ";
}

function getTypeTextDescription(type, getters, rootGetters) {
    let found = rootGetters["interpolationSettings/availableTypes"].find(
        (t) => t.slug == type
    );

    return found ? found.description : null;
}

function getAlternateTypeText(type, getters, rootGetters) {
    let found = rootGetters["interpolationSettings/types"].find(
        (t) => t.slug == type
    );

    return found.alternateTypeText ? found.alternateTypeText : null;
}

function getExportState(state) {
    return {
        substance: state.substance,
        product: state.product,
        polygons: state.polygons,
        bounds: state.bounds,
        loading: state.loading,
        amounts: state.amounts,
    };
}

function getValueBounds(features) {
    return features.reduce(
        (bounds, feature) => {
            if (feature.properties.value > bounds.max) {
                bounds.max = feature.properties.value;
            }
            if (
                feature.properties.value < bounds.min &&
                feature.properties.value > 0
            ) {
                bounds.min = feature.properties.value;
            }
            return bounds;
        },
        { min: Number.MAX_SAFE_INTEGER, max: 0 }
    );
}

function getTotalArea(features) {
    return features.reduce((sum, feature) => {
        return (sum += feature.properties.area / 10000);
    }, 0);
}

function getGivaBounds(features) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    features.forEach((feature) => {
        if (!feature.modified && feature.properties.giva < min) {
            min = feature.properties.giva;
        }
        if (!feature.modified && feature.properties.giva > max) {
            max = feature.properties.giva;
        }
    });
    return {
        min: min,
        max: max,
    };
}

function getColors(colorGrading, rootgetters) {
    if (colorGrading) {
        return colorGrading.map((grade, i) => {
            return {
                from: grade ? grade.value : null,
                to: colorGrading[i + 1] ? colorGrading[i + 1].value : null,
                color: rootgetters["colors/precisionColors"][i],
            };
        });
    }
}

function getColorGrading(givaBounds, totalArea, rootgetters) {
    // If there are no min and max values return 0
    if (givaBounds.min == 0 && givaBounds.max == 0) {
        return [0, 0, 0, 0, 0, 0, 0, 0].map((grade) => {
            return { value: null, area: totalArea.toFixed(1) };
        });
    }

    let old = givaBounds.min;
    let length = 8;
    let colorGrading = [];

    colorGrading[0] = null;
    colorGrading[1] = givaBounds.min;

    for (var i = 2; i < length + 1; i++) {
        colorGrading[i] = (givaBounds.max - givaBounds.min) / length + old;
        old = colorGrading[i];
    }
    let useDecimals = rootgetters["colors/precisionColors"];
    colorGrading = colorGrading.map((grade, i) => {
        return {
            value:
                useDecimals && grade !== null
                    ? +parseFloat(grade).toFixed(2)
                    : grade,
            area: getAreaForGrade(colorGrading, i, grade, state.grid),
        };
    });

    return colorGrading;
}

function getAreaForGrade(colorGrading, i, grade, grid) {
    let upperboundExist = colorGrading.length > i + 1;
    let areaSum = grid.features.reduce((sum, feature) => {
        // Is the giva within the grade-bounds?
        if (grade === null && feature.properties.giva === 0) {
            return sum + feature.properties.area;
        } else if (
            grade === 0
                ? feature.properties.giva > grade
                : feature.properties.giva >= grade
        ) {
            if (
                upperboundExist
                    ? feature.properties.giva < colorGrading[i + 1]
                    : true
            ) {
                return sum + feature.properties.area;
            }
        }
        return sum;
    }, 0);

    return (areaSum / 10000).toFixed(2);
}

function getTotalGiva(features) {
    return features.reduce((sum, feature) => {
        return (sum +=
            feature.properties.giva * (feature.properties.area / 10000));
    }, 0);
}

function getInfo(state, getters, rootGetters) {
    let zeroArea = state.grid.features.reduce((sum, feature) => {
        return (sum +=
            feature.properties.giva == 0 ? feature.properties.area / 10000 : 0);
    }, 0);

    return {
        totalArea: +getters.totalArea.toFixed(1) + " ha",
        maxLimit:
            getters.givaBounds.max > 1000
                ? (getters.givaBounds.max / 1000).toFixed(1) + " ton/ha"
                : (getters.givaBounds.max
                      ? getters.givaBounds.max.toFixed(1)
                      : 0) + " kg/ha",
        minLimit:
            getters.givaBounds.min > 1000
                ? (getters.givaBounds.min / 1000).toFixed(1) + " ton/ha"
                : (getters.givaBounds.min
                      ? getters.givaBounds.min.toFixed(1)
                      : 0) + " kg/ha",
        totalAmount: (getters.totalGiva / 1000).toFixed(1) + " ton",
        totalAmountKg:
            parseFloat(getters.totalGiva.toFixed(1)).toLocaleString("sv-SE") +
            " kg",
        zeroArea:
            +zeroArea.toFixed(1) +
            " ha (" +
            +((zeroArea / getters.totalArea) * 100).toFixed(1) +
            "%)",
        method: "Anpassad styrfil",
        productTitle: getters.product ? getters.product.title : null,
        grades: getters.colors.map((grade, i) => {
            return {
                color: grade.color,
                area: getters.colorGrading[i].area,
                value:
                    grade.from === null
                        ? "Ingen giva"
                        : parseInt(grade.from) +
                          " - " +
                          parseInt(
                              grade.to ? grade.to : getters.givaBounds.max
                          ),
            };
        }),
        manualGrades: rootGetters["manualGiva/appliedGivorSorted"].map(
            (giva) => {
                let color;
                let index = rootGetters[
                    "manualGiva/appliedGivorSorted"
                ].indexOf(giva.toString());
                if (index !== -1) {
                    index =
                        rootGetters["manualGiva/colors"].length -
                        (rootGetters["manualGiva/appliedGivorSorted"].length -
                            index);
                    color = rootGetters["manualGiva/colors"][index];
                }
                return {
                    value: giva,
                    color: color,
                };
            }
        ),
    };
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
