const namespaced = true;

const state = {
    currentLayer: "height",
    filters: [],
    availableLayers: [],
    statistics: null,

    selectedFeatures: [],
};

const getters = {
    currentLayer: (state) => state.currentLayer,
    availableLayers: (state) => state.availableLayers,
    statistics: (state) => state.statistics,
    filters: (state) => state.filters,

    selectedFeatures: (state) => state.selectedFeatures,
};

const actions = {
    SET_AVAILABLE_LAYERS({ dispatch, commit, getters, rootGetters }) {
        commit(
            "setAvailableLayers",
            rootGetters["raster/currentRaster"].layers
        );
    },

    SET_CURRENT_LAYER({ commit }, layer) {
        // return new Promise((resolve, reject) => {
        //     axios.get("/crop").then((response) => {
        //         commit("setCrops", response.data);
        //         resolve();
        //     });
        // });
        commit("setCurrentLayer", layer);
    },

    SET_FILTER({ commit }, filter) {
        commit("setFilter", filter);
    },

    RESET_FILTER({ commit }, filter) {
        commit("resetFilter", filter);
    },

    SELECT_FEATURE({ commit }, data) {
        commit("addSelectedFeature", data);
    },

    CLEAR_SELECTIONS({ commit }) {
        commit("clearSelectedFeatures");
    },

    SET_STATISTICS({ commit }, data) {
        commit("setStatistics", data);
    },
};

const mutations = {
    setAvailableLayers({}, rasterLayers) {
        let availableLayers = {};
        Object.values(getDefaultLayers()).forEach((defaultLayer) => {
            let rasterLayer = rasterLayers.find(
                (obj) => obj.id == defaultLayer.id
            );
            if (rasterLayer) {
                availableLayers[defaultLayer.id] = defaultLayer;

                if (rasterLayer.id === "yieldPrediction") {
                    availableLayers[defaultLayer.id].description =
                        "Skördeprediktion år: " + rasterLayer.parameters.year;
                }
            }
        });

        state.availableLayers = availableLayers;
    },
    setFilter(state, filter) {
        let filters = state.filters.filter(
            (f) => f.property != filter.property
        );
        filters.push(filter);
        state.filters = filters;
    },
    resetFilter(state, filter) {
        let filters = state.filters.filter(
            (f) => f.property != filter.property
        );
        state.filters = filters;
    },
    setCurrentLayer(state, layer) {
        state.currentLayer = layer;
    },
    addSelectedFeature(state, feature) {
        state.selectedFeatures.push(feature.properties);
    },
    clearSelectedFeatures() {
        state.selectedFeatures = [];
    },
    setStatistics(state, data) {
        state.statistics = data;
    },
};

function getDefaultLayers() {
    return {
        height: {
            id: "height",
            title: "Höjd",
            description: "Höjd över havet",
            extrudeFactor: 1.35,
        },
        topology: {
            id: "topology",
            title: "Terräng",
            description: "Klassificering av topologi",
            extrudeFactor: 1.35,
        },
        slope: {
            id: "slope",
            title: "Lutning",
            description: "Lutning på terräng i procent",
            extrudeFactor: 1.35,
        },
        lerhalt: {
            id: "lerhalt",
            title: "Lerhalt",
            description: "Markkartering",
            extrudeFactor: 1.5,
        },
        mullhalt: {
            id: "mullhalt",
            title: "Mullhalt",
            description: "Markkartering",
            extrudeFactor: 1.7,
        },
        ph: {
            id: "ph",
            title: "pH",
            description: "Markkartering",
            extrudeFactor: 2.2,
        },
        p_al: {
            id: "p_al",
            title: "Fosfor",
            description: "Markkartering",
            extrudeFactor: 1.4,
        },
        k_al: {
            id: "k_al",
            title: "Kalium",
            description: "Markkartering",
            extrudeFactor: 1.5,
        },
        mg_al: {
            id: "mg_al",
            title: "Magnesium",
            description: "Markkartering",
            extrudeFactor: 1.5,
        },
        k_mg_kvot: {
            id: "k_mg_kvot",
            title: "Kalium/Magnesium",
            description: "Markkartering",
            extrudeFactor: 2.5,
        },
        ca_al: {
            id: "ca_al",
            title: "Kalcium",
            description: "Markkartering",
            extrudeFactor: 0.65,
        },

        yieldPrediction: {
            id: "yieldPrediction",
            title: "Skördeprediktion",
            description: "Skördeprediktion",
            extrudeFactor: 2.0,
        },
    };
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
