let auth = {
    path: "/auth",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/login",
            component: () => import("../pages/auth/login.vue"),
            name: "login",
            meta: { auth: false },
        },
        {
            path: "/register",
            component: () => import("../pages/auth/register.vue"),
            name: "register",
            meta: { auth: false },
        },
    ],
};

let password = {
    path: "/password",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "email",
            component: () => import("../pages/auth/email.vue"),
            name: "password.request",
            meta: { auth: false },
        },
        {
            path: "reset/:token",
            component: () => import("../pages/auth/reset.vue"),
            name: "password.reset",
            meta: { auth: false },
        },
    ],
};

let guest = {
    path: "/guest",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "map/:linkid",
            component: () => import("../components/shared/map.vue"),
            name: "guest.map",
            meta: { auth: false },
        },
        {
            path: "confirmation",
            name: "orderConfirmation",
            component: () => import("../pages/order/confirmation.vue"),
            meta: { auth: false },
        },
    ],
};

let order = {
    path: "/order",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "order.index",
            component: () => import("../pages/order/index.vue"),
            meta: { auth: true },
        },
        {
            path: ":id",
            name: "order.show",
            component: () => import("../pages/order/show.vue"),
            meta: { auth: true },
        },
    ],
};

let plan = {
    path: "/plan",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "plan.index",
            component: () => import("../pages/plan/Index.vue"),
            meta: { auth: true },
        },
        {
            path: "nutrition",
            name: "nutrition.index",
            component: () => import("../pages/nutrition/index.vue"),
            meta: { auth: true, license: "can_view_nutrition" },
        },
        {
            path: "need",
            name: "need.index",
            component: () => import("../pages/need/index.vue"),
            meta: { auth: true, license: "can_view_edit_need" },
        },
        {
            path: "need/create",
            name: "need.create",
            component: () => import("../pages/need/create.vue"),
            meta: { auth: true, license: "can_view_edit_need" },
        },
        // {
        //     path: "cultivationyear/all",
        //     name: "cultivation.index",
        //     component: () => import("../pages/cultivation-year/index.vue"),
        //     meta: { auth: true, license: "can_view_cultivationyear" },
        // },
        // {
        //     path: "cultivationyear/show",
        //     name: "cultivation.show",
        //     component: () => import("../pages/cultivation-year/show.vue"),
        //     meta: { auth: true, license: "can_view_cultivationyear" },
        // },
        // {
        //     path: "cultivationyear/create",
        //     name: "cultivation.create",
        //     component: () => import("../pages/cultivation-year/create.vue"),
        //     meta: { auth: true, license: "can_create_cultivationyear" },
        // },
        // {
        //     path: "cultivationyear/create/blocks",
        //     name: "cultivation.create.blocks",
        //     component: () =>
        //         import("../pages/cultivation-year/create-from-blocks.vue"),
        //     meta: { auth: true, license: "can_create_cultivationyear" },
        // },
        // {
        //     path: "cultivationyear/edit",
        //     name: "cultivation.edit",
        //     component: () => import("../pages/cultivation-year/edit.vue"),
        //     meta: { auth: true, license: "can_edit_cultivationyear" },
        // },
        {
            path: "samples/attach",
            name: "samples.attach",
            component: () => import("../pages/cultivation-year/attach.vue"),
            meta: { auth: true, license: "can_view_cultivationyear" },
        },
    ],
};

let prescriptions = {
    path: "/prescription",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "create",
            name: "prescription.create",
            component: () => import("../pages/prescriptions/create.vue"),
            meta: { auth: true, license: "can_create_styrfiler" },
        },
        {
            path: "show",
            name: "prescription.show",
            component: () => import("../pages/prescriptions/show.vue"),
            meta: { auth: true, license: "can_save_styrfiler" },
        },
        {
            path: "",
            name: "prescription.index",
            component: () => import("../pages/prescriptions/index.vue"),
            meta: { auth: true, license: "can_save_styrfiler" },
        },
        {
            path: "lerhaltskartan",
            name: "prescription.lerhaltskartan",
            component: () => import("../pages/claymap/prescription.vue"),
            meta: { auth: true, license: "can_create_styrfiler" },
        },
    ],
};

let analyze = {
    path: "/analyze",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "soil",
            name: "soil.index",
            component: () => import("../pages/soilmap/index.vue"),
            props: true,
            meta: { auth: true },
        },
        {
            path: "satelite",
            name: "satelite.index",
            component: () => import("../pages/satelite/index.vue"),
            props: true,
            meta: { auth: true },
        },
        {
            path: "lerhaltskartan",
            name: "lerhaltskartan.index",
            component: () => import("../pages/claymap/index.vue"),
            meta: { auth: true, license: "lerhaltskartan_view" },
        },
        {
            path: "compare",
            name: "compare.index",
            component: () => import("../pages/compare/index.vue"),
            meta: { auth: true, license: "can_view_compare" },
        },
        {
            path: "evaluate",
            name: "evaluate.index",
            component: () => import("../pages/evaluate/index.vue"),
            meta: { auth: true, license: "view_evaluation" },
        },
        {
            path: "joinedsamplings",
            name: "joinedsampling.index",
            component: () => import("../pages/joined-samplings/index.vue"),
            meta: { auth: true, license: "can_view_joined_sampling" },
        },
        {
            path: "joinedsamplings/create",
            name: "joinedsampling.create",
            component: () => import("../pages/joined-samplings/create.vue"),
            meta: { auth: true, license: "can_create_joined_sampling" },
        },
        {
            path: "joinedsamplings/edit",
            name: "joinedsampling.edit",
            component: () => import("../pages/joined-samplings/edit.vue"),
            meta: { auth: true, license: "can_create_joined_sampling" },
        },
    ],
};

let quotation = {
    path: "/quotation",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "orderQuotation",
            component: () => import("../pages/order/quotation.vue"),
            meta: { auth: true },
        },
    ],
};

let customer = {
    path: "/customer",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "customerOptions",
            component: () => import("../pages/customer/options.vue"),
            meta: { auth: true },
        },
        {
            path: ":id/sampling/status/map",
            name: "customer.samplingStatusMap",
            component: () =>
                import("../pages/customer/sampling-status-map.vue"),
            meta: { auth: true },
        },
    ],
};

let map = {
    path: "/map",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "joinedsampling/:id",
            name: "showJoinedSamplingMap",
            component: () => import("../pages/map/joined-sampling.vue"),
            meta: { auth: true, fullScreenMap: true },
        },
        {
            path: "customer/:customer_id/:year",
            name: "showCustomerYearMap",
            component: () => import("../pages/map/customer-year.vue"),
            meta: { auth: true, fullScreenMap: true },
        },
    ],
};

let lerhaltskartan = {
    path: "/lerhaltskartan",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "lerhaltskartan",
            component: () => import("../pages/lerhaltskartan.vue"),
            meta: { auth: true, fullScreenMap: true },
        },
    ],
};

let settings = {
    path: "/settings",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "settings",
            component: () => import("../pages/settings/settings.vue"),
            meta: { auth: true },
        },
    ],
};

let support = {
    path: "/support",
    component: () => import("../components/layout/Layout.vue"),
    children: [
        {
            path: "/",
            name: "support",
            component: () => import("../pages/support.vue"),
            meta: { auth: true },
        },
    ],
};

const routes = [
    auth,
    password,
    guest,
    plan,
    analyze,
    order,
    quotation,
    customer,
    lerhaltskartan,
    prescriptions,
    map,
    settings,
    support,
    {
        path: "/",
        component: () => import("../components/layout/Layout.vue"),
        redirect: "/dashboard",
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("../pages/dashboard.vue"),
                meta: { auth: true },
            },
            {
                path: "/shared",
                name: "shared",
                component: () => import("../pages/Shared.vue"),
                meta: { auth: true },
            },
        ],
    },
    {
        path: "*",
        redirect: {
            name: "login",
        },
    },
];

export default routes;
