/**
 *   Interpolate the Y value based on the parameters
 *
 */
export function interpolateValue(x, x1, x2, y1, y2, useDecimals = false) {
    let k = (y2 - y1) / (x2 - x1);
    let m = y1 - k * x1;
    let result = k * x + m;

    // !x should include x === 0 and also fix issues when x is null or undefined.
    if (!x) {
        return 0;
    } else if (!useDecimals && result < 1) {
        return 0;
    } else if (useDecimals && result < 1) {
        return parseFloat(+result.toFixed(2));
    } else {
        return result;
    }
}
