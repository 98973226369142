import Errors from "@/helpers/errors.js";

const namespaced = true;

const state = {
    access_token: null,
    status: "",
    hasLoadedOnce: false,
    errors: null,
};

const getters = {
    isAuthenticated: (state) => !!state.access_token,
    user: (state) => state.user,
    accessToken: (state) => state.access_token,
    authStatus: (state) => state.status,
    authErrors: (state) => state.errors,
};

const actions = {
    AUTH_REQUEST({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit("authRequest");
            supportAxios
                .post("/token/get", {
                    email: payload.email,
                    name: payload.name,
                    origin: "markkartering.se",
                })
                .then((response) => {
                    let access_token = "Bearer " + response.data;
                    supportAxios.defaults.headers.common["Authorization"] =
                        access_token;

                    commit("authSuccess", access_token);
                    resolve(access_token);
                })
                .catch((err) => {
                    commit("authError", err.response.data);
                    reject(err);
                });
        });
    },

    AUTH_LOGOUT({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .post("token/remove")
                .then((response) => {
                    commit("authLogout");
                    resolve();
                })
                .catch((err) => {
                    commit("authError", err.response.data);
                    resolve();
                });
        });
    },

    RESET({ commit }) {
        commit("reset");
    }
};

const mutations = {
    authRequest: (state) => {
        state.status = "loading";
    },

    authSuccess: (state, access_token) => {
        state.status = "success";
        state.access_token = access_token;
        state.hasLoadedOnce = true;
    },

    authError: (state, err) => {
        let errors = err.errors ? err.errors : {};
        if (err.error == "invalid_credentials") {
            errors.invalid_credentials = [
                "The user credentials were incorrect.",
            ];
        }

        state.status = "error";
        state.hasLoadedOnce = true;

        if (state.errors === null) {
            state.errors = new Errors();
        }
        state.errors.record(errors);
    },

    authLogout: (state) => {
        state.access_token = "";
    },

    reset: (state) => {
        state.access_token = null;
        state.status = "";
        state.hasLoadedOnce = false;
        state.errors = null;
    }
};

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
