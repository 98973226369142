const namespaced = true;

const defaulState = {
    isEditing: false,
    hidePolygons: false,
    showParcelInfo: false,
    showCropColor: true,
    editMode: null, // split, new, modify, union, modify-side, block
    featureType: null, // Polygon, Linestring
    modifiedFeature: null,
    selectedRuns: [],
    creatingPrescriptionFile: false,
    editingPrescriptionFile: {
        runId: null,
        prescriptionId: null,
    },
    newFeature: null,
    offset: 0,
    toBeFetched: 0,
    fetched: 0,
    fetching: false,
    currentAnalysisType: 'cultivationYear'
}

const state = defaulState

const getters = {
    isEditing: (state) => state.isEditing,
    isFetching: (state) => state.fetching,
    isCreatingPrescriptionFile: (state) => state.creatingPrescriptionFile,
    isEditingPrescriptionFile: (state) => !!(state.editingPrescriptionFile?.runId && state.editingPrescriptionFile?.prescriptionId),
    editingPrescriptionFile: (state) => state.editingPrescriptionFile,
    toBeFetched: (state) => state.toBeFetched,
    fetched: (state) => state.fetched,
    editMode: (state) => state.editMode,
    selectedRuns: (state) => state.selectedRuns,
    showParcelInfo: (state) => state.showParcelInfo,
    showCropColor: (state) => state.showCropColor,
    featureType: (state) => state.featureType,
    offset: (state) => state.offset,
    hidePolygons: (state) => state.hidePolygons,
    modifiedFeature: (state) => state.modifiedFeature,
    newFeature: (state) => state.newFeature,
    currentAnalysisType: (state) => state.currentAnalysisType,
};

const actions = {
    SET_IS_EDITING({ commit }, isEditing) {
        commit("setIsEditing", isEditing);
    },    
    
    SET_EDITING_MODE({ commit }, editMode) {
        commit("setEditingMode", editMode);
    },        
    
    SET_ANALYSIS_TYPE({ commit }, type) {
        commit("setCurrentAnalysisType", type);
    },

    SET_FETCHING({ commit }, fetching) {    
        commit("setFetching", fetching);
    },  
    
    SET_TO_BE_FETCHED({ commit }, toBeFetched) {
        commit("setToBeFetched", toBeFetched);
    },

    SET_FETCHED({ commit }, fetched) {
        commit("setFetched", fetched);
    },
    
    SET_FEATURE_TYPE({ commit }, featureType) {
        commit("setFeatureType", featureType);
    },      
    
    SET_MODIFIED_FEATURE({ commit }, feature) {
        commit("setModifiedFeature", feature);
    },       
    
    SET_NEW_FEATURE({ commit }, feature) {
        commit("setNewFeature", feature);
    },   

    SET_SELECTED_RUNS({ commit }, runs) {
        commit("setSelectedRuns", runs);
    },

    CREATE_PRESCRIPTION_FILE({ commit, state, dispatch}, creating) {
        commit("setCreatingPrescriptionFile", creating);
    },

    SET_EDITING_PRESCRIPTION_FILE({ commit, state, dispatch}, {runId, prescriptionId}) {
        commit("setEditingPrescriptionFile", {runId, prescriptionId});
    },
    
    SAVE_MODIFIED_FEATURE({ commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            let params = {
                id: state.modifiedFeature.properties.parcel_id,
                params: {
                    geom: state.modifiedFeature.geometry
                }
            }
            dispatch("plan/UPDATE_PARCEL", params, {
                root: true,
            })
            .then(() => {
                resolve()
            })
            .catch((e) => {
                reject(e)
            });
        });
    },    
    
    SET_OFFSET({ commit }, offset) {
        commit("setOffset", offset);
    },    

    SET_SHOW_PARCEL_INFO({ commit }, show) {
        commit("setShowParcelInfo", show);
    },       
    
    SET_SHOW_CROP_COLOR({ commit }, show) {
        commit("setCropColor", show);
    },    
    
    SET_HIDE_POLYGONS({ commit }, hide) {
        commit("setHidePolygons", hide);
    },

    RESET({ commit }) {
        commit("reset");
    },
};

const mutations = {
    setIsEditing(state, isEditing){
        state.isEditing = isEditing;
    },    
    
    setEditingMode(state, editMode){
        state.editMode = editMode;
        state.modifiedFeature = null;
        state.newFeature = null;

    },    

    setFetching(state, fetching){
        console.log("Setting fetching to", fetching);
        if(fetching == false){
            state.toBeFetched = 0;
            state.fetched = 0;
        }
        state.fetching = fetching;
    },   
    
    setSelectedRuns(state, runs){
        state.selectedRuns = runs;
    },

    setCreatingPrescriptionFile(state, creating){
        state.creatingPrescriptionFile = creating;
    },

    setEditingPrescriptionFile(state, {runId, prescriptionId}){
        state.editingPrescriptionFile.runId = runId;
        state.editingPrescriptionFile.prescriptionId = prescriptionId;
    },

    setToBeFetched(state, toBeFetched){
        state.toBeFetched = toBeFetched;
        state.fetching = true;
    },

    setFetched(state, fetched){
        state.fetched = fetched;
    },
    
    setFeatureType(state, featureType){
        state.featureType = featureType;
    },    
            
    setCurrentAnalysisType(state, type){
        state.currentAnalysisType = type;
    },    
        
    setShowParcelInfo(state, show){
        state.showParcelInfo = show;
    },      
    
    setCropColor(state, show){
        state.showCropColor = show;
    },    
    
    setOffset(state, offset){
        state.offset = offset;
    },    
    
    setModifiedFeature(state, feature){
        state.modifiedFeature = feature;
    },        
    
    setNewFeature(state, feature){
        state.newFeature = feature;
    },    
    
    setHidePolygons(state, hide){
        state.hidePolygons = hide;
    },

    reset(state) {
        state = defaulState;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
