const namespaced = true;

const state = {
    store: null,
    minAmount: null,
    maxAmount: null,
    cellSize: 25,
    idwDistance: 100,
    includeZeroCells: false,
    cellErrors: {
        applyCorrected: false,
        applyNearest: false,
        correctedGiva: 0,
        show: false,
    },

    totalAmount: null,
    originalTotalGiva: null,
};

const initialState = JSON.parse(JSON.stringify(state));

const getters = {
    exportState: (state) => getExportState(state),
    totalAmount: (state) => state.totalAmount,
    minAmount: (state) => state.minAmount,
    maxAmount: (state) => state.maxAmount,
    cellSize: (state) => state.cellSize,
    idwDistance: (state) => state.idwDistance,
    includeZeroCells: (state) => state.includeZeroCells,
    originalTotalGiva: (state) => state.originalTotalGiva,
    store: (state) => state.store,
    cellErrors: (state) => state.cellErrors,
    minMax: (state) => {
        return {
            min: state.minAmount,
            max: state.maxAmount,
            includeZero: state.includeZeroCells,
        };
    },
    totalAmountClearMessage: () => {
        return "Du har applicerat total giva, om du applicerar en ny inställning kommer den total giva-inställningen nollställas, tryck ok för att nollställa";
    },
};

const actions = {
    SET_TOTAL_AMOUNT({ state, commit, dispatch, rootGetters }, amount) {
        commit("setTotalAmount", amount);
        if (amount != null) {
            dispatch("manualGiva/CLEAR_PAINTING_STATES", {}, { root: true });
            if (state.originalTotalGiva === null) {
                commit(
                    "setOriginalTotalGiva",
                    rootGetters[state.store + "/totalGiva"]
                );
            }
        }
    },

    CLEAR_TOTAL_AMOUNT({ commit }, amount) {
        commit("setTotalAmount", null);
        commit("setOriginalTotalGiva", null);
    },

    SET_MIN_MAX({ commit }, minMax) {
        commit("setMinMax", minMax);
    },

    CLEAR_MIN_MAX({ commit }) {
        commit("setMinMax", {
            minAmount: null,
            maxAmount: null,
            includeZeroCells: false,
        });
    },

    RESET_GRID_SETTINGS({ state, commit, dispatch }) {
        commit("setGridSettings", {
            cellSize: 25,
            idwDistance: 100,
        });

        dispatch(
            "custom/GET_GRID",
            { size: state.cellSize, idwDistance: state.idwDistance },
            { root: true }
        );
    },

    SET_GRID_SETTINGS({ state, commit, dispatch }, settings) {
        commit("setGridSettings", {
            cellSize: settings.cellSize,
            idwDistance: settings.idwDistance,
        });

        dispatch(
            "custom/GET_GRID",
            { size: state.cellSize, idwDistance: state.idwDistance },
            { root: true }
        );
    },

    UPDATE_CELL_ERRORS({ commit }, cellErrors) {
        commit("setCellErrors", cellErrors);
    },

    SET_STORE({ commit }, store) {
        commit("setStore", store);
    },

    CLEAR_STATE({ commit }) {
        commit("clearState");
    },

    SET_STATE({ commit }, newState) {
        commit("setState", newState);
    },
};

const mutations = {
    setTotalAmount(state, amount) {
        state.totalAmount = amount;
    },

    setMinMax(state, minMax) {
        state.minAmount = minMax.minAmount;
        state.maxAmount = minMax.maxAmount;
        state.includeZeroCells = minMax.includeZeroCells;
    },

    setGridSettings(state, settings) {
        state.cellSize = settings.cellSize;
        state.idwDistance = settings.idwDistance;
    },

    setOriginalTotalGiva(state, giva) {
        state.originalTotalGiva = giva;
    },

    setCellErrors(state, cellErrors) {
        Object.keys(state.cellErrors).forEach((key) => {
            if (cellErrors.hasOwnProperty(key)) {
                state.cellErrors[key] = cellErrors[key];
            }
        });
    },

    setStore(state, store) {
        state.store = store;
    },

    clearState(state) {
        Object.keys(state).forEach((key) => {
            state[key] = initialState[key];
        });

        state.cellErrors = {
            applyCorrected: false,
            applyNearest: false,
            correctedGiva: 0,
            show: false,
        };
    },

    setState(state, newState) {
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

function getExportState(state) {
    return state;
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
